import _isObject from 'lodash/isObject';
// import _isNumber from "lodash/isNumber"
import { patterns } from '@/lib/consts';
import { store } from '@/store/store';

/**
 * Возвращает описание фильтра для актов и договоров (в виде для чтения человеком)
 * @param filter {Object} объект фильтра
 * @returns строку описания
 */
export const describeFilter = (prefix, filter) => {
  if (!(filter && _isObject(filter))) return '';
  let result = '';
  if (filter.ownCreated === true || filter.ownCreated === 'true') {
    result += 'созданные мной, ';
  }
  if (filter.ownCompleted === true || filter.ownCompleted === 'true') {
    result += 'закрытые мной, ';
  }
  if ((typeof filter.unp === 'number' || typeof filter.unp === 'string') && filter.unp >= 100000000 && filter.unp <= 999999999) {
    result += `УНП: ${filter.unp}, `;
  }
  if (filter.number) {
    if (typeof filter.number === 'string' && filter.number.trim().length > 3) {
      result += `№: ${filter.number.trim()}, `;
    } else if (typeof filter.number === 'number' && filter.number > 999) {
      result += `№: ${filter.number}, `;
    }
  }
  if (filter.serviceId && !isNaN(filter.serviceId)) {
    let services = {};
    store.getters.services.forEach(el => {
      services[el.id] = el.name;
    });
    result += `${services[filter.serviceId]}, `;
  }
  if (typeof filter.name === 'string' && filter.name.trim().length > 5) {
    result += `${filter.name.trim()}, `;
  }
  if (patterns.date.test(filter.from)) {
    result += `с ${moment(filter.from).format('DD.MM.YYYY')}, `;
  }
  if (patterns.date.test(filter.to)) {
    result += `по ${moment(filter.to).format('DD.MM.YYYY')}, `;
  }
  if (result.length) {
    return prefix + ': ' + result.slice(0, result.length - 2);
  } else return prefix;
};

/**
 * Преобразует фильтр актов или договоров в URI
 * @param filter {Object} объект фильтра
 * @returns URI
 */
export const filterToURI = filter => {
  if (!(filter && _isObject(filter))) return '';
  let result = '';
  if (filter.ownCreated === true || filter.ownCreated === 'true') {
    result += '&ownCreated=true';
  }
  if (filter.ownCompleted === true || filter.ownCompleted === 'true') {
    result += '&ownCompleted=true';
  }
  if (filter.unp && !isNaN(filter.unp)) {
    result += `&unp=${filter.unp}`;
  }
  if (filter.serviceId && !isNaN(filter.serviceId)) {
    result += `&serviceId=${filter.serviceId}`;
  }
  if (filter.number) {
    result += `&number=${filter.number}`;
  }
  if (filter.name && typeof filter.name === 'string' && filter.name.trim().length > 5) {
    result += `&name=${filter.name.trim()}`;
  }
  if (patterns.date.test(filter.from)) {
    result += `&from=${filter.from}`;
  }
  if (patterns.date.test(filter.to)) {
    result += `&to=${filter.to}`;
  }
  if (result.length) {
    return '?' + result.slice(1);
  } else return '';
};

/**
 * Проверяет переданный объект фильтра на заполненность. Фильтр используется для отбора документов.
 * Проверяются типы полей и их заполненность
 * @param {Object} filter - объект фильтра
 * @returns false Если фильтр пригоден для передачи на сервер (для отбора документов по индекированным
 *  полям), true в противном случае
 */
export const isFilterEmpty = filter => {
  if (filter.ownCreated === true || filter.ownCreated === 'true' || filter.ownCompleted === true || filter.ownCompleted === 'true') return false;
  if ((typeof filter.number === 'string' || typeof filter.number === 'number') && filter.number) return false;
  if ((typeof filter.unp === 'number' || typeof filter.unp === 'string') && filter.unp >= 100000000 && filter.unp <= 999999999) return false;
  if (typeof filter.name === 'string' && filter.name.trim().length > 5) return false;
  if (patterns.date.test(filter.from) || patterns.date.test(filter.to)) return false;
  return true;
};

/**
 * Возвращает пустой фильтр
 */
export const emptyFilter = () => {
  return {
    // созданые текущим пользователем:
    ownCreated: false,
    // звкрытые текущим пользователем:
    ownCompleted: false,
    unp: null,
    // номер (строка)
    number: null,
    serviceId: null,
    name: null,
    from: null,
    to: null,
  };
};
