export const defaultDateFormat = 'DD.MM.YYYY';
export const defaultDateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

export const DocumentGroups = {
  // Документы, связанные с разрешениями на допуск (оригинальные разрешения, 2 вида переносов)
  PERMISSION: 1,
  // Документы, связанные с МСТО (оригинальные сертификаты, продления).
  MSTO: 2,
  // Документы, связанные с ЕКМТ (только оригинальные сертификаты).
  EKMT: 3,
  // Документы, связанные с ОГ (оригинальные сертификаты, продления).
  OG: 4,
  // Документы, связанные с картами доп. проверки ОГ (оригинальные карты).
  OG_CARD: 5,
};

export const DocumentSubgroups = {
  // Оригинальные документы (разрешения на допуск, акты, сертификаты МСТО ЕКМТ ОГ, прочие).
  ORIGINAL: 1,
  // Продления (для сертификатов, где это предусмотрено, например МСТО и ОГ)
  PROLONGATION: 2,
  // Переносы отметки (только для разрешений на допуск).
  TRANSFER: 11,
  // Переносы информации на новый бланк (только для разрешений на допуск).
  TRANSFER2: 13,
};

export const vatRate = 20.0;
export const ta2Reasons = [
  {
    id: 1,
    name: 'Смена собственника',
  },
  { id: 2, name: 'Смена госномера' },
  { id: 3, name: 'Смена техпаспорта' },
];
// export const taReasons = [
//   { id: 1, name: 'Внесение изменений' },
//   { id: 2, name: 'Выдача дубликата' },
//   { id: 3, name: 'Выдача нового сертификата' },
//   {
//     id: 4,
//     name: 'Смена собственника',
//   },
// ];

var _combinedRNPattern = '';
[
  /^([A-ZА-Я])(\d{5})$/,
  /^(\d)([A-ZА-Я]{2,3})(\d{4})$/,
  /^\d{4}\s?[A-ZА-Я]{2}-?(\d)$/,
  /^([A-ZА-Я]{2})\s?(\d{4})-(\d)$/,
  /^([A-ZА-Я])\s?(\d{4})\s?([A-ZА-Я])-?(\d)$/,
  /^(\d{4})\s?([A-ZА-Я])-?(\d)$/,
  /^([A-ZА-Я])(\d{4})([A-ZА-Я]{2})$/,
  /^(\d{4})([A-ZА-Я]{2,3})$/,
  /^([A-ZА-Я]{2,3})(\d{4})$/,
  /^([A-ZА-Я])(\d{4})([A-ZА-Я])$/,
  /^([A-ZА-Я])(\d{4})$/,
  /^(\d{4})([A-ZА-Я])$/,
  /^([A-ZА-Я]{2})-?\d (\d{4})$/,
  /^[EЕ]\d{3}\s?[A-ZА-Я]{2}-?\d$/,
  /^[A-ZА-Я]{2} [EЕ]\d{3}-?\d$/,
  /^[A-ZА-Я]{2}-?\d [EЕ]\d{3}$/,
].forEach(function(item) {
  var x = '' + item;
  x = x.substring(1, x.length - 1);
  _combinedRNPattern = _combinedRNPattern + '(' + x + ')|';
});

const sCnPattern = '([A-ZА-Я]{2,3}|\\d[A-ZА-Я]{2})\\s*(\\d{4,8})';
export const patterns = {
  combinedRN: new RegExp(_combinedRNPattern.substring(0, _combinedRNPattern.length - 1)),
  vinStrict: /^(([A-ZА-Я0-9]{17,18})|(Б\/Н))$/,
  vinLoose: /^(([A-ZА-Я0-9]{6,25})|(Б\/Н))$/,
  vinLooseOrEmpty: /^(([A-ZА-Я0-9]{6,25})|)$/,
  dc: /^([A-ZА-Я]{2,2})?\s*(\d{2,7})$/,
  pn: /^([A-ZА-Я]{2,2})\s*(\d{2,7})$/,
  isoDate: /^\d{4}-[01]\d-[0-3]\d(T[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)?)?$/,
  cn: new RegExp(`^${sCnPattern}$`),
  sCn: sCnPattern,
  // dl: /^\s*((\d?[a-zа-я]{2,3})|(\d{2} ?\d{2} ))?\s*?(\d{1,9})\s*$/gi,
  date: /\d{4,4}-\d{2,2}-\d{2,2}/,
  dateTime: /\d{4,4}-\d{2,2}-\d{2,2}T\d{2,2}:\d{2,2}:\d{2,2}/,
};

export const allReferences = ['users', 'gmodels', 'cats', 'areas', 'regions', 'colors', 'ds', 'applyings', 'taxdiscounts'];

export const payForms = [
  { id: 3, name: 'ЕРИП', description: 'Оплата по ЕРИП' },
  { id: 2, name: 'ПП', description: 'Платёжное поручение юр.лица, оформленное через клиент-банк' },
  { id: 1, name: 'Наличные', description: 'Наличные деньги' },
  { id: 4, name: 'Акт сверки', description: 'Акт сверки взаиморасчётов' },
  { id: 5, name: 'oplati', description: 'Оплата через платёжную систему o-plati.by' },
  { id: 6, name: 'Квитанция', description: 'Квитанция из банка или почты, выданная физ. лицу' },
  { id: 7, name: 'Карта банка', description: 'Банковская карта (в банкомате или через сайт/webpay)' },
];
/**
 * Формы оплаты, которыми разрешено пользоваться при оплате госпошлины
 */
export const payFormsTax = payForms.filter(it => it.id === 2 || it.id === 3 || it.id === 6 || it.id === 7);
export const formsOfOwnership = [{ id: 0, name: 'ФЛ' }, { id: 1, name: 'ЮЛ' }, { id: 2, name: 'ИП' }];

// TODO: читать из БД
export const eripServiceNames = {
  2: 'Госпошлина (ЮЛ)',
  3: 'Госпошлина (ФЛ и ИП)',
  4: 'Разрешение (ЮЛ и ИП)',
  5: 'Разрешение (ФЛ)',
  6: 'Опасные грузы (доп. проверка)',
  7: 'Опасные грузы (свидетельство)',
  8: 'МСТО (M2,M3,N2,N3)',
  9: 'МСТО (О2,О3,О4)',
  10: 'ЕКМТ',
  11: 'Комп. услуги (перенос отметки)',
  12: 'Техосмотр',
  13: 'Перенос информации в новый бланк',
  16: 'Внесение изменений в разрешение',
};

export const blankTypes = {
  1: { id: 1, name: 'Разрешение', defaultSeries: '', seriesFixed: false },
  2: { id: 2, name: 'МСТО', defaultSeries: 'BY', seriesFixed: true },
  3: { id: 3, name: 'ЕКМТ', defaultSeries: 'BY/D', seriesFixed: true },
  4: { id: 4, name: 'ОГ', defaultSeries: '', seriesFixed: true },
  5: { id: 5, name: 'ОГ-2021', defaultSeries: '', seriesFixed: true },
};

export const payStateNames = {
  1: 'совершается',
  2: 'совершен',
  3: 'использован',
  4: 'заблокирован',
  5: 'возврат',
  6: 'возврат',
  7: 'не совершен',
};

export const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export const weekdays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

export const admProcTemplate = 'admProcContractAndAct.docx';
export const serviceTemplate = 'serviceContractAndAct.docx';

// let mstoAdmCommonName = 'Выдача международного сертификата технического осмотра транспортных средств';
// let mstoAdmCommonName2022 = 'Получение международного сертификата технического осмотра';
// let ekmtCommonName = 'Технический контроль состояния дорожных ТС на соответствие техническим требованиям ЕКМТ';
// let ekmtAdmCommonName = 'Подтверждение соответствия транспортных средств техническим нормам ЕКМТ';
// let ekmtAdm2022CommonName = 'Получение сертификата технического контроля';
// let ogCommonName = 'Дополнительная проверка транспортных средств, предназначенных для перевозки определенных опасных грузов';
// let ogAdmCommonName = 'Выдача (продление срока действия) свидетельства о допуске транспортного средства к перевозке определенных опасных грузов';
// let ogAdmCommonName2022 = 'Получение (продление срока действия) свидетельства о допуске транспортного средства к перевозке определенных опасных грузов';
// /**
//  *  Описатель услуги
//  * @param {Number} id идентификатор услуги
//  * @param {String} name название услуги
//  * @param {String} shortName краткое название услуги
//  * @param {Boolean} cert признак того, что услуга связана с выдачей(продлением) сертификата(свидетельства)
//  */
// function ServiceDescriptor(id, name, shortName, cert = true) {
//   this.id = id;
//   this.name = name;
//   this.shortName = shortName;
//   this.cert = cert;
// }
// const ss = {
//   permissionAdm: new ServiceDescriptor(
//     100,
//     'Выдача разрешения на допуск транспортного средства (за исключением колесных тракторов, прицепов, полуприцепов к ним) к участию в дорожном движении',
//     'Разрешение'
//   ),
//   mstoAdmMN: new ServiceDescriptor(200, `${mstoAdmCommonName} для категории M1, M2, M3, N1, N2, N3`, 'МСТО кат. M, N'),
//   mstoAdmO: new ServiceDescriptor(201, `${mstoAdmCommonName} для категории O1, О2, О3, О4`, 'МСТО кат. O'),
//   mstoAdm: new ServiceDescriptor(202, mstoAdmCommonName, 'МСТО выдача'),
//   mstoAdm2022: new ServiceDescriptor(203, mstoAdmCommonName2022, 'МСТО получение'),

//   ekmtTruck2: new ServiceDescriptor(301, 'Грузовой автомобиль 2-осный', 'Технический контроль 2-х осный грузовой', false),
//   ekmtTruck3: new ServiceDescriptor(302, 'Грузовой автомобиль 3-осный', 'Технический контроль 3-х осный грузовой', false),
//   ekmtBus2: new ServiceDescriptor(303, 'Автобус 2-осный', 'Технический контроль 2-х осный автобус', false),
//   ekmtBus3: new ServiceDescriptor(304, 'Автобус 3-осный', 'Технический контроль 3-х осный автобус', false),
//   ekmtTrailer2: new ServiceDescriptor(305, 'Прицеп (полуприцеп) 2-осный', 'Технический контроль 2-х осный прицеп', false),
//   ekmtTrailer3: new ServiceDescriptor(306, 'Прицеп (полуприцеп) 3-осный', 'Технический контроль 3-х осный прицеп', false),
//   ekmtAdm: new ServiceDescriptor(300, ekmtAdmCommonName, 'ЕКМТ подтверждение'),
//   ekmtAdm2022: new ServiceDescriptor(307, ekmtAdm2022CommonName, 'ЕКМТ получение'),

//   og: new ServiceDescriptor(400, ogCommonName, 'ООГ проверка', false),
//   ogAdm: new ServiceDescriptor(401, ogAdmCommonName, 'ООГ выдача'),
//   ogAdm2022: new ServiceDescriptor(402, ogAdmCommonName2022, 'ООГ получение'),
// };

// /**
//  * Описатель набора услуг
//  */
// class ServiceSetDescriptor {
//   /**
//    * Конструктор описателя набора услуг
//    * @param {Number} id идентификатор набора услуг
//    * @param {String} name Название набора услуг
//    * @param {String} commonName Общее название услуг в наборе (например, для услуг 'МСТО M1' и 'МСТО O1' это будет 'МСТО')
//    * @param {Array} tab табличная часть
//    * @param {String} template путь к шаблону docx
//    * @param {Boolean} isService признак того, что набор услуг описывает услуги, а не адм. процедуры
//    * @param {String} article номер пункта единого перечня административных процедур, осуществляемых государственными органами и иными организациями в отношении юридических лиц и индивидуальных предпринимателей, утвержденного постановлением Совета Министров Республики Беларусь от 17.02.2012 № 156, Закона Республики Беларусь "Об основах административных процедур"
//    */
//   constructor(id, name, commonName, tab, template = serviceTemplate, isService = true, article = '') {
//     this.id = id;
//     this.name = name;
//     this.commonName = commonName;
//     this.tab = tab;
//     this.template = template;
//     this.isService = isService;
//     this.article = article;
//     this.from = '2000-01-01';
//     this.to = '2100-01-01';
//   }

//   /**
//    * Установить дату начала действия услуги.
//    * @param {String} from дата начала действия услуги в виде строки в формате ISO (например, '2020-01-01')
//    */
//   _from(from) {
//     this.from = from;
//     return this;
//   }

//   /**
//    * Установить дату окончания действия услуги.
//    * @param {String} to дата окончания действия услуги в виде строки в формате ISO (например, '2020-01-01')
//    */
//   _to(to) {
//     this.to = to;
//     return this;
//   }
// }

// /**
//  * Структура описывает возможные наборы услуг для быстрого заполнения табличной части договора
//  */
// export const serviceSets = {
//   1: [
//     new ServiceSetDescriptor(
//       100,
//       'Разрешение на допуск',
//       'Выдача разрешения на допуск ТС к участию в ДД',
//       [{ subservice: ss.permissionAdm, count: 1 }],
//       admProcTemplate,
//       false,
//       '5.26'
//     ),
//   ],
//   2: [
//     new ServiceSetDescriptor(203, 'МСТО выдача', mstoAdmCommonName, [{ subservice: ss.mstoAdm, count: 1 }], admProcTemplate, false, '5.23')
//       ._from('2020-04-01')._to('2022-03-26T23:59:59'),
//     new ServiceSetDescriptor(204, 'МСТО получение', mstoAdmCommonName, [{ subservice: ss.mstoAdm2022, count: 1 }], admProcTemplate, false, '5.12.3')
//       ._from('2022-03-27'),
//   ],
//   3: [
//     new ServiceSetDescriptor(300, 'Технический контроль 2-х осный грузовой', ekmtCommonName, [{ subservice: ss.ekmtTruck2, count: 1 }]),
//     new ServiceSetDescriptor(302, 'Технический контроль 3-х осный грузовой', ekmtCommonName, [{ subservice: ss.ekmtTruck3, count: 1 }]),
//     new ServiceSetDescriptor(305, 'Технический контроль 2-х осный прицеп', ekmtCommonName, [{ subservice: ss.ekmtTrailer2, count: 1 }]),
//     new ServiceSetDescriptor(306, 'Технический контроль 3-х осный прицеп', ekmtCommonName, [{ subservice: ss.ekmtTrailer3, count: 1 }]),
//     new ServiceSetDescriptor(307, 'Технический контроль 2-х осный грузовой + 2-х осный прицеп', ekmtCommonName, [
//       { subservice: ss.ekmtTruck2, count: 1 },
//       { subservice: ss.ekmtTrailer2, count: 1 },
//     ]),
//     new ServiceSetDescriptor(308, 'Технический контроль 3-х осный грузовой + 3-х осный прицеп', ekmtCommonName, [
//       { subservice: ss.ekmtTruck3, count: 1 },
//       { subservice: ss.ekmtTrailer3, count: 1 },
//     ]),
//     new ServiceSetDescriptor(309, 'Технический контроль 2-х осный грузовой + 3-х осный прицеп', ekmtCommonName, [
//       { subservice: ss.ekmtTruck2, count: 1 },
//       { subservice: ss.ekmtTrailer3, count: 1 },
//     ]),
//     new ServiceSetDescriptor(301, 'Подтверждение ЕКМТ', ekmtAdmCommonName, [{ subservice: ss.ekmtAdm, count: 1 }], admProcTemplate, false, '5.22')._to('2022-03-26T23:59:59'),
//     new ServiceSetDescriptor(310, 'Получение ЕКМТ', ekmtAdm2022CommonName, [{ subservice: ss.ekmtAdm2022, count: 1 }], admProcTemplate, false, '5.12.4')._from('2022-03-27'),
//   ],
//   4: [
//     new ServiceSetDescriptor(400, 'ООГ проверка', ogCommonName, [{ subservice: ss.og, count: 1 }]),
//     new ServiceSetDescriptor(401, 'ООГ выдача', ogAdmCommonName, [{ subservice: ss.ogAdm, count: 1 }], admProcTemplate, false, '5.26¹')._to('2022-03-26T23:59:59'),
//     new ServiceSetDescriptor(402, 'ООГ получение', ogAdmCommonName2022, [{ subservice: ss.ogAdm2022, count: 1 }], admProcTemplate, false, '5.12.2')._from('2022-03-27'),
//     new ServiceSetDescriptor(
//       403,
//       'ООГ проверка+получение',
//       ogAdmCommonName2022,
//       [{ subservice: ss.og, count: 1 }, { subservice: ss.ogAdm2022, count: 1 }],
//       admProcTemplate,
//       false,
//       '5.12.2'
//     )._from('2022-03-27'),
//   ],
// };

/**
 * Тип по европейскому соглашению о международной перевозке грузов ДОПОГ.
 */
export const designations = [
  { c: 1, n: 'EX/II' },
  { c: 2, n: 'EX/III' },
  { c: 4, n: 'FL' },
  { c: 8, n: 'OX' },
  { c: 16, n: 'AT' },
  { c: 32, n: 'MEMU' },
];

// Это только для того, чтобы сборщик не ругался на отсутствие экспорта в файле EditTA.vue
export const taReasons = [];
// Это только для того, чтобы сборщик не ругался на отсутствие экспорта в файле EditContract.vue
export const serviceGroups = [];

export default {
  allReferences,
  patterns,
  payForms,
  payFormsTax,
  formsOfOwnership,
  eripServiceNames,
  payStateNames,
};

export function AjaxError(errCode, msg, httpStatus) {
  var err = Error.call(this, msg);
  err.name = 'AjaxError';
  err.errCode = errCode;
  err.httpStatus = httpStatus;
  return err;
}

/**
 * Создать объект описания ошибки
 * @param {*} response Ответ на запрос HTTP (при наличии)
 * @param {*} errCode Код статуса. Обычно берется из стандартной обертки ответа API
 * @param {String} msg Сообщение. Обычно берется из стандартной обертки ответа API
 * @returns Объект, описывающий ошибку, совместимый с объектом AxiosError
 */
export function AxiosErrorFromReponse(response, errCode, msg) {
  this.response = response;
  this.errCode = errCode;
  if (msg) {
    this.message = msg;
  }
  return this;
}

// export const allServices = ss;
