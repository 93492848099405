<template>
  <div class="container-fluid">
    <vue-context ref="creationMenu">
      <template slot-scope="child">
        <li v-for="(s, i) in ['Разрешение', 'МСТО', 'ЕКМТ', 'ОГ']" :key="i">
          <a @click.prevent="$router.push({ name: 'editRefuse2', params: { admProcType: i + 1 } })">{{ s }}</a>
        </li>
      </template>
    </vue-context>
    <div class="up-right-corner">
      <button
        class="btn btn-success icon-button"
        type="button"
        title="Создать отказ"
        @click.prevent.stop="$refs.creationMenu.open($event)"
      >
        <i class="fa fa-plus fa-2x" />
      </button>
    </div>
    <div class="row">
      <ul class="nav nav-tabs" col-sm-5>
        <li :class="{ active: $route.fullPath == '/refuses/perms' }">
          <router-link to="/refuses/perms">Разрешения</router-link>
        </li>
        <li :class="{ active: $route.fullPath == '/refuses/msto' }">
          <router-link to="/refuses/msto">МСТО</router-link>
        </li>
        <li :class="{ active: $route.fullPath == '/refuses/ekmt' }">
          <router-link to="/refuses/ekmt">ЕКМТ</router-link>
        </li>
        <li :class="{ active: $route.fullPath == '/refuses/og' }">
          <router-link to="/refuses/og">ОГ</router-link>
        </li>
      </ul>
    </div>
    <router-view />
  </div>
</template>

<script>
import { tabbedSubroutes } from '@/lib/mixins';
export default {
  data() {
    return {
      componentName: 'refuses',
      defaultSubRoute: 'perms',
    };
  },
  mixins: [tabbedSubroutes],
};
</script>
