import EditMsto from '@/components/msto/edit/EditMsto';
import MstoList from '@/components/msto/MstoList';
import ProlongMstoList from '@/components/msto/ProlongMstoList';
import MstoPage from '@/components/msto/MstoPage';
import CommonBlanksList from '@/components/CommonBlanksList';

export default [
  {
    path: '/mstos',
    component: MstoPage,
    children: [
      {
        path: 'blanks',
        component: CommonBlanksList,
        name: 'mstoBlanks',
        meta: {
          title: 'Бланки МСТО',
        },
      },
      {
        path: 'certs',
        component: MstoList,
        name: 'mstoCerts',
        meta: {
          title: 'МСТО',
        },
      },
      {
        path: 'prolong',
        component: ProlongMstoList,
        name: 'prolongMsto',
        meta: {
          title: 'Продление МСТО',
        },
      },
    ],
  },
  {
    path: '/editMsto/:mstoId?',
    name: 'editMsto',
    component: EditMsto,
    hidden: true,
    meta: {
      title: 'Редактирование МСТО',
    },
  },
];
