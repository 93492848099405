<template>
  <div>
    <div style="height:270px;overflow:auto;">
      <div class="form-group">
        <label class="control-label col-sm-4" :class="{ 'has-error': $v.blankId.$invalid }">Бланк</label>
        <div class="col-sm-8 no-pad">
          <vue-single-select v-model="blankId" :options="blanks" :filter-by="filterKeyboardAgnostic" ref="blanks" :max-results="6" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn btn-success footer-btn" type="button" @click="$emit('close', blankId)" :disabled="$v.$invalid">Выбрать</button>
        <button class="btn footer-btn" type="button" @click="$emit('close')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import { numberPad, rus2latLowercase } from '@/lib/funcs';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { required } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      blanks: [],
      initialFetchFailure: false,
      blankId: null,
    };
  },
  validations() {
    return { blankId: { required } };
  },
  props: {
    title: { type: String, default: 'Выбрать бланк' },
    blankType: { type: Number, default: 1 },
  },
  methods: {
    filterKeyboardAgnostic(option) {
      let l = rus2latLowercase(option.name);
      if (!l) {
        return false;
      }
      let searchText = rus2latLowercase(this.$refs.blanks.searchText.toString());
      return l.includes(searchText);
    },
  },
  mounted() {
    let vm = this;
    ajax.getBlanks({ type: vm.blankType, sk: 0, pageSize: 10000 }).then(
      r => {
        let blanks = [];
        for (let b of r.data.content[2]) {
          blanks.push({ id: b.id, name: b.s + numberPad(b.n, 7) });
        }
        vm.blanks = blanks;
      },
      err => {
        vm.initialFetchFailure = !0;
        vm.$showError('Ошибка загрузки бланков', err);
      }
    );
  },
  components: { VueSingleSelect },
};
</script>
