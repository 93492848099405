<template>
  <div>
    <msto-list-common :ajax-loader="ajaxLoader" :ajax-params="{}" />
  </div>
</template>

<script>
import MstoListCommon from './MstoListCommon';
export default {
  data() {
    return {
      ajaxLoader: ajax.mstoList,
    };
  },
  methods: {
    load() {},
  },
  mounted() {
    this.load();
  },
  components: { MstoListCommon },
};
</script>

<style>
</style>
