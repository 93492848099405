<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="clientMenu">
      <template slot-scope="child">
        <li><a @click.prevent="addCounteragent">Добавить</a></li>
        <li><a @click.prevent="selectCounteragent">Выбрать</a></li>
        <li v-if="m.counteragentId"><a @click.prevent="editCounteragent">Редактировать {{ shrinkString(info.name, 15) }}</a></li>
      </template>
    </vue-context>
    <div style="height:390px;overflow:auto;">
      <!-- № договора -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.number.$invalid }">№ договора</label>
        <div class="col-sm-9 input-group">
          <input class="form-control" type="text" name="number" v-model="m.number" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" title="Сгенерировать номер" @click="nextContractNum">
              <i class="fa fa-step-forward"></i>
            </button>
          </span>
        </div>
        <div v-if="$v.m.number.$invalid" class="col-sm-offset-3 error-label-x">Введите № договора</div>
      </div>

      <!-- дата договора -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.date.$invalid }">Дата</label>
        <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
          <rome-datepicker v-model="m.date" class="form-control" :options="{ time: false, inputFormat: 'DD.MM.YYYY' }" modelFormat="YYYY-MM-DD" />
          <div v-if="$v.m.date.$invalid" class="error-label">Требуется дата</div>
        </div>
      </div>
      <!-- Клиент: -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.counteragentId.$invalid }">Клиент</label>
        <div class="col-sm-9 input-group">
          <input :title="info.name" class="form-control" type="text" name="contractNum" disabled :value="clientDescription" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" title="Выбрать клиента" @click.prevent.stop="$refs.clientMenu.open">...</button>
          </span>
        </div>
      </div>

      <!-- сумма договора: -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.vat.$invalid || $v.m.total.$invalid }">Сумма и НДС</label>
        <div class="col-sm-9 input-group">
          <div class="col-sm-6 no-pad">
            <input class="form-control" type="number" name="total" v-model="m.total" readonly />
            <div v-if="$v.m.vat.$invalid || $v.m.total.$invalid" class="error-label">Добавьте услуги</div>
          </div>
          <div class="col-sm-6 no-pad">
            <input class="form-control" type="number" name="vat" v-model="m.vat" readonly />
          </div>
        </div>
      </div>
      <tabular-part v-model="m.tabular" :subServiceGroups="subServiceGroups" @changed="tabularChanged" />
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn footer-btn" type="button" @click="print"><i class="fa fa-print" />Печатать</button>
        <button class="btn btn-warning footer-btn" type="button" @click="$emit('close')">Отмена</button>
        <button class="btn btn-success footer-btn" type="button" @click="save">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import RomeDatepicker from '@/controls/RomeDatepicker';
import { required, minValue } from 'vuelidate/lib/validators';
import { shrinkString } from '@/lib/funcs';
import { serviceGroups } from '@/lib/consts';
import { modifiable } from '@/lib/mixins';
import SelectCounteragent from './SelectCounteragent';
import EditCounteragent from './EditCounteragent';
import TabularPart from './TabularPart';
import _isFunction from 'lodash/isFunction';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      blockUI: false,
      m: {
        contractId: null,
        counteragentId: null,
        date: moment().format('YYYY-MM-DD'),
        number: '',
        serviceId: 0,
        total: null,
        vat: null,
      },
      // объект для хранения доп. данных о клиенте (имя и УНП), которые не содержатся в договоре непосредственно
      info: { name: null, unp: null },
      m_: {},
      subServiceGroups: serviceGroups[this.serviceId],
      subServiceGroup: null,
    };
  },
  validations() {
    return {
      m: {
        counteragentId: { required, minValue: 1 },
        // contractId: { required, minValue: 1 },
        date: { required },
        total: { required, minValue: minValue(0.01) },
        vat: { required, minValue: minValue(0.01) },
        number: { required },
      },
    };
  },
  props: {
    initialId: {
      type: Number,
      default: null,
    },
    serviceId: {
      type: Number,
      required: true,
    },
  },
  components: { TabularPart, RomeDatepicker },
  computed: {
    clientDescription() {
      let info = this.info;
      let d = shrinkString(info.name, 40);
      return (info.unp ? info.unp + ' ' : '') + d;
    },
  },
  methods: {
    shrinkString,
    // postLoad(r) {
    //   r.date = moment(r.date).toDate()
    //   return r
    // },
    prepareToSave(model) {
      let m = _cloneDeep(model);
      m.date = moment(m.date)
        .format()
        .substring(0, 10);
      return m;
    },
    print() {
      let vm = this;
      if (vm.m.contractId && _isEqual(vm.m, vm.m_)) {
        window.open('s/docs/contractAsPDF/' + vm.m.contractId, '_blank');
      } else {
        this.$dlg.alert('Можно печатать только сохраненные договоры', {
          messageType: 'warning',
        });
      }
    },
    tabularChanged(payload) {
      let m = this.m;
      m.total = payload.total;
      m.vat = payload.vat;
    },
    editCounteragent() {
      let id = this.m.counteragentId;
      if (id) this.addOrEditCounteragent(id);
    },
    addCounteragent() {
      this.addOrEditCounteragent();
    },
    addOrEditCounteragent(initialId) {
      let vm = this;
      vm.$dlg.modal(EditCounteragent, {
        params: {
          initialId,
          serviceId: vm.serviceId,
        },
        width: 800,
        height: 520,
        title: (initialId ? 'Редактирование ' : 'Создание') + ' клиента для договора ' + vm.m.number,
        callback: function(rslt) {
          if (rslt) {
            vm.m.counteragentId = rslt.counteragentId;
            vm.info = rslt;
          }
        },
      });
    },
    selectCounteragent() {
      let vm = this;
      vm.$dlg.modal(SelectCounteragent, {
        width: 800,
        height: 500,
        title: 'Подбор клиента',
        callback: function(rslt) {
          if (rslt) {
            vm.info = rslt;
            vm.m.counteragentId = rslt.counteragentId;
          }
        },
      });
    },
    save() {
      function makeReturn(m) {
        return {
          name: vm.info.name,
          unp: vm.info.unp,
          contractId: m.contractId,
          contractNum: m.number,
          contractDate: m.date,
        };
      }
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      if (vm.$v.m.$invalid) {
        vm.$dlg.alert('Правильно заполните все поля', {
          messageType: 'warning',
        });
        return;
      }
      if (vm.m.contractId && _isEqual(vm.m, vm.m_)) {
        this.$log.debug('no changes');
        vm.$emit('close', makeReturn(vm.m));
      } else {
        let m = vm.prepareToSave(vm.m);
        vm.blockUI = true;
        sign(m)
          .then(ajax.saveContract)
          .then(r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
            vm.$emit('close', makeReturn(vm.m));
          })
          .catch(err => {
            vm.$showError('Ошибка записи договора', err);
          })
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
    nextContractNum(cb) {
      let vm = this;
      vm.blockUI = true;
      ajax
        .nextContractNum({ serviceId: vm.serviceId })
        .then(
          r => {
            vm.m.number = r.data.content;
            if (_isFunction(cb)) cb();
          },
          err => {
            vm.$showError('Не смог получить номер договора', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    let vm = this;
    vm.blockUI = true;
    vm.m.serviceId = vm.serviceId;
    vm.m_ = _cloneDeep(vm.m);
    if (vm.initialId) {
      ajax
        .getContractAndInfo({ id: vm.initialId })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
            let c = r.data.content.info;
            if (c) {
              vm.info = Object.assign(vm.info, c);
            }
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    } else {
      vm.nextContractNum(() => {
        vm.m_ = _cloneDeep(vm.m);
      });
    }
  },
  mixins: [modifiable],
};
</script>
