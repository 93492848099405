import _isObject from 'lodash/isObject';

export const hashCode = (param) => {
  var hash = 0;
  var i;
  var chr;
  if (typeof param !== 'string' || param.length === 0) return hash;
  for (i = 0; i < param.length; i++) {
    chr = param.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Вычисляет пароль на открытие отладочного режима на текущий час
 */
export const currentDebugPassword = () =>
  Math.abs(
    hashCode(
      moment()
        .format('YYYY-MM-DDTHH')
        .split('')
        .reverse()
        .join('')
    )
  );

/**
 * Очищает входной объект. Строки становятся пустыми строками, всё остальное - null. Учитывает вложенность объектов.
 * на циклических ссылках зависнет
 * @param {Object} objx
 */
export const clearObjectFields = (objx) => {
  let objs = [objx];
  while (objs.length > 0) {
    let obj = objs.shift();
    for (var k in obj) {
      if (_isObject(obj[k])) {
        objs.push(obj[k]);
      } else if (typeof obj[k] === 'string') {
        obj[k] = '';
        // } else if (typeof obj[k] === "object") {
        //     obj[k] = {}
      } else {
        obj[k] = null;
      }
    }
  }
};

/**
 * функция, автоматически приводящая введенные данные к верхнему регистру без потери фокуса
 * обязательно должна объявляться в виде "forceUpper = function(e, o, prop) {", а не "forceUpper = (e, o, prop) => {"
 * в противном случае переменная this будет установлена в неверное значение
 * использование:
 * import { forceUpper } from "@/lib/funcs"
 * methods: {
 *    ...
 *    forceUpper: forceUpper
 * }
 *  https://forum.vuejs.org/t/inputs-always-in-uppercase-using-v-model/7450/8
 * @param {Object} e объект события
 * @param {*} o объект, в котором содержится модифицируемое свойство
 * @param {*} prop имя свойства
 */
export const forceUpper = function(e, o, prop) {
  const start = e.target.selectionStart;
  e.target.value = e.target.value.toUpperCase();
  this.$set(o, prop, e.target.value);
  e.target.setSelectionRange(start, start);
};

/**
 * Автоматически выставляет в объекте "o" свойство "prop" в null, если в событии изменения "e" новое значение является чистой строкой
 * (т.е. пустой или состоящей только из пробельных символов).
 * обязательно должна объявляться в виде "setNullIfBlank = function(e, o, prop) {", а не "setNullIfBlank = (e, o, prop) => {"
 * в противном случае переменная this будет установлена в неверное значение
 * использование:
 *
 * <input :value="m.t.producer" @input="setNullIfBlank($event, m.t, 'producer')" ... />
 *
 * import { setNullIfBlank } from "@/lib/funcs"
 * methods: {
 *    ...
 *    setNullIfBlank: setNullIfBlank
 * }
 *  https://forum.vuejs.org/t/inputs-always-in-uppercase-using-v-model/7450/8
 * @param {Object} e объект события
 * @param {*} o объект, в котором содержится модифицируемое свойство
 * @param {*} prop имя свойства
 */
export const setNullIfBlank = function(e, o, prop) {
  let value = e.target.value;
  if (value == null || value.trim() == '') value = null;
  this.$set(o, prop, value);
};

/**
 * Проверка пароля на соответствие требованиям сложности
 * @param {String} pass проверяемый пароль
 * @returns true, если пароль годный
 */
export const passwordComplexity = (pass) => {
  if (!pass || typeof pass !== 'string' || pass.length < 8) return false;
  let hasLower = false;
  let hasUpper = false;
  let hasDigits = false;
  let hasSpecials = false;
  for (var i = 0; i < pass.length; i++) {
    let c = pass[i];
    if (!isNaN(parseInt(c, 10))) {
      hasDigits = true;
      continue;
    }
    if ('!@#$%^&*()_-+={}[]|\\/?\'";:'.includes(c)) {
      hasSpecials = true;
      continue;
    }
    if (c.toLowerCase() === c) {
      hasLower = true;
      continue;
    }
    if (c.toUpperCase() === c) {
      hasUpper = true;
    }
  }
  return hasLower && hasUpper && hasDigits && hasSpecials;
};

/**
 * Грязный хак для работы с ошибками в часовых поясах
 * Многим лениво или они не умеют выставить часовой пояс. Приводит к тому, что поля времени сдвигаются на
 * часы, а поля даты - на сутки.
 * Функцию нужно вызывать после получения объекта с сервера.
 * Несколько сумбурное описание работы:
 * 1. Время в БД хранится в часовом поясе беларуси (GMT+3)
 * 2. При разборе временнЫх полей на клиенте время в них корректируется таким образом, чтобы
 * цифры часов, минут в белорусском времени и преобразованном времени на клиенте остались одинаковыми.
 * Т.е. если дата выдачи ДК - "2019-01-05 15:50:01+300", то клиент тоже увидит время 15:50:01, даже если
 * у него другой часовой пояс.
 * Иными словами, у пользователя создается впечатление, что мир не разбит на часовые пояса.
 * Пример
 * в БД хранится "2019-01-03" (часовой пояс +3). При передаче на клиент - "2019-01-02T21:00:00.000+0000"
 * После преобразования получается "2019-01-03T00:00:00.000+0700" (если на клиенте часовой пояс +7)
 * @param {Date} dt преобразуемая дата
 * @param {Date} forSave признак преобразования для отправки на сервер
 */
export const correctTZ = (dt, forSave) => {
  if (!dt) return null;
  dt.setMinutes(dt.getMinutes() + (new Date().getTimezoneOffset() + 180) * (forSave ? -1 : 1));
  return dt;
};

// export const fillTime = targetDate => {
//   if (!targetDate) return
//   const dt = new Date()
//   targetDate.setHours(dt.getHours())
//   targetDate.setMinutes(dt.getMinutes())
//   targetDate.setSeconds(dt.getSeconds())
//   correctTZ(targetDate)
// }

export const mergeRefs = (refs) => {
  function merge(args) {
    // args  = Array.prototype.slice.call(arguments)
    var o = {};
    for (var i = 0; i < args.length; ++i) {
      for (var j = 0; j < args[i].length; ++j) {
        o[args[i][j].id] = args[i][j];
      }
    }
    return o;
  }

  function expand(o) {
    var a = [];
    for (var p in o) {
      if (Object.prototype.hasOwnProperty.call(o, p)) {
        a.push(o[p]);
      }
    }
    return a;
  }

  return expand(merge(refs));
};

export const byNameComparator = (a, b) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  } else {
    return 0;
  }
};

export const bySortOrderAndNameComparator = (a, b) => {
  if (a.so < b.so) {
    return -1;
  } else if (a.so > b.so) {
    return 1;
  } else {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  }
};

/**
 * Преобразует строку в число, дополняя слева нулями до указанной длины.
 * @param {number} num Исходное число.
 * @param {Number} padSize Минимальное количество знаков в результирующей строке.
 */
export const numberPad = (num, padSize) => {
  return (1e9 + num + '').slice(-padSize);
};

let substRus2latUpcase = {
  а: 'a',
  в: 'b',
  е: 'e',
  ё: 'e',
  к: 'k',
  м: 'm',
  н: 'h',
  о: 'o',
  р: 'p',
  с: 'c',
  т: 't',
  у: 'y',
  х: 'x',
};

/**
 * Заменяет в строке символы русского алфавита на подобные по написанию латинские.
 * @param { String } input входная строка
 * @returns строка с замененными символами, приведенная к нижнему регистру
 */
export const rus2latLowercase = (input) => {
  if (!input || typeof input !== 'string') {
    return input;
  }
  let x = input.toLocaleLowerCase().split('');
  let result = '';
  for (let c of x) {
    if (c in substRus2latUpcase) {
      result = result + substRus2latUpcase[c];
    } else {
      result = result + c;
    }
  }
  return result;
};

export const isTransfer = (flags) => {
  return flags & (1 << 8 | 1 << 13 | 1 << 18 | 1 << 19);
};

export const describeTransfer = (flags) => {
  return flags & 256 ? 'перенос' : 'разрешение';
};

// const unpCache = {}
//
// export const updateNameByUNP = function(unp, obj, propname) {
//   if (typeof unp !== "number" || unp < 100000000 || unp > 999999999) {
//     return
//   }
//   if (unp in unpCache) {
//     if (unpCache[unp]) {
//       obj[propname] = unpCache[unp]
//     }
//   } else {
//     var pr = ajax.getUnpInfo({ unp })
//     pr.then(
//       resp => {
//         var x = resp.data.content
//         unpCache[unp] = x
//         if (x) {
//           obj[propname] = x
//         }
//       },
//       err => {
//         log.error(err)
//       }
//     )
//   }
// }

// export const resolveObjectFields = (obj, fieldPath) => {
//   return fieldPath.split(".").reduce(function(prev, curr) {
//     return prev ? prev[curr] : null
//   }, obj || self)
// }

export const isoDate = (inDate) => {
  const d = new Date(inDate);
  var mm = d.getMonth() + 1; // getMonth() is zero-based
  var dd = d.getDate();
  return [d.getFullYear(), '-', mm < 10 ? '0' : '', mm, '-', dd < 10 ? '0' : '', dd].join(''); // padding
};

// export const russianDate = (inDate) => {
//   if (inDate == null) return null
//   const d = new Date(inDate)
//   var mm = d.getMonth() + 1 // getMonth() is zero-based
//   var dd = d.getDate()
//   return [dd < 10 ? "0" : "", dd, ".", mm < 10 ? "0" : "", mm, ".", d.getFullYear()].join("") // padding
// }

export const russianDate = (inDate) => {
  if (inDate == null) return null;
  return moment(inDate).format('DD.MM.YYYY');
  // return moment(inDate).format('L')
};

// export const findRefNameById = (ref, id) => {
//   var r = ref.find(function(e) {
//     return e.id == id
//   })
//   return r ? r.name : ""
// }
export const freshPayment = (sum) => {
  return {
    payId: null,
    docNum: null,
    s: isNaN(sum) ? 0 : sum,
    t: 3,
    docDate: moment().format('YYYY-MM-DD'),
  };
};

/**
 * Проверяет УНП на вхождение в диапазон и правильность контрольной суммы
 */
export const isUnpValid = (unp) => {
  if (unp < 100000000 || unp > 999999999) return false;
  let weights = [3, 5, 7, 13, 17, 19, 23, 29];
  let v = Number(unp);
  let lastDigit = v % 10;
  v = Math.floor(v / 10);
  let control = 0;
  for (let i = 0; i < 8; i++) {
    control += (v % 10) * weights[i];
    v = Math.floor(v / 10);
  }
  return lastDigit == control % 11;
};

/**
 * Создает новый пустой объект "клиент".
 * @param {Number} legalType форма собственности. Если не указать, будет ФЛ (0)
 */
export const freshClient = (legalType) => {
  return { t: typeof legalType == 'undefined' ? 0 : Number(legalType), n: '', u: null, b: null };
};

/**
 *
 * @param {Object} userInfo объект userInfo (в нем есть инфо о дате запрета изменений для пользователя и его ролях)
 * @param {Object} a объект CertificateAction (там дата действия)
 */
export const editingDisabled = (userInfo, a) => {
  let ban = moment(userInfo.banDate);
  let aDate = moment(a.date);
  return ban > aDate;
};

export const freshCertificateAction = (currentUser, userSettings) => {
  let a = {
    actionNumber: 0,
    userName: (currentUser || {}).name,
    userId: (currentUser || {}).id,
    date: moment().format('YYYY-MM-DD'),
    validTo: moment()
      .add(1, 'y')
      .add(-1, 'd')
      .format('YYYY-MM-DD'),
    actionId: null,
    actId: null,
    actNum: null,
    certificateType: 0,
    dsId: (userSettings || {}).curDsId,
    place: '',
  };
  return a;
};

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const updateObjectOnlyIfContainedInReference = (ref, newId, obj, propName) => {
  if (
    ref.find(function(e) {
      return e.id == newId;
    })
  ) {
    obj[propName] = newId;
  } else {
    obj[propName] = null;
  }
};

/**
 * "Сливает 2 массива". т.е. находит в первом массиве объекты, у которых свойство propname совпадает со
 * свойством propname второго массива и заменяет объект в первом массиве объектом из второгомассива.
 * Если соответствия не найдено, элемент второго массива добавляется в первый
 *
 * oArray = [{id:1, name:"Apple"}, {id:2, name:"Bananos"}]
 * addArray= [{id:3, name:"Pea"}, {id:2, name:"Banana"}]
 * результат вызова mergeObjectArrays(oArray, addArray, 'id'):
 * [{id:1, name:"Apple"}, {id:2, name:"Banana"}б {id:3, name:"Pea"}]
 * @param {Array} oArray первый массив
 * @param {Array} addArray заменяющий массив
 * @param {String} propname имя свойства
 * @returns {Array} результирующий массив
 */
export const mergeObjectArrays = (oArray, addArray, propname) => {
  var result = oArray.slice();
  for (var idx in addArray) {
    let obj = addArray[idx];
    var found = false;
    for (var i = 0; i < result.length; i++) {
      if (result[i][propname] === obj[propname]) {
        result[i] = obj;
        found = true;
        break;
      }
    }
    if (!found) {
      result.push(obj);
    }
  }
  return result;
};

/**
 * Считает количество непустых свойств входного объекта. Учитывает вложенность объектов.
 * Булевские значения и пустые строки не учитываются.
 * на циклических ссылках зависнет
 * @param {Object} objx
 */
export const countNonBlanksFields = (objx) => {
  var counter = 0;
  let objs = [objx];
  while (objs.length > 0) {
    let obj = objs.shift();
    for (var k in obj) {
      let value = obj[k];
      if (typeof value === 'string' && value.trim() === '') continue;
      if (typeof value === 'boolean') continue;
      if (_isObject(value)) {
        objs.push(value);
      } else if (value) {
        counter++;
      }
    }
  }
  return counter;
};

export const loadComponentState = (name, _default) => {
  const state = localStorage.getItem('compState_' + name);
  if (!state && _default) {
    return _default;
  }
  try {
    return JSON.parse(state);
  } catch (e) {
    return _default;
  }
};

export const storeComponentState = (name, state) => {
  localStorage.setItem('compState_' + name, JSON.stringify(state));
};

/**
 * Возвращает исходную строку, урезанную до указанной длины и дополненную троеточием. Если урезание не произошло, точки не добавляются
 * @param {String} s Исходная сокращаемая строка
 * @param {Number} maxLen максимальная длина строки. Если не задана явно, равна 20 символам
 * @returns {String} Сокращенную и дополненную троеточием строку
 */
export const shrinkString = (s, maxLen) => {
  if (!s) return '';
  let l = maxLen | 20;
  if (typeof s !== 'string' || s.length < l) return s;
  return s.substring(0, l) + '...';
};

export const randomFromRange = (n1, n2) => {
  return Math.floor(Math.random() * (n2 - n1) + n1);
};

export const randomArrayItem = (arr) => {
  if (!arr) {
    return null;
  }
  return arr[Math.floor(Math.random() * arr.length)];
};

export function randomDate() {
  return moment({
    y: Math.floor(Math.random() * 16 + 2015),
    M: Math.floor(Math.random() * 11),
    d: Math.floor(Math.random() * 28),
    h: Math.floor(Math.random() * 12 + 8),
    m: Math.floor(Math.random() * 60),
    s: Math.floor(Math.random() * 60),
    ms: 123,
  }).format('YYYY-MM-DDTHH:mm:ss');
}

export const randomChars = (len) => {
  var alphabet = [
    'А',
    'Б',
    'В',
    'Г',
    'В',
    'Е',
    'Ж',
    'З',
    'И',
    'К',
    'Л',
    'М',
    'Н',
    'О',
    'П',
    'Р',
    'С',
    'Т',
    'У',
    'Ф',
    'Х',
    'Ц',
    'Ч',
    'Ш',
    'Щ',
    'Ь',
    'Ы',
    'Ъ',
    'Э',
    'Ю',
    'Я',
  ];
  var s = '';
  var i;
  for (i = 0; i < len; i++) {
    const idx = Math.min(Math.random() * alphabet.length, alphabet.length - 1);
    s = s + alphabet[Math.floor(idx)];
  }
  return s;
};

/**
 * Перейти наверх страницы
 */
export const toPageTop = function() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

/**
 * Проверяет, что строка пуста или состоит только из пробелов
 * @param {String} s входная строка
 */
export const isBlankOrEmpty = (s) => {
  if (!s || typeof s !== 'string') return true;
  for (let i = 0; i < s.length; i++) {
    if (s.charAt(i) != ' ') return false;
  }
  return true;
};

const allfunctions = {
  numberPad,
};

export const mapFunctions = function(names) {
  return names.reduce((result, filter) => {
    result[filter] = allfunctions[filter];
    return result;
  }, {});
};

/**
 * Анализирует объект $v валидатора vuelidate. Проходит по объекту, заглядывая в свойства и массивы. На выходе - массив свойств модели, которые не валидны.
 * @param {String} path Анализируемый путь внутри объекта (для записи в результирующий массив). Необходимо передавать пустую строку
 * @param {Object} obj объект - валидатор vuelidate ($v)
 * @param {Array} result результирующий массив для добавления путей свойств с ошибками. Необходимо передать массив (чаще всего пустой).
 *    Пример того, что получается на выходе: ['m.cn', 'm.payments[0].payId']
 */
export const vuelidateExtractErrors = function(path, obj, result) {
  console.log('evaluating ' + path);
  let leaf = true;
  for (let name in obj) {
    let value = obj[name];
    if (!name.startsWith('$') && _isObject(value)) {
      vuelidateExtractErrors(`${path}.${name}`, value, result);
      leaf = false;
    }
  }
  if (typeof obj.$each != 'undefined') {
    for (let index in obj.$each) {
      let value = obj.$each[index];
      if (!index.startsWith('$') && _isObject(value)) {
        vuelidateExtractErrors(`${path}.${name}[${index}]`, value, result);
        leaf = false;
      }
    }
  }
  if (leaf && obj.$invalid) {
    result.push(path);
  }
};

/**
 * Кодирует строку в кодировку Base64
 * @param {String} str исходная строка для кодирования
 * @returns Закодированная строка
 */
export const b64EncodeUnicode = (str) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    })
  );
};

export const describeGaiInfo = (o) => {
  if (!o) return null;
  let result = `Информация по ТС от ГАИ:
Техпаспорт: ${o.DOC_SERY} ${o.DOC_NUM}
Марка: ${o.TRANSP_MARK_RUS || '-'}
Модель: ${o.TRANSP_MODEL_RUS || '-'}
Госномер основной: ${o.TRANSP_NUM || '-'}
Госномер транзитный: ${o.TRANSP_NUM_TRANSIT || '-'}
Тип: ${o.TRANSP_TYPE || '-'}
VIN: ${o.TRANSP_BODY || '-'}
Шасси: ${o.TRANSP_CHASSIS || '-'}
Год: ${o.TRANSP_MADE_DATE || '-'}
Масса собственная: ${o.TRANSP_WEIGHT || '-'}
Масса максимальная: ${o.TRANSP_MAX_WEIGHT || '-'}
Срок регистрации: ${o.REG_DATE_FROM} - ${o.REG_DATE_TO}
Цвет: ${o.COLOR_STR || '-'}
Модификация: ${o.MODIFICATION || '-'}
Категория: ${o.TRANSP_CATEGORY || '-'}
Тип привода: ${o.WHEEL_DRIVE || '-'}
Тип специального ТС: ${o.SPEC_TYPE || '-'}
Число мест: ${o.PASSENGER_SEATS_NUM || '-'}
Тип двигателя: ${o.ENGINE_TYPE || '-'}
Экокласс: ${o.TRANSP_EKO_CLASS || '-'}
Отметки: ${o.SPECIAL_MARKS || '-'}`;
  return result;
};

/**
 * Преобразует во входной строке русские символы в похожие по написанию латинские. Например русское А (U+0410) в латинское A (U+0041).
 * @param {String} input входная строка.
 * @returns Строка, в которой русские символы преобразованы в похожие по написанию латинские.
 */
const rus2latChars = {
  а: 'a',
  в: 'b',
  е: 'e',
  к: 'k',
  м: 'm',
  н: 'h',
  о: 'o',
  р: 'p',
  с: 'c',
  т: 't',
  х: 'x',
  і: 'i',
  А: 'A',
  В: 'B',
  Е: 'E',
  К: 'K',
  М: 'M',
  Н: 'H',
  О: 'O',
  Р: 'P',
  С: 'C',
  Т: 'T',
  Х: 'X',
  І: 'I',
};
export const rus2lat = (input) => {
  if (typeof input !== 'string') return null;
  let output = '';
  for (let char of input) {
    output += rus2latChars[char] || char;
  }
  return output;
};

export const parseNumberWithDefault = (input, def) => {
  let v = parseInt(input);
  return isNaN(parseInt(v)) ? def : v;
};

export default {
  hashCode,
  clearObjectFields,
  correctTZ,
  updateObjectOnlyIfContainedInReference,
  // fillTime,
  mergeRefs,
  byNameComparator,
  bySortOrderAndNameComparator,
  // updateNameByUNP,
  // resolveObjectFields,
  // isoDate,
  // russianDate,
  // findRefNameById,
  numberPad,
  isTransfer,
  describeTransfer,
};
