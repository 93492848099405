<template>
  <div>
    <div class="fixed-header">
      <span class="fixed-header-title"
        >Заявление на перенос {{ m.id ? ' №' + m.id : '' }} {{ m.aDate ? ' от ' + russianDate(m.aDate) : '' }}</span
      >
      <span class="fa-pull-right">
        <button
          class="btn btn-success icon-button"
          type="button"
          title="Сохранить"
          @click="save()"
          :disabled="initialFetchFailure || $v.m.$invalid"
        >
          <i class="fa fa-save fa-2x"></i>
        </button>
        <button class="btn btn-danger icon-button" type="button" title="Отмена" @click="$router.go(-1)">
          <i class="fa fa-window-close fa-2x"></i>
        </button>
      </span>
    </div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div class="under-fixed-header container">
      <div class="alert alert-info row" v-if="m.cDate">Работы по этому заявлению завершены. Изменять его нельзя</div>
      <div class="panel panel-default row">
        <div class="panel-heading text-center">Шаг 1. Регистрация заявления</div>
        <div class="panel-body">
          <div class="container-fluid">
            <form novalidate onsubmit="return false;" v-show="!initialFetchFailure">
              <fieldset :disabled="m.cDate">
                <client-component v-model="m.client" :v="$v.m.client" />
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.cn.$invalid }"
                    >НОВЫЙ техпаспорт</label
                  >
                  <div class="col-sm-8 no-pad">
                    <input type="text" v-model="m.cn" class="form-control" @input="forceUpper($event, m, 'cn')" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.pn.$invalid }"
                    >СТАРОЕ разрешение</label
                  >
                  <div class="col-sm-8 no-pad">
                    <input
                      type="text"
                      v-model="m.pn"
                      class="form-control"
                      @input="forceUpper($event, m, 'pn')"
                      :placeholder="!m.refused && 'Будет подставлено, когда завершится оформление переноса'"
                      :disabled="!m.refused"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.reasonId.$invalid }">Причина</label>
                  <div class="col-sm-8 no-pad">
                    <select class="form-control" v-model="m.reasonId">
                      <option v-for="r in taReasons" :key="r.id" :value="r.id">{{ r.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.aDate.$invalid }"
                    >Дата регистрации заявления</label
                  >
                  <div class="col-sm-8 no-pad" style="margin-bottom: 0.5em;">
                    <rome-datepicker
                      v-model="m.aDate"
                      class="form-control"
                      :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
                      modelFormat="YYYY-MM-DD"
                      @input="dateChanged"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="icheck-primary col-sm-offset-4">
                    <input type="checkbox" id="refused" name="refused" v-model="m.refused" />
                    <label for="refused">Отказано в переносе</label>
                  </div>
                </div>
                <div class="panel panel-warning row">
                  <div class="panel-heading">
                    Оплата услуг поиска
                    <div class="btn-group">
                      <button type="button" class="btn btn-in-bar" @click="addPayment">
                        <i class="fa fa-plus-square"></i>
                      </button>
                    </div>
                  </div>
                  <div class="panel-body">
                    <payment-set v-model="m.p" :v="$v.m.p" :allowed-services="[11]" />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div class="panel panel-default row" v-show="m && !m.cDate && m.id">
        <div class="panel-heading text-center">Шаг 2. Поиск разрешения для переноса</div>
        <div class="panel-body">
          <form class="form-inline" onsubmit="return false;">
            <div class="form-group">
              <div
                class="input-group col-xs-6"
                v-for="(o, i) in [
                  { n: 'pn', t: 'Разрешение' },
                  { n: 'vin', t: 'VIN' },
                  { n: 'cn', t: 'Техпаспорт' },
                  { n: 'rn', t: 'Госномер' },
                ]"
                :key="i"
              >
                <input
                  :placeholder="o.t"
                  type="text"
                  v-model="s[o.n]"
                  :name="o.n"
                  class="form-control"
                  :id="o.n"
                  @keyup.enter="$v.s[o.n].$invalid || search(o.n)"
                  @input="forceUpper($event, s, o.n)"
                />
                <span class="input-group-btn">
                  <button type="button" class="btn btn-default" @click="search(o.n)" :disabled="$v.s[o.n].$invalid">
                    <span class="fa fa-search"></span>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <table class="table table-hover table-mc-light-blue table-striped">
            <thead>
              <tr>
                <th>
                  № разрешения
                  <br />Госномер
                </th>
                <th>
                  Дата выдачи
                  <br />Срок действия
                </th>
                <th>
                  vin (разрешение)
                  <br />vin (КДР)
                </th>
                <th>
                  Модель
                  <br />год
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="permResults && permResults.length === 0">
              <tr>
                <td class="text-center red-text" colspan="4" style="padding-top:20px;">{{ searchDescription }}</td>
              </tr>
            </tbody>
            <tbody v-if="permResults && permResults.length > 0">
              <tr v-for="(p, i) in permResults" :key="i">
                <td>
                  {{ p.num }}
                  <span v-if="isTransfer(p.fl)" class="fa-stack fa-lg">
                    <i class="fa fa-arrows-h fa-stack-1x"></i>
                  </span>
                  <br />
                  {{ p.rn }}
                </td>
                <td>
                  {{ russianDate(p.pDate) }}
                  <br />
                  {{ russianDate(p.validTo) }} ({{ isTransfer(p.fl) ? '-' : p.p }})
                </td>
                <td>
                  {{ p.vin ? p.vin : 'не указан' }}
                  <br />
                  {{ p.vinKdr ? p.vinKdr : 'не указан' }}
                </td>
                <td>
                  {{ p.model }}
                  <br />
                  {{ p.y }}
                </td>
                <td>
                  <button
                    class="btn btn-default"
                    type="button"
                    :disabled="cannotTransfer(p.fl)"
                    @click="
                      transfer(p);
                      $event.stopPropagation();
                    "
                  >
                    {{ describeStatus(p.fl) }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forceUpper, russianDate, freshPayment, isTransfer, freshClient } from '@/lib/funcs';
import { patterns, taReasons } from '@/lib/consts';
import ClientComponent from '@/controls/ClientComponent';
import PaymentSet from '@/components/payments/PaymentSet';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { mapGetters } from 'vuex';
import ConfirmDialogVue from '@/controls/ConfirmDialog.vue';
import { required, minValue, minLength, maxLength } from 'vuelidate/lib/validators';
import { regex, clientValidation, paymentsValidation } from '@/lib/validators';
import RomeDatepicker from '@/controls/RomeDatepicker';
import { getTariff } from '@/store/store';
export default {
  data() {
    return {
      permResults: null,
      patterns,
      taReasons,
      blockUI: false,
      initialFetchFailure: false,
      searchDescription: null,
      s: {
        pn: null,
        vin: null,
        cn: null,
        rn: null,
      },
      m: {
        client: freshClient(),
        id: null,
        refused: !1,
        aDate: moment().format('YYYY-MM-DD'),
        cDate: null,
        pn: '',
        cn: '',
        p: {
          payments: [freshPayment(getTariff(1100, new Date()).total)],
        },
        permId: null,
        reasonId: 2,
      },
    };
  },
  validations() {
    return {
      m: {
        cn: { required, regex: regex(patterns.cn) },
        pn: { regex: regex(patterns.pn) },
        reasonId: { required, minValue: minValue(1) },
        aDate: { required },
        client: clientValidation(),
        p: paymentsValidation(),
      },
      s: {
        pn: { required, regex: regex(patterns.pn) },
        vin: { required, maxLength: maxLength(22), minLength: minLength(1) },
        cn: { required, regex: regex(patterns.cn) },
        rn: { required, regex: regex(patterns.combinedRN) },
      },
    };
  },
  methods: {
    dateChanged() {
      let m = this.m;
      if (m.p.payments.length == 1 && m.aDate) {
        let tariff = getTariff(1100, m.aDate);
        m.p.payments[0].s = tariff.total;
      }
    },
    forceUpper,
    russianDate,
    isTransfer,
    search(type) {
      let vm = this;
      vm.blockUI = true;
      vm.searchDescription = '';
      vm.permResults = [];
      ajax
        .searchPerms4TA({ type, term: vm.s[type] })
        .then(
          r => {
            vm.permResults = r.data.content;
            if (vm.permResults.length == 0) vm.searchDescription = r.data.message;
          },
          err => {
            vm.searchDescription = 'Ошибка поиска';
            vm.$showError('Ошибка поиска', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    cannotTransfer(fl) {
      return (fl & (128 | 512 | 1024)) != 0;
    },
    describeStatus(fl) {
      if ((fl & 128) !== 0) {
        return 'Уже перенесено';
      }
      if ((fl & 512) !== 0) {
        return 'Аннулировано';
      }
      if ((fl & 1024) !== 0) {
        return 'Недействительно';
      }
      return 'Перенести';
    },
    transfer(p) {
      let vm = this;
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно перенести разрешение № ${p.num}?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            vm.m.pn = p.num;
            vm.save(function() {
              vm.$router.push({
                name: 'editPerm',
                params: { guid: p.guid, transferType: 1, cn: vm.m.cn, applId: vm.m.id },
              });
            });
          }
        },
      });
    },
    addPayment() {
      let m = this.m;
      let total = m.p.payments.length == 0 ? getTariff(1100, m.aDate).total : 0;
      m.p.payments.push(freshPayment(total));
    },
    register() {
      let vm = this;
      if (!_isEqual(vm.m, vm.m_)) {
        vm.save();
      }
    },
    save(cba) {
      let vm = this;
      vm.blockUI = true;
      vm.$dlg.closeAll(null, 'toast');
      ajax
        .saveTA(vm.m)
        .then(
          r => {
            vm.$dlg.toast('Сохранено', { messageType: 'success', closeTime: 3 });
            vm.m.id = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
            if (vm.m.refused) {
              vm.m.cDate = new Date();
            }
            if (cba) {
              cba();
            }
          },
          err => {
            this.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    let vm = this;
    vm.m_ = _cloneDeep(vm.m);
    let id = vm.$route.params.id;
    if (id) {
      vm.blockUI = true;
      ajax
        .getTA({ id })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.initialFetchFailure = 1;
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (_isEqual(this.m, this.m_)) {
      next();
    } else {
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: 'У вас есть несохраненные изменения.\nДействительно хотите выйти?',
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Внимание',
        callback: b => {
          if (b === 2) next();
          else next(false);
        },
      });
    }
  },
  components: {
    ClientComponent,
    VueSingleSelect,
    PaymentSet,
    RomeDatepicker,
  },
  computed: {
    ...mapGetters(['debugMode']),
  },
};
</script>
