// import OwnTAs from '@/components/transferApplications/OwnTAs';
// import SearchTAs from '@/components/transferApplications/SearchTAs';
import OthersPerms from '@/components/perms/OthersPerms';
import PermissionList from '@/components/perms/PermissionList';
import PermissionBlanksList from '@/components/perms/PermissionBlanksList';
import PermPage from '@/components/perms/PermPage';
import OwnTas2 from '@/components/transferApplications/OwnTas2';
import OwnTas3 from '@/components/transferApplications/OwnTas3';
import SearchTas2 from '@/components/transferApplications/SearchTas2';
import SearchTas3 from '@/components/transferApplications/SearchTas3';
import EditPermission from '@/components/perms/edit/EditPermission';
import PassPerms from '@/components/perms/PassPerms';

export default [
  {
    path: '/perms',
    component: PermPage,
    children: [
      {
        path: 'blanks',
        component: PermissionBlanksList,
        name: 'permBlanks',
        meta: { title: 'Бланки разрешений', },
      },
      {
        path: 'certs',
        component: PermissionList,
        name: 'permCerts',
        meta: { title: 'Разрешения', },
      },
      {
        path: 'ownTas2',
        component: OwnTas2,
        name: 'ownTas2',
        meta: { title: 'Свои заявления на перенос', },
      },
      {
        path: 'ownTas3',
        component: OwnTas3,
        name: 'ownTas3',
        meta: { title: 'Свои заявления на внесение изменений информации о выданном разрешении', },
      },
      {
        path: 'searchTas2',
        component: SearchTas2,
        name: 'searchTas2',
        meta: { title: 'Поиск заявлений на перенос', },
      },
      {
        path: 'searchTas3',
        component: SearchTas3,
        name: 'searchTas3',
        meta: { title: 'Поиск заявлений на внесение изменений информации о выданном разрешении', },
      },
      {
        path: 'others',
        component: OthersPerms,
        meta: { title: 'Чужие разрешения', },
      },
    ],
  },
  {
    path: '/editPerm/:guid?',
    component: EditPermission,
    name: 'editPerm',
    hidden: true,
    meta: { title: 'Редактирование разрешения (переноса)', },
  },
  {
    path: '/passPerms',
    component: PassPerms,
    hidden: true,
    meta: { title: '"Передать" разрешения', },
  },
];
