<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      {{ local.actionNumber ? 'Продление' : 'Выдача' }}
      <button
        :disabled="disabled || local.actionNumber == 0"
        v-show="local.actionNumber != 0"
        class="btn btn-warning"
        type="button"
        @click="$emit('del')"
        style="padding-top: 0; padding-bottom: 0;"
      >
        Удалить
      </button>
      <button
        :disabled="disabled"
        v-show="local.actionNumber != 0 && printCallback"
        class="btn btn-success"
        type="button"
        @click="printCallback(local)"
        style="padding-top: 0; padding-bottom: 0;"
      >
        Печать
      </button>
    </div>
    <div class="panel-body">
      <!-- Место выдачи/продления -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ [errorClass]: v.dsId.$invalid }">Место</label>
        <div class="col-sm-7 input-group">
          <vue-single-select v-model="local.dsId" :options="dsList" :disabled="disabled" />
        </div>
      </div>

      <!-- Место выдачи/продления (строкой) -->
      <div class="form-group" v-if="showPlace">
        <label class="control-label col-sm-3" :class="{ [errorClass]: v.place.$invalid }">Место (для печати)</label>
        <div class="col-sm-7 input-group">
          <input
            type="text"
            :value="local.place"
            class="form-control"
            :disabled="disabled"
            @input="update('place', $event.target.value)"
          />
        </div>
      </div>

      <!-- Дата выдачи/продления -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ [errorClass]: v.date.$invalid }">Дата</label>
        <div class="col-sm-7 input-group">
          <rome-datepicker
            :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
            modelFormat="YYYY-MM-DD"
            :value="local.date"
            class="form-control"
            @input="update('date', $event)"
            placeholder="Дата"
            :disabled="disabled"
            :class="{ [errorClass]: v.date.$invalid }"
          />
        </div>
      </div>

      <!-- Срок действия -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ [errorClass]: v.validTo.$invalid }">Срок действия</label>
        <div class="col-sm-7 input-group">
          <rome-datepicker
            :options="{ time: false, inputFormat: validToInputFormat }"
            modelFormat="YYYY-MM-DD"
            :value="local.validTo"
            class="form-control"
            @input="update('validTo', $event)"
            placeholder="Дата"
            :disabled="disabled"
            :class="{ [errorClass]: v.validTo.$invalid }"
          />
        </div>
      </div>

      <!-- Инженер -->
      <div class="form-group">
        <label class="control-label col-sm-3">Инженер</label>
        <div class="col-sm-7 input-group">
          <input type="text" disabled :value="local.userName" class="form-control" />
        </div>
      </div>

      <!-- Акт -->
      <div class="form-group">
        <label class="control-label col-sm-3">Акт</label>
        <div class="col-sm-7 input-group">
          <input type="text" disabled :value="local.actNum" class="form-control" />
          <span class="input-group-btn">
            <button
              :disabled="disabled"
              class="btn btn-default"
              type="button"
              title="Выбрать акт"
              @click="$emit('actAction', 0)"
            >
              <span class="fa fa-ellipsis-h" />
            </button>
            <button
              :disabled="disabled"
              class="btn btn-default"
              type="button"
              title="Редактировать акт"
              @click="$emit('actAction', 1)"
            >
              <span class="fa fa-pencil" />
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-sm-2 no-pad">
      <input type="text" disabled :value="actionType" class="form-control" />
    </div>
    <div class="col-sm-2 no-pad">
      <rome-datepicker
        :options="{time:false,inputFormat: 'DD.MM.YYYY'}"
        modelFormat="YYYY-MM-DD"
        :value="local.date"
        class="form-control"
        @input="update('date', $event)"
        placeholder="Дата"
        :disabled="disabled"
        :class="{[errorClass]:v.date.$invalid}"
      />
    </div>
    <div class="col-sm-2 no-pad">
      <rome-datepicker
        :options="{time:false,inputFormat: validToInputFormat}"
        modelFormat="YYYY-MM-DD"
        :value="local.validTo"
        class="form-control"
        @input="update('validTo', $event)"
        placeholder="Дата"
        :disabled="disabled"
        :class="{[errorClass]:v.validTo.$invalid}"
      />
    </div>
    <div class="col-sm-3 no-pad">
      <input type="text" disabled :value="local.userName" class="form-control" />
    </div>
    <div class="col-sm-3 no-pad">
      <div class="input-group">
        <input type="text" disabled :value="local.actNum" class="form-control" />
        <span class="input-group-btn">
          <button
            :disabled="disabled"
            class="btn btn-default"
            type="button"
            title="Выбрать акт"
            @click="$emit('actAction',0)"
          >
            <span class="fa fa-ellipsis-v" />
          </button>
          <button
            :disabled="disabled"
            class="btn btn-default"
            type="button"
            title="Редактировать акт"
            @click="$emit('actAction', 1)"
          >
            <span class="fa fa-pencil" />
          </button>
          <button
            :disabled="disabled || local.actionNumber == 0"
            class="btn btn-default"
            type="button"
            title="удалить"
            @click="$emit('del')"
          >
            <span class="fa fa-trash"></span>
          </button>
        </span>
      </div>
    </div>
  </div>-->
</template>

<script>
import RomeDatepicker from '@/controls/RomeDatepicker';
import { freshCertificateAction } from '@/lib/funcs';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
export default {
  data() {
    return {
      actionNumbers: [{ id: 0, name: 'Выдача' }, { id: 1, name: 'Продление' }],
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
    },
    errorClass: {
      type: String,
      default: 'has-error',
    },
    validToInputFormat: {
      type: String,
      required: true,
    },
    dsList: {
      type: Array,
      required: true,
    },
    showPlace: {
      type: Boolean,
      required: true,
    },
    /**
     * Callback для печати продления. Если null или undefined, кнопка печати будет скрыта.
     * получает на входе объект CertificateAction (см. CertificateAction.vue)
     */
    printCallback: {
      type: Function,
      default: undefined,
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    },
  },
  computed: {
    local() {
      return this.value ? this.value : freshCertificateAction();
    },
  },
  components: { RomeDatepicker, VueSingleSelect },
};
</script>
