import { required, between, helpers, requiredIf, minLength, minValue, integer } from 'vuelidate/lib/validators';
import { resolveUnpInfo } from '@/api/api';
import { isUnpValid } from '@/lib/funcs';

// export const contains = (param) => (value) => !helpers.req(value) || value.indexOf(param) >= 0

export const regex = pattern => value => {
  return !helpers.req(value) || (typeof value === 'string' && !!value.match(pattern));
};

/**
 * Проверяет, что значение начинается (или наоборот, НЕ начинается) с указанной строки.
 * @param {String} prefix префикс
 * @param {Boolean} negate инвертировать результат (проверять, что значение НЕ начинается с префикса)
 */
export const startsWith = prefix => (value, negate = false) => {
  if (!helpers.req(value)) {
    return true;
  }
  if (typeof value !== 'string') {
    return false;
  }
  if (negate) {
    return !value.startsWith(prefix);
  } else {
    return value.startsWith(prefix);
  }
};

/**
 * Проверяет значение на вхождение строки
 * @param {String} substring искомая строка
 * @param {Boolean} negate инвертировать результат (проверять, что значение НЕ начинается с префикса)
 */
export const includes = substring => (value, negate = false) => {
  if (!helpers.req(value)) {
    return true;
  }
  if (typeof value !== 'string') {
    return false;
  }
  if (negate) {
    return !value.includes(substring);
  } else {
    return value.includes(substring);
  }
};

/**
 * проверка, что у значение тип boolean
 */
export const validBoolean = () => value => {
  return !helpers.req(value) || typeof value === 'boolean';
};

/**
 * Проверяет, чтобы поля объекта _1 и _2 не были истинными одновременно
 * @param {Object} value объект
 */
export const singleTrueBooleanValidator = value => !(value._1 && value._2);

/**
 * Проверяет, чтобы в объекте было хотя бы одно истинное значение
 * @param {Object} value объект
 */
export const atLeastOneTrueValidator = v => !!Object.values(v).find(e => e);

/**
 * Валидатор, всегда возвращающий истину
 */
export const trueValidator = () => true;

const unpValidator = (value, ctx) => ctx.t == 0 || isUnpValid(value);

export const payIdValidator = (value, ctx) => {
  switch (ctx.t) {
    case 3:
      return value > 1000000000;
    case 5:
    case 7:
      return value > 1000;
    default:
      return true;
  }
};

const clientNameValidator = value => {
  if (typeof value !== 'string') {
    return false;
  }
  let n = value.trim();
  return !(n.startsWith('⚠') || n.startsWith('!'));
};

/**
 * Создаёт валидатор vuelidate для списка действий с сертификатом.
 * @param {Boolean} requrePlace требовать или нет ввода места осуществления действия
 * @returns валидатор
 */
export const actionsValidation = function(requrePlace = false) {
  return {
    $each: {
      actionNumber: { required, integer },
      dsId: { required, between: between(100000000, 999999999) },
      place: requrePlace ? { required, ml: minLength(3) } : {},
      date: { required },
      validTo: { required },
    },
  };
};

export const clientValidation = function(ignoreBranches) {
  return {
    t: { required, between: between(0, 2) },
    n: { required, minLength: minLength(4), val: clientNameValidator },
    u: {
      reqIf: requiredIf(function(m) {
        return m.t != 0;
      }),
      unp: unpValidator,
    },
    b: {
      vv: function(value, obj) {
        let unpValid = isUnpValid(obj.u);
        if (ignoreBranches || !unpValid || obj.t < 1 || obj.t > 2) return true;
        return resolveUnpInfo(obj.u).then(({ branches }) => {
          let result = !branches || !branches.length || branches.find(x => x.b == value);
          return result;
        });
      },
    },
  };
};

export const paymentsValidation = function() {
  return {
    payments: {
      $each: {
        payId: {
          required: requiredIf(function(m) {
            return m.t == 3 || m.t == 5 || m.t == 7;
          }),
          payId: payIdValidator,
        },
        docNum: {
          required: requiredIf(function(m) {
            return m.t == 2 || m.t == 6;
          }),
        },
        s: { required, min: minValue(0.01) },
        docDate: {
          required: requiredIf(function(m) {
            return m.t == 2 || m.t == 4 || m.t == 6;
          }),
        },
      },
    },
  };
};

export const tabPartValidation = function() {
  return {
    required,
    ml: minLength(1),
    $each: {
      count: { required, integer, bw: between(1, 2) },
      price: { required, mn: minValue(0.01) },
      sumWithoutVAT: { required, mn: minValue(0.01) },
      vat: { required, mn: minValue(0) },
      total: { required, mn: minValue(0.01) },
    },
  };
};
