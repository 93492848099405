<template>
  <modal-wrapper @close="close" :width="width">
    <template slot="header">
      <h4>Поиск платежа Webpay</h4>
    </template>
    <div class="form-group">
      <label class="control-label col-sm-6" title="Можно вводить техпаспорт, номер заказа или номер транзакции" style="cursor: help;">
        <span :class="{ 'has-error': $v.m.searchTerm.$invalid }">Критерий поиска: </span
        ><i class="fa fa-question-circle" /></label>
      <div class="col-sm-5 input-group">
        <input
          type="text"
          class="form-control"
          v-model="m.searchTerm"
          @keyup.enter="search"
          @input="forceUpper($event, m, 'searchTerm')"
          ref="searchField"
        />
        <span class="input-group-btn">
          <button type="button" class="btn btn-default" @click.prevent.stop="search" :disabled="$v.m.searchTerm.$invalid">
            <span class="fa fa-search" />
          </button>
        </span>
      </div>
    </div>
    <table class="table table-hover table-mc-light-blue table-striped" v-show="!blockUI">
      <thead>
        <tr>
          <td>Сумма<br />Дата</td>
          <td>№ транзакции<br />№ заказа</td>
          <td>Техпаспорт<br />Услуга</td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in pays" :key="i" :style="p.st != 2 && { 'text-decoration': 'line-through' }">
          <td>{{ p.sum }}<br />{{ p.date | msToDate }}</td>
          <td>{{ p.id }}<br />{{ p.orderNumber }}</td>
          <td>{{ p.certNumber }}<br />{{ seName(p.serviceId) }}</td>
          <td>
            <button v-if="showInsertButton && useAllowed(p)" @click="apply(p)" :disabled="!useAllowed(p)" class="btn btn-success" style="padding: 2px 5px;">
              Вставить
            </button>
            <span v-else>{{ describeRefuseReason(p) }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-info" v-show="blockUI">Загружаю...</div>
    <template slot="footer">
      <button class="btn btn-warning" type="button" @click="close">Отмена</button>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/controls/ModalWrapper';
import { eripServiceNames, payStateNames } from '@/lib/consts';
import { forceUpper } from '@/lib/funcs';
import { required, minLength } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      blockUI: false,
      m: {
        searchTerm: this.initialSearchTerm,
      },
      pays: [],
    };
  },
  validations: () => ({ m: { searchTerm: { required, ml: minLength(3) } } }),
  props: {
    width: { type: String },
    initialSearchTerm: { type: String },
    payload: { default: () => {} },
    allowedServices: { type: Array },
    showInsertButton: { type: Boolean, default: () => true },
  },
  components: { ModalWrapper },
  methods: {
    forceUpper,
    useAllowed(p) {
      return p.st === 2 && (this.allowedServices == null || this.allowedServices.includes(p.serviceId));
    },
    describeRefuseReason(p) {
      if (this.allowedServices && !this.allowedServices.includes(p.serviceId)) {
        return 'Другая услуга';
      }
      return p.st !== 2 ? payStateNames[p.st] : '';
    },
    seName: id => eripServiceNames[id],
    close() {
      this.$emit('close');
    },
    apply(p) {
      this.$emit('apply', { foundPayment: p, payload: this.payload, payType: 7 });
    },
    search() {
      let vm = this;
      let searchTerm = this.m.searchTerm.trim();
      if (!searchTerm) {
        alert('Неверный критерий поиска');
        return;
      }
      vm.blockUI = true;
      ajax
        .searchWebpayPayments({ searchTerm })
        .then(
          r => {
            vm.pays = r.data.content;
          },
          err => {
            vm.pays = [];
            this.$showError('Ошибка', err);
          }
        )
        .finally(() => (vm.blockUI = false));
    },
  },
  mounted() {
    this.$refs.searchField.focus();
    if (this.m.searchTerm) this.search();
  },
};
</script>
