<template>
  <div class="container">
    <div class="fixed-header">
      <span class="fixed-header-title">ОГ {{ m.number ? m.number : '' }} ({{ editMode }})</span>
      <span class="fa-pull-right">
        <button
          class="btn btn-warning icon-button"
          type="button"
          @click="fillDummyData"
          :disabled="initialErr"
          v-if="debugMode"
        >
          <i class="fa fa-bug fa-2x"></i>
        </button>
        <button
          :disabled="initialErr || $v.m.$invalid"
          class="btn btn-success icon-button"
          @click="print"
          type="button"
          title="Печать"
        >
          <i class="fa fa-print fa-2x"></i>
        </button>
        <button
          :disabled="initialErr || $v.m.$invalid"
          class="btn btn-success icon-button"
          @click="save"
          type="button"
          title="Сохранить"
        >
          <i class="fa fa-save fa-2x"></i>
        </button>
        <button class="btn btn-danger icon-button" @click="$router.go(-1)" type="button" title="Отмена">
          <i class="fa fa-window-close fa-2x"></i>
        </button>
      </span>
    </div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <select-og-card v-if="showSelectOgCard" @close="showSelectOgCard = false" width="800" @apply="applyCard" />
    <div v-show="!initialErr" class="under-fixed-header">
      <ul class="nav nav-tabs">
        <li :class="{ active: tabIndex == 0 }" @click="tabIndex = 0">
          <a>Сертификат ОГ</a>
        </li>
        <li :class="{ active: tabIndex == 1 }" @click="tabIndex = 1">
          <a>Регистрационные действия</a>
        </li>
      </ul>

      <div v-show="tabIndex == 0">
        <!-- Первая вкладка - данные сертификата -->

        <h3 class="text-center" style="text-decoration: underline;">Сведения о ТС</h3>
        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3 input-group">
            <button type="button" class="btn btn-default" @click="showSelectOgCard = true" :disabled="editingDisabled">
              <span class="fa fa-search" /> Заполнить из карты доп. проверки
            </button>
          </div>
        </div>
        <!-- 2. Изготовитель ТС -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.v.producer.$invalid }" class="control-label col-sm-3"
            >2. Изготовитель ТС</label
          >
          <div class="col-sm-9 input-group">
            <input v-model="m.v.producer" class="form-control" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- 3. VIN -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.v.vin.$invalid }" class="control-label col-sm-3">3. VIN</label>
          <div class="col-sm-9 input-group">
            <input
              v-model="m.v.vin"
              class="form-control"
              @input="forceUpper($event, m.v, 'vin')"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 4. Госномер ТС -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.v.rn.$invalid }" class="control-label col-sm-3">4. Госномер</label>
          <div class="col-sm-9 input-group">
            <input
              v-model="m.v.rn"
              class="form-control"
              @input="forceUpper($event, m.v, 'rn')"
              type="text"
              autocomplete="off"
              :disabled="editingDisabled"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="selectOgFromOld" :disabled="$v.m.v.rn.$invalid">
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>

        <!-- Год выпуска ТС-->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.v.y.$invalid }" class="control-label col-sm-3">Год выпуска ТС</label>
          <div class="col-sm-9 input-group">
            <input v-model.number="m.v.y" class="form-control" type="number" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- 5. Перевозчик: -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.ownerName.$invalid }">5. Перевозчик</label>
          <div class="col-sm-9 input-group">
            <input v-model="m.ownerName" class="form-control" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- Описание ТС -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.v.description.$invalid }" class="control-label col-sm-3"
            >6. Описание ТС</label
          >
          <div class="col-sm-9 input-group">
            <select class="form-control" v-model="m.v.description" :disabled="editingDisabled">
              <option
                v-for="c in cats.filter(
                  x => x.v && !['M2', 'M3', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7'].includes(x.name)
                )"
                :key="c.id"
                :value="c.name"
                >{{ c.name }}</option
              >
            </select>
          </div>
        </div>

        <!-- Обозначение ТС согласно разделу 9.1.1.2 (оно же Тип по Европейскому соглашению ДОПОГ) -->
        <!-- <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.v.des.$invalid }"
            >7. Обозначения по 9.1.1.2 ДОПОГ</label
          >
          <div class="col-sm-9 input-group">
            <select id="designations" class="form-control" v-model="m.v.des" :disabled="editingDisabled">
              <option v-for="d in designations" :key="d.c" :value="d.c">{{ d.n }}</option>
            </select>
          </div>
        </div> -->

        <div class="form-group row">
          <label class="control-label col-sm-3"
            >7. Обозначения по 9.1.1.2 ДОПОГ</label
          >
          <div class="icheck-primary icheck-inlined" v-for="des in designations" :key="des.c">
            <input :id="des.n" :checked="!!(m.v.des & des.c)" @change="m.v.des = m.v.des ^ des.c" type="checkbox" :disabled="editingDisabled" />
            <label :for="des.n">{{ des.n }}</label>
          </div>
          <!-- <div class="icheck-primary icheck-inlined">
            <input id="EXII" v-model="m.v.designations.EXII" type="checkbox" :disabled="editingDisabled" />
            <label for="EXII">EX/II</label>
          </div>
          <div class="icheck-primary icheck-inlined">
            <input id="EXIII" v-model="m.v.designations.EXIII" type="checkbox" :disabled="editingDisabled" />
            <label for="EXIII">EX/III</label>
          </div>
          <div class="icheck-primary icheck-inlined">
            <input id="FL" v-model="m.v.designations.FL" type="checkbox" :disabled="editingDisabled" />
            <label for="FL">FL</label>
          </div>
          <div class="icheck-primary icheck-inlined">
            <input id="OX" v-model="m.v.designations.OX" type="checkbox" :disabled="editingDisabled" />
            <label for="OX">OX</label>
          </div>
          <div class="icheck-primary icheck-inlined">
            <input id="AT" v-model="m.v.designations.AT" type="checkbox" :disabled="editingDisabled" />
            <label for="AT">AT</label>
          </div>
          <div class="icheck-primary icheck-inlined">
            <input id="MEMU" v-model="m.v.designations.MEMU" type="checkbox" :disabled="editingDisabled" />
            <label for="MEMU">MEMU</label>
          </div> -->
        </div>

        <!-- Износостойкая тормозная система -->
        <div class="form-group row">
          <div class="col-sm-3" />
          <div class="icheck-primary col-sm-9">
            <input id="enduranceBS" v-model="m.v.enduranceBS" type="checkbox" :disabled="editingDisabled" />
            <label for="enduranceBS">8. Износостойкая тормозная система</label>
          </div>
        </div>

        <h3 class="text-center" style="text-decoration: underline;">9. Описание встроенной цистерны</h3>
        <!-- 9.1. Изготовитель цистерны -->
        <div class="form-group row">
          <label class="control-label col-sm-3">9.1. Изготовитель цистерны</label>
          <div class="col-sm-9 input-group">
            <input
              :value="m.t.producer"
              @input="setNullIfBlank($event, m.t, 'producer')"
              class="form-control"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 9.2. Номер утверждения -->
        <div class="form-group row">
          <label class="control-label col-sm-3">9.2. Номер утверждения</label>
          <div class="col-sm-9 input-group">
            <input
              :value="m.t.approvalNum"
              @input="setNullIfBlank($event, m.t, 'approvalNum')"
              class="form-control"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 9.3. Серийный номер цистерны -->
        <div class="form-group row">
          <label class="control-label col-sm-3">9.3. Серийный номер</label>
          <div class="col-sm-9 input-group">
            <input
              :value="m.t.serial"
              @input="setNullIfBlank($event, m.t, 'serial')"
              class="form-control"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 9.4. Год выпуска цистерны -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.t.y.$invalid }" class="control-label col-sm-3">9.4. Год выпуска</label>
          <div class="col-sm-9 input-group">
            <input v-model.number="m.t.y" type="number" class="form-control" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- 9.5. Код цистерны -->
        <div class="form-group row">
          <label class="control-label col-sm-3">9.5. Код цистерны</label>
          <div class="col-sm-9 input-group">
            <input
              :value="m.t.code"
              @input="setNullIfBlank($event, m.t, 'code')"
              class="form-control"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 9.6. Специальные предписания -->
        <div class="form-group row">
          <label class="control-label col-sm-3">9.6. Специальные предписания</label>
          <div class="col-sm-9 input-group">
            <input
              :value="m.t.specialProvisions"
              @input="setNullIfBlank($event, m.t, 'specialProvisions')"
              class="form-control"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <h3
          class="text-center"
          style="text-decoration: underline;"
          :class="{ 'has-error': $v.m.p10_1.$invalid || $v.m.p10_2.$invalid }"
        >
          10. Опасные грузы, разрешенные к перевозке
        </h3>
        <div class="form-group row">
          <div class="col-sm-3" />
          <div class="icheck-primary col-sm-9">
            <input
              id="p10_1_1"
              v-model="m.p10_1._1"
              type="checkbox"
              :disabled="editingDisabled"
              @click="p10_1_1clicked"
            />
            <label for="p10_1_1">Класс 1, включая группу совместимости J</label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-3" />
          <div class="icheck-primary col-sm-9">
            <input
              id="p10_1_2"
              v-model="m.p10_1._2"
              type="checkbox"
              :disabled="editingDisabled"
              @click="p10_1_2clicked"
            />
            <label for="p10_1_2">Класс 1, исключая группу совместимости J</label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-3" />
          <div class="icheck-primary col-sm-9">
            <input
              id="p10_2_1"
              v-model="m.p10_2._1"
              type="checkbox"
              :disabled="editingDisabled"
              @click="p10_2_1clicked"
            />
            <label for="p10_2_1"
              >могут перевозиться только вещества, разрешенные согласно коду цистерны и любым специальным предписаниям,
              указанным в графе 9</label
            >
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-3" />
          <div class="icheck-primary col-sm-9">
            <input
              id="p10_2_2"
              v-model="m.p10_2._2"
              type="checkbox"
              :disabled="editingDisabled"
              @click="p10_2_2clicked"
            />
            <label for="p10_2_2">Могут перевозиться только следующие вещества:</label>
          </div>
        </div>

        <!-- Список веществ, разрешенных к перевозке -->
        <div class="form-group row" v-show="m.p10_2._2">
          <label :class="{ 'has-error': $v.m.permittedSubstances.$invalid }" class="control-label col-sm-3"
            >Список веществ, разрешенных к перевозке</label
          >
          <div class="col-sm-9 input-group">
            <input
              v-model="m.permittedSubstances"
              class="form-control"
              type="text"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- 11. Замечания -->
        <div class="form-group row">
          <label class="control-label col-sm-3">11. Замечания</label>
          <div class="col-sm-9 input-group">
            <textarea rows="3" v-model="m.remarks" class="form-control" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- дата проверки -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.actions.$each[0].date.$invalid }" class="control-label col-sm-3"
            >Дата проверки</label
          >
          <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.actions[0].date"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              class="form-control"
              modelFormat="YYYY-MM-DD"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- срок действия -->
        <div class="form-group row">
          <label :class="{ 'has-error': $v.m.actions.$each[0].validTo.$invalid }" class="control-label col-sm-3"
            >Срок действия</label
          >
          <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.actions[0].validTo"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              class="form-control"
              modelFormat="YYYY-MM-DD"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- выбор ДС -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.actions.$each[0].dsId.$invalid }"
            >Место проверки</label
          >
          <div class="col-sm-9 input-group">
            <vue-single-select name="dsId" v-model="m.actions[0].dsId" :options="ds" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- Место (строкой, для печати) -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.actions.$each[0].place.$invalid }"
            >Место (для печати)</label
          >
          <div class="col-sm-9 input-group">
            <input
              v-model="m.actions[0].place"
              class="form-control"
              type="text"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>
      </div>

      <div v-show="tabIndex == 1">
        <!-- Вторая вкладка - регистрационные действия -->
        <certificate-actions
          :userInfo="userInfo"
          v-model="m.actions"
          :v="$v.m.actions"
          :validToInputFormat="'DD.MM.YYYY'"
          @actAction="actAction"
          :printCallback="printProlong"
          :certType="4"
        />
        <!-- Конец второй вкладки -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  numberPad,
  forceUpper,
  freshCertificateAction,
  randomArrayItem,
  randomChars,
  randomFromRange,
  editingDisabled,
  setNullIfBlank,
} from '@/lib/funcs';
import { mapGetters } from 'vuex';
import { required, between, numeric, requiredIf } from 'vuelidate/lib/validators';
import { regex, actionsValidation, singleTrueBooleanValidator } from '@/lib/validators';
import { designations, patterns } from '@/lib/consts';
import RomeDatepicker from '@/controls/RomeDatepicker';
import CertificateActions from '@/controls/CertificateActions';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import SelectOgCard from '../SelectOgCard';
import SelectAct from '@/components/docs/SelectAct';
import EditAct from '@/components/docs/EditAct';
import SelectExistingOg from '@/components/og/SelectExistingOg';
import { modifiable } from '@/lib/mixins';
import _isObject from 'lodash/isObject';
import _isNumber from 'lodash/isNumber';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      editMode: 'новый',
      initialErr: false,
      blockUI: false,
      tabIndex: 0,
      showSelectOgCard: false,
      m: {
        blankId: null,
        ownerName: '',
        p10_1: { _1: false, _2: false },
        p10_2: { _1: false, _2: false },
        permittedSubstances: null,
        remarks: null,
        blankType: 4,

        number: null,
        id: null,
        v: {
          rn: '',
          producer: '',
          vin: '',
          description: '',
          y: null,
          des: null,
          enduranceBS: false,
        },
        t: {
          producer: null,
          approvalNum: null,
          serial: null,
          y: null,
          code: null,
          specialProvisions: null,
        },
        actions: [
          {
            actionType: 0,
            date: moment().format('YYYY-MM-DD'),
            validTo: moment()
              .add(1, 'y')
              .add(-1, 'd')
              .format('YYYY-MM-DD'),
            actId: null,
            userName: null,
            actNum: null,
          },
        ],
      },
    };
  },
  validations() {
    return {
      m: {
        blankId: { numeric, required },
        ownerName: { required },
        p10_1: { singleTrueBooleanValidator },
        p10_2: { singleTrueBooleanValidator },
        permittedSubstances: { required: requiredIf(nm => nm.p10_2._2) },

        v: {
          rn: { required, regex: regex(patterns.combinedRN) },
          producer: { required },
          vin: { regex: regex(patterns.vinLooseOrEmpty) },
          description: { required },
          des: { required, numeric, between: between(1, 63) },
          y: { between: between(1940, 2090) },
        },
        t: {
          // producer: { required },
          // approvalNum: { required },
          // serial: { required },
          // code: { required },
          y: { between: between(1940, 2090) },
        },
        actions: actionsValidation(true),
      },
    };
  },
  components: { CertificateActions, VueSingleSelect, RomeDatepicker, SelectOgCard },
  computed: {
    ...mapGetters([
      'debugMode',
      'ds',
      'userSettings',
      'cats',
      'userInfo',
      // 'gmodels'
    ]),
    editingDisabled() {
      return editingDisabled(this.userInfo, this.m.actions[0]);
    },
    designations() {
      return this.m.blankType == 5 ? designations.filter(x => x.n != 'OX') : designations;
    },
  },
  methods: {
    applyCard(card) {
      this.showSelectOgCard = false;
      // карта не выбрана или её результат - неисправно:
      if (!card) return;
      if (card.p & (0b01000000000000000000000000000000 == 0)) {
        this.$showError(null, { errCode: 1, message: 'Выбрана карта с результатом "Не соответствует"' });
        return;
      }
      let m = this.m;
      m.v.rn = card.rn;
      m.v.y = card.vY;
      m.v.producer = card.m;
      m.v.vin = card.vin || '';
      // m.v.des = this.designations.some(el => el.c == card.des) ? (m.v.des = card.des) : null;
      m.v.des = card.des;
      m.t.code = card.tCod;
      m.t.y = card.tY;
      m.t.serial = card.tSer;
    },
    setNullIfBlank,
    p10_1_1clicked() {
      // if (this.m.p10_1[0]) this.m.p10_1[1] = false;
    },
    p10_1_2clicked() {
      // if (this.m.p10_1[0]) this.m.p10_1[1] = false;
    },
    p10_2_1clicked() {
      // if (this.m.p10_1[0]) this.m.p10_1[1] = false;
    },
    p10_2_2clicked() {
      // if (this.m.p10_1[0]) this.m.p10_1[1] = false;
    },
    fillDummyData() {
      let vm = this;
      let m = this.m;
      m.v.rn = randomChars(2) + randomFromRange(1000, 9999) + '-' + randomFromRange(1, 7);
      m.v.producer = randomChars(10);
      m.v.vin = randomChars(17);
      m.v.y = randomFromRange(1990, 2018);
      m.ownerName = randomChars(30);
      m.v.description = randomArrayItem(this.cats).name;
      m.v.des = 43;
      m.v.enduranceBS = false;
      m.t.producer = randomChars(17);
      m.t.approvalNum = randomChars(10);
      m.t.serial = randomChars(10);
      m.t.y = randomFromRange(1990, 2018);
      m.t.code = randomChars(5);
      m.t.specialProvisions = randomChars(10);
      m.p10_1 = { _1: false, _2: true };
      m.p10_2 = { _1: false, _2: false };
      m.permittedSubstances = randomChars(10);
      m.remarks = randomChars(10);

      m.actions = [
        {
          ...freshCertificateAction(vm.userInfo, vm.userSettings),
          place: (this.userSettings || { ogPlace: 'test place' }).ogPlace,
        },
      ];
    },
    actAction({ op, index }) {
      let vm = this;
      if (index < 0 || index >= vm.m.actions.length) return;
      let action = vm.m.actions[index];
      let actionText = action.actionNumber === 0 ? 'выдаче' : 'продлению';
      vm.$dlg.modal(op === 0 ? SelectAct : EditAct, {
        params: {
          initialId: op === 0 ? action.actId : null,
          serviceId: 4,
        },
        width: 800,
        height: op === 0 ? 500 : 590,
        title: `Акт к ${actionText} сертификата ОГ ${vm.m.number}`,
        callback: function(rslt) {
          if (rslt) {
            action.actId = rslt.actId;
            action.actNum = rslt.actNum;
          }
        },
      });
    },
    forceUpper,
    selectOgFromOld() {
      let vm = this;
      vm.$dlg.modal(SelectExistingOg, {
        params: { rn: vm.m.regNumber, ogId: vm.m.ogId },
        width: 800,
        height: 400,
        title: 'Информация о ОГ',
        callback: function(rslt) {
          if (_isObject(rslt)) {
            vm.m = rslt;
          }
        },
      });
    },
    save() {
      let vm = this;
      vm.blockUI = true;
      sign(vm.m)
        .then(ajax.saveOg)
        .then(r => {
          vm.m = r.data.content;
          vm.m_ = _cloneDeep(vm.m);
          vm.editMode = 'редактирование';
        })
        .catch(err => {
          vm.$showError(null, err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    canPrint(title) {
      let vm = this;
      if (!(vm.m.id && _isEqual(vm.m, vm.m_))) {
        this.$dlg.alert(title, {
          messageType: 'warning',
        });
        return false;
      } else {
        return true;
      }
    },
    print() {
      let vm = this;
      if (!vm.canPrint('Можно печатать только сохраненные сертификаты')) return;
      window.open(
        `s/certs/ogPdf2/${vm.m.id}?topOffset=${vm.userSettings.topOffset}&leftOffset=${vm.userSettings.leftOffset}`,
        '_blank'
      );
    },
    printProlong(action) {
      let vm = this;
      if (!vm.canPrint('Можно печатать только сохраненные продления')) return;
      window.open(
        `s/certs/ogProlongPdf/${action.actionId}?topOffset=${vm.userSettings.topOffset}&leftOffset=${
          vm.userSettings.leftOffset
        }`,
        '_blank'
      );
    },
  },

  mounted() {
    let vm = this;
    let rp = vm.$route.params;

    if (rp.certId) {
      // редактирование существующего
      vm.blockUI = true;
      // eslint-disable-next-line
      ajax
        .getOg({ certId: rp.certId })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
            vm.editMode = 'редактирование';
            vm.$nextTick(() => console.log(vm.designations));
          },
          err => {
            vm.initialErr = true;
            vm.$showError(null, err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    } else if (_isObject(rp.bl) && _isNumber(rp.bl.id)) {
      let m = vm.m;
      m.blankId = rp.bl.id;
      m.blankType = rp.bl.t;
      m.number = numberPad(rp.bl.n, 7);
      m.actions = [
        {
          ...freshCertificateAction(vm.userInfo, vm.userSettings),
          place: (this.userSettings || { ogPlace: '' }).ogPlace,
        },
      ];
      vm.m_ = _cloneDeep(vm.m);
      vm.editMode = 'новый';
    } else {
      vm.m_ = _cloneDeep(vm.m);
      vm.$router.go(-1);
    }
  },
  mixins: [modifiable],
};
</script>
<style scoped>
.icheck-inlined {
  display: inline-block;
  margin-right: 20px !important;
}
a {
  cursor: pointer;
}
</style>
