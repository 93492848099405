<template>
  <div>
    <h3 class="text-center">Отчеты</h3>
    <form id="hiddenRptForm" action="s/rpt/reportXLS" method="GET" target="_blank" ref="hiddenRptForm">
      <input type="hidden" name="from" id="issuedFrom" ref="issuedFrom" />
      <input type="hidden" name="to" id="issuedTo" ref="issuedTo" />
      <input type="hidden" name="type" ref="reportType" />
      <!-- <input type="hidden" name="userId" value="177"/> -->
    </form>
    <form name="rptForm" class="form-horizontal">
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.rpt.$invalid }">Период</label>
        <div class="col-sm-3 no-pad">
          <rome-datepicker
            v-model="rpt.dateFrom"
            class="form-control"
            :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
            @input="dateFromChanged"
            modelFormat="YYYY-MM-DD"
          />
        </div>
        <div class="col-sm-3 no-right-pad">
          <rome-datepicker v-model="rpt.dateTo" class="form-control" :options="{ time: false, inputFormat: 'DD.MM.YYYY' }" modelFormat="YYYY-MM-DD" />
        </div>
      </div>
      <!-- тип отчета -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.reportType.$invalid }">Тип отчета</label>
        <div class="col-sm-6 input-group">
          <select id="reportType" class="form-control" v-model="reportType">
            <option v-for="d in reportDescriptions" :key="d.id" :value="d">{{ d.name }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3"></div>
        <button class="btn btn-success" type="button" @click="makeReport()" :disabled="$v.$invalid">Отчитаться</button>
      </div>

      <div v-if="readyReportType">
        <h3 class="text-center">{{ readyReportTitle }}</h3>
        <div v-if="readyReportType == 'ENGINEER_REVIEW_REPORT'">
          <table class="table table-hover table-mc-light-blue table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Количество</th>
                <th>Пошлина</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entry, i) in reportData" :key="i">
                <td>{{ entry.title }}</td>
                <td>{{ entry.cnt }}</td>
                <td>{{ entry.amount ? entry.amount : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table class="table table-hover table-mc-light-blue table-striped">
            <thead>
              <tr>
                <th>Договор</th>
                <th>Акт</th>
                <th>Сумма в акте</th>
                <th>Сертификаты</th>
                <th>Клиент</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in reportData" :key="e.actNum">
                <td>
                  {{ e.contractNum }}
                  <br />
                  {{ e.contractDate | date }}
                </td>
                <td>
                  {{ e.actNum }}
                  <br />
                  {{ e.actDate | date }}
                </td>
                <td>{{ e.actTotal }}</td>
                <td>{{ e.certificates }}</td>
                <td>
                  {{ e.unp }}
                  <br />
                  {{ e.clientName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="debugMode">
        <div>{{ rpt }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import { russianDate } from '@/lib/funcs';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import RomeDatepicker from '@/controls/RomeDatepicker';
export default {
  data() {
    return {
      rpt: {
        dateFrom: null,
        dateTo: null,
      },
      reportDescriptions: [
        { name: 'Реестр разрешений (Excel)', id: 'b_PERMISSION_REGISTRY_BY_ENGINEER' },
        { name: 'Отчет по списанию бланков (Excel)', id: 'b_USED_BLANKS_REPORT_BY_ENGINEER' },
        { name: 'Итог по разрешениям (экран)', id: 'ENGINEER_REVIEW_REPORT' },
        { name: 'Сводный МСТО (Excel)', id: 'b_MSTO_CONSOLIDATED' },
        { name: 'Сводный МСТО (Экран)', id: 'MSTO_CONSOLIDATED_BY_ENGINEER' },
        { name: 'Сводный ЕКМТ (Excel)', id: 'b_EKMT_CONSOLIDATED' },
        { name: 'Сводный ЕКМТ (Экран)', id: 'EKMT_CONSOLIDATED_BY_ENGINEER' },
        { name: 'Сводный ОГ (Excel)', id: 'b_OG_CONSOLIDATED' },
        { name: 'Сводный ОГ (Экран)', id: 'OG_CONSOLIDATED_BY_ENGINEER' },
      ],
      reportType: null,
      reportData: [],
      blockUI: false,
      readyReportType: null,
      readyReportTitle: '',
    };
  },
  components: { RomeDatepicker },
  computed: mapGetters(['debugMode']),
  validations() {
    return {
      rpt: {
        dateFrom: { required },
        dateTo: { required },
      },
      reportType: { required },
    };
  },
  methods: {
    makeReport() {
      let vm = this;
      if (vm.reportType.id[0] === 'b') {
        vm.$refs.issuedFrom.value = vm.rpt.dateFrom;
        vm.$refs.issuedTo.value = vm.rpt.dateTo;
        vm.$refs.reportType.value = vm.reportType.id.substring(2);
        vm.$refs.hiddenRptForm.submit();
      } else {
        vm.blockUI = true;
        vm.readyReportType = null;
        ajax
          .reportJSON({ from: vm.rpt.dateFrom, to: vm.rpt.dateTo, type: vm.reportType.id })
          .then(
            (r) => {
              vm.reportData = r.data.content;
              vm.readyReportTitle = vm.reportType.name + ' с ' + russianDate(vm.rpt.dateFrom) + ' по ' + russianDate(vm.rpt.dateTo);
              vm.readyReportType = vm.reportType.id;
            },
            (err) => {
              vm.$showError('error', err);
            }
          )
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
    dateFromChanged() {
      let vm = this;
      if (!vm.rpt.dateFrom) return;
      vm.rpt.dateTo = moment(vm.rpt.dateFrom)
        .add(1, 'M')
        .date(1)
        .add(-1, 'd')
        .format('YYYY-MM-DD');
    },
  },
  created() {
    this.rpt.dateFrom = moment()
      .add(-1, 'M')
      .date(1)
      .format('YYYY-MM-DD');
    this.dateFromChanged();
  },
};
</script>

<style></style>
