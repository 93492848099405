<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="pMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="askChangeBlank(child.data)">Подменить бланк</a></li>
        <li v-show="isUserInRole('admin')"><a @click.prevent="del(child.data, child.data.i)">Удалить</a></li>
      </template>
    </vue-context>
    <form name="fSearchPerm" class="form-inline" onsubmit="return false;">
      <div class="form-group">
        <label for="pn" :class="{ 'has-error': $v.m.pn.$invalid }">Разрешение&nbsp;&nbsp;</label>
        <div class="input-group">
          <input type="text" class="form-control" id="pn" name="pn" ref="pn" v-model="m.pn" @input="forceUpper($event, m, 'pn')" />
          <span class="input-group-btn">
            <button class="btn btn-default" :disabled="$v.m.pn.$invalid" @click="searchByPerm">
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="cn" :class="{ 'has-error': $v.m.cn.$invalid }">&nbsp;&nbsp;Техпаспорт&nbsp;&nbsp;</label>
        <div class="input-group">
          <input type="text" class="form-control" id="cn" name="cn" ref="cn" v-model="m.cn" @input="forceUpper($event, m, 'cn')" />
          <span class="input-group-btn">
            <button class="btn btn-default" :disabled="$v.m.cn.$invalid" @click="searchByCn">
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
    </form>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <td></td>
          <td>№ разрешения</td>
          <td>Дата</td>
          <td>Инженер</td>
          <td>Собственник</td>
          <td>Госномер</td>
          <td>Срок</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in m.certs" :key="i" @dblclick="edit(p)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click.prevent.stop="$refs.pMenu.open($event, { ...p, index: i })">
                <i class="fa fa-bars fa-2x"></i>
              </button>
            </div>
          </td>
          <td>
            {{ p.num }}
            <span v-if="fu.isTransfer(p.fl)" class="fa fa-arrows-h"></span>
          </td>
          <td>{{ russianDate(p.pDate) }}</td>
          <td>{{ p.e }}</td>
          <td>{{ p.ow.n }}</td>
          <td>{{ p.rn }}</td>
          <td>{{ p.p }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu, { forceUpper, russianDate, loadComponentState, storeComponentState } from '@/lib/funcs';
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import SelectBlank from '@/controls/SelectBlank';
import { required } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { sign } from '@/lib/sign';
import { isUserInRole } from '@/store/store';
export default {
  data() {
    return {
      blockUI: !1,
      m: { pn: null, cn: null, certs: [] },
      fu,
    };
  },
  validations() {
    return {
      m: {
        pn: { required, regex: regex(/^\s*([A-ZА-Я]{2,2})\s*(\d{2,7})\s*$/) },
        cn: { required, regex: regex(/^\s*([A-ZА-Я]{1,3})\s*(\d{2,8})\s*$/) },
      },
    };
  },
  methods: {
    forceUpper,
    russianDate,
    isUserInRole,
    changeBlank(index, oldBlankId, newBlankId) {
      let vm = this;
      vm.blockUI = true;
      sign({ oldBlankId, newBlankId })
        .then(ajax.changeBlank)
        .then(r => {
          vm.$dlg.toast('Бланк заменен. Он будет отображен у того пользователя, который выдал временное разрешение', { messageType: 'success', closeTime: 20 });
          vm.m.certs.splice(index, 1);
        })
        .catch(err => {
          vm.$showError('Ошибка', err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    askChangeBlank(p) {
      let vm = this;
      vm.$dlg.modal(SelectBlank, {
        params: { },
        width: 600,
        height: 400,
        title: `Выбор бланка для замены разрешения ${p.num}`,
        callback: function(payload) {
          if (payload) vm.changeBlank(p.index, p.bId, payload);
        },
      });
    },
    edit(p) {
      let vm = this;
      if (isUserInRole('perm_moderator', 'admin')) {
        vm.$router.push({ name: 'editPerm', params: { guid: p.guid } });
      } else {
        vm.$showError('Ошибка', { errCode: 1, message: 'Редактирование запрещено' });
      }
    },
    del(p, index) {
      let vm = this;
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно удалить ${fu.describeTransfer(p.fl)} № ${p.num}?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            vm.blockUI = true;
            sign(p.guid)
              .then(d => ajax.delPermission({ guid: p.guid, s: d.s }))
              .then(r => {
                vm.$dlg.toast('Удаление успешно', { messageType: 'success', closeTime: 3 });
                vm.m.certs.splice(index, 1);
              })
              .catch(err => {
                vm.$showError('Ошибка', err);
              })
              .finally(() => {
                vm.blockUI = false;
              });
          }
        },
      });
    },
    searchByPerm() {
      let vm = this;
      vm.blockUI = true;
      vm.m.certs = [];
      ajax
        .findPermByNum({ pn: vm.m.pn.trim() })
        .then(
          r => {
            vm.m.certs = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    searchByCn() {
      let vm = this;
      vm.blockUI = true;
      vm.m.certs = [];
      ajax
        .findPermByCn({ cn: vm.m.cn.trim() })
        .then(
          r => {
            vm.m.certs = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  destroyed() {
    storeComponentState('OthersPerms', this.m);
  },
  mounted() {
    this.m = loadComponentState('OthersPerms', this.m);
    this.$nextTick(() => {
      this.$refs.pn.focus();
    });
  },
  components: { SelectBlank },
};
</script>
