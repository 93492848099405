<template>
  <modal-wrapper @close="close" :width="width">
    <template slot="header">
      <h4>Поиск платежа ЕРИП</h4>
    </template>
    <div class="form-group">
      <label class="control-label col-sm-6" :class="{ 'has-error': $v.m.searchTerm.$invalid }">Техпаспорт или № платежа ЕРИП:</label>
      <div class="col-sm-5 input-group">
        <input
          type="text"
          class="form-control"
          v-model="m.searchTerm"
          @keyup.enter="search"
          @input="forceUpper($event, m, 'searchTerm')"
          ref="searchField"
        />
        <span class="input-group-btn">
          <button type="button" class="btn btn-default" @click.prevent.stop="search" :disabled="$v.m.searchTerm.$invalid">
            <span class="fa fa-search" />
          </button>
        </span>
      </div>
    </div>
    <table class="table table-hover table-mc-light-blue table-striped" v-show="!blockUI">
      <thead>
        <tr>
          <td class="td1">Дата</td>
          <td class="td2">№</td>
          <td class="td3">Сумма</td>
          <td class="td4">Услуга</td>
          <td class="td5" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in pays" :key="i" :style="p.st != 2 && { 'text-decoration': 'line-through' }">
          <td class="td1">{{ p.date | msToDate }}</td>
          <td class="td2">{{ p.txId }}</td>
          <td class="td3">{{ p.s }}</td>
          <td class="td4">{{ seName(p.se) }}</td>
          <td class="td5">
            <button
              v-if="showInsertButton && useAllowed(p)"
              @click="apply(p)"
              :disabled="!useAllowed(p)"
              class="btn btn-success"
              style="padding: 2px 5px"
            >
              Вставить
            </button>
            <span v-else>{{ describeRefuseReason(p) }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-info" v-show="blockUI">Загружаю...</div>
    <template slot="footer">
      <button class="btn" type="button" @click="showInactive" v-show="onlyActive && !!pays">Показать погашенные</button>
      <button class="btn btn-warning" type="button" @click="close">Отмена</button>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/controls/ModalWrapper';
import { eripServiceNames, payStateNames, patterns } from '@/lib/consts';
import { forceUpper } from '@/lib/funcs';
import { required } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
export default {
  data() {
    return {
      blockUI: false,
      m: {
        searchTerm: this.initialSearchTerm,
      },
      pays: null,
      onlyActive: true,
    };
  },
  validations() {
    return {
      m: {
        searchTerm: { required, pattern: regex(new RegExp(`^\\s*((${patterns.sCn})|(\\d{10,20}))\\s*$`)) },
      },
    };
  },
  props: {
    width: {
      type: String,
    },
    initialSearchTerm: {
      type: String,
    },
    payload: {
      default: () => {},
    },
    allowedServices: { type: Array },
    showInsertButton: { type: Boolean, default: () => true },
  },
  components: { ModalWrapper },
  methods: {
    forceUpper,
    useAllowed(p) {
      return p.st === 2 && (this.allowedServices == null || this.allowedServices.includes(p.se));
    },
    describeRefuseReason(p) {
      if (this.allowedServices && !this.allowedServices.includes(p.se)) {
        return 'Другая услуга';
      }
      return p.st !== 2 ? payStateNames[p.st] : '';
    },
    seName: id => eripServiceNames[id],
    close() {
      this.$emit('close');
    },
    apply(p) {
      this.$emit('apply', { foundPayment: p, payload: this.payload, payType: 3 });
    },
    showInactive() {
      this.onlyActive = false;
      this.search(null, true);
    },
    search(_, dontChangeActivity) {
      let vm = this;
      let searchTerm = this.m.searchTerm.trim();
      let res = null;
      if (searchTerm) {
        if (searchTerm.match(patterns.cn)) {
          if (!dontChangeActivity) {
            this.onlyActive = true;
          }
          res = ajax.searchEripPaymentsByAccount({ searchTerm, onlyActive: this.onlyActive });
        } else if (!(searchTerm === '' || isNaN(searchTerm))) {
          if (!dontChangeActivity) {
            this.onlyActive = false;
          }
          res = ajax.searchEripPaymentsByTxId({ searchTerm, onlyActive: this.onlyActive });
        }
      }
      if (!res) {
        alert('То, что вы ввели, не похоже на техпаспорт или номер платежа ЕРИП');
        return;
      }
      vm.blockUI = true;
      res
        .then(
          r => {
            vm.pays = r.data.content;
          },
          err => {
            vm.pays = null;
            this.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    this.$refs.searchField.focus();
    if (this.initialSearchTerm) this.search();
  },
};
</script>

<style scoped lang="css">
table {
  width: 100%;
}

table,
td {
  border-collapse: collapse;
}

thead {
  display: table; /* to take the same width as tr */
  width: calc(100% - 17px); /* - 17px because of the scrollbar width */
}

tbody {
  display: block; /* to enable vertical scrolling */
  max-height: 200px; /* e.g. */
  overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
}

th,
td {
  padding: 5px;
  word-break: break-all; /* 4. */
}
.td1 {
  width: 15%;
}
.td2 {
  width: 15%;
}
.td3 {
  width: 10%;
}
.td4 {
  width: 40%;
}
.td5 {
  width: 20%;
}

tr {
  display: table; /* display purpose; th's border */
  width: 100%;
  box-sizing: border-box; /* because of the border (Chrome needs this line, but not FF) */
}

td {
  text-align: center;
  border-bottom: none;
  border-left: none;
}
</style>
