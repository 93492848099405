<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div class="text-center">
      <button
        id="bigButton"
        class="btn btn-success"
        v-if="firstBlank && firstBlank.length"
        @click="issue(firstBlank[0])"
      >
        <span class="text-center" style="font-size: xx-large">
          Оформить разрешение
          {{ firstBlank[0].s }} {{ numberPad(firstBlank[0].n, 7) }}
        </span>
      </button>
      <h3 v-else>У вас нет чистых бланков</h3>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      ></div>
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <th />
          <th>Серия</th>
          <th>Номер</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bl, i) in blanks" :key="i" style="cursor: pointer;" @dblclick="issue(bl)">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="issue(bl)">
                <i class="fa fa-plus-circle fa-2x"></i>
              </button>
            </div>
          </td>
          <td>{{ bl.s }}</td>
          <td>{{ numberPad(bl.n, 7) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { numberPad } from '@/lib/funcs';
export default {
  data() {
    return {
      blockUI: false,
      numberPad,
      blanks: [],
      firstBlank: [],
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 0,
    };
  },
  methods: {
    issue(bl) {
      if (!bl) return;
      let vm = this;
      vm.$router.push({ name: 'editPerm', params: { blank: _cloneDeep(bl) } });
    },
    load() {
      let vm = this;
      vm.blanks = [];
      vm.blockUI = true;
      ajax
        .getBlanks({ type: 1, sk: vm.pageSize * (vm.pg.currentPage - 1), pageSize: vm.pageSize })
        .then(
          r => {
            vm.totalItems = Number(r.data.content[0]);
            vm.firstBlank = r.data.content[1];
            vm.blanks = r.data.content[2];
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
