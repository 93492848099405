import EditTA from '@/components/transferApplications/edit/EditTA';
import EditTa2 from '@/components/transferApplications/edit/EditTa2';
import EditTa3 from '@/components/transferApplications/edit/EditTa3';

export default [
  {
    path: '/editTA/:id?',
    component: EditTA,
    name: 'editTA',
    hidden: true,
    meta: { title: 'Редактирование заявления на перенос', },
  },
  {
    path: '/editTa2/:id?',
    component: EditTa2,
    name: 'editTa2',
    hidden: true,
    meta: { title: 'Редактирование заявления на перенос', },
  },
  {
    path: '/editTa3/:id?',
    component: EditTa3,
    name: 'editTa3',
    hidden: true,
    meta: { title: 'Редактирование заявления на внесение изменений', },
  },
];
