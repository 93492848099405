<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>

    <form class="form-inline" @submit.prevent="aaa = 1">
      <div class="form-group">
        <label for="unp">УНП:</label>
        <div class="input-group">
          <input type="text" class="form-control" id="unp" placeholder="УНП" name="unp" v-model.number="unp" ref="unp" @keypress.enter="find('u')" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" title="Искать" @click="find('u')">
              <span class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Наименование:</label>
        <div class="input-group">
          <input type="text" class="form-control" id="name" placeholder="Наименование" name="name" v-model="name" @keypress.enter="find('n')" />
          <span class="input-group-btn">
            <button class="btn btn-default" type="button" title="Искать" @click="find('n')">
              <span class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
    </form>
    <div style="height:350px;overflow:auto;">
      <table class="table table-hover table-mc-light-blue table-striped">
        <thead>
          <tr>
            <td>
              УНП/КОП
              <span title="Код обособленного подразделения" style="text-decoration: underline">?</span>
            </td>
            <td>Наименование</td>
          </tr>
        </thead>
        <tbody v-if="counteragents.length != 0">
          <tr v-for="c in counteragents" :key="c.counteragentId" @dblclick="close(c)" style="cursor: pointer;" @click="close(c)">
            <td>{{ c.client.u }}{{ c.client.b ? ' / ' + c.client.b : '' }}</td>
            <td>{{ c.client.n }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" style="text-align:center;">Нет данных</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn btn-warning" type="button" @click="$emit('close')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blockUI: false,
      counteragents: [],
      unp: null,
      name: '',
    };
  },
  methods: {
    find(searchType) {
      let vm = this;
      let criteria = { unp: null, name: null };
      if (searchType === 'u') {
        let unp = vm.unp;
        if (isNaN(unp) || unp < 100000000 || unp > 999999999) {
          vm.$dlg.alert('Неправильный УНП', { messageType: 'warning' });
          return;
        }
        criteria.unp = unp;
      } else {
        let name = vm.name.trim();
        if (name.length < 5) {
          vm.$dlg.alert('Введите хотя бы 5 символов наименования', { messageType: 'warning' });
          return;
        }
        criteria.name = name;
      }

      vm.blockUI = true;
      vm.counteragents = [];
      ajax
        .findCounteragents(criteria)
        .then(
          r => {
            vm.counteragents = r.data.content;
          },
          err => {
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    close(c) {
      this.$emit('close', { name: c.client.n, unp: c.client.u, counteragentId: c.counteragentId });
    },
  },
  mounted() {
    let vm = this;
    vm.$nextTick(() => {
      this.$refs.unp.focus();
      vm.$log.debug(this.$refs.unp);
    });
  },
};
</script>
