<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>

    <div v-if="initialError">Ошибка чтения информации о клиенте</div>
    <div v-else>
      <div style="height: 330px; overflow: auto;" class="container-fluid">
        <client-component v-model="m.client" :v="$v.m.client" ref="client" @address="newAddress" @branchSelected="branchSelected" />
        <!-- Поля для ЮЛ И ИП: -->
        <div v-if="m.client.t != 0">
          <!-- Юр. адрес: -->
          <div class="form-group row">
            <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.legalAddress.$invalid }">Юр. адрес</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="legalAddress" v-model="m.legalAddress" />
            </div>
          </div>

          <!-- Почтовый адрес: -->
          <div class="form-group row">
            <label class="control-label col-sm-4">Почтовый адрес</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="postAddress" v-model="m.postAddress" />
            </div>
          </div>

          <!-- IBAN: -->
          <div class="form-group row">
            <label class="control-label col-sm-4">IBAN</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="iban" v-model="m.iban" />
            </div>
          </div>

          <!-- bic: -->
          <div class="form-group row">
            <label class="control-label col-sm-4">BIC</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="bic" v-model="m.bic" />
            </div>
          </div>

          <!-- Банк: -->
          <div class="form-group row">
            <label class="control-label col-sm-4">Банк</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="bank" v-model="m.bank" />
            </div>
          </div>

          <!-- В лице: -->
          <!-- <div class="form-group row">
            <label class="control-label col-sm-4">В лице</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="representative" v-model="m.representative" placeholder="напр. «директора Пупкина В.И.»" />
            </div>
          </div> -->

          <!-- на основании: -->
          <!-- <div class="form-group row">
            <label class="control-label col-sm-4">Действующего на основании</label>
            <div class="no-pad col-sm-8">
              <input class="form-control" type="text" name="ground" v-model="m.ground" placeholder="напр. «доверенности №» или «устава»" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" style="text-align: right;">
          <button class="btn btn-warning footer-btn" type="button" @click="$emit('close')">
            Отмена
          </button>
          <button class="btn btn-success footer-btn" type="button" @click="save" :disabled="$v.m.$invalid">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import { clientValidation } from '@/lib/validators';
import { modifiable } from '@/lib/mixins';
import { formsOfOwnership } from '@/lib/consts';
import { mapFunctions, freshClient } from '@/lib/funcs';
import ClientComponent from '@/controls/ClientComponent';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      formsOfOwnership,
      blockUI: false,
      counteragentId: null,
      counteragents: [],
      contracts: [],
      initialError: false,
      m: {
        client: freshClient(1),
        counteragentId: null,
        legalAddress: '',
        postAddress: '',
        iban: '',
        bank: '',
        bic: null,
        representative: null,
        ground: null,
      },
      m_: {},
    };
  },
  computed: {
    validationRules() {
      return {
        client: clientValidation(),
        legalAddress: {
          required: requiredIf(function(nm) {
            return nm.client.t != 0;
          }),
        },
      };
    },
  },
  validations() {
    return { m: this.validationRules };
  },
  props: {
    initialId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    ...mapFunctions(['numberPad']),
    branchSelected(info) {
      this.m.legalAddress = info.a;
      this.m.postAddress = info.a;
    },
    newAddress(address) {
      this.m.legalAddress = address;
    },
    save() {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      if (vm.$v.$invalid) {
        vm.$dlg.alert('Правильно заполните все поля', {
          messageType: 'warning',
        });
        return;
      }
      if (vm.m.counteragentId && _isEqual(vm.m, vm.m_)) {
        this.$log.debug('no changes');
        vm.$emit('close');
      } else {
        vm.blockUI = true;
        sign(vm.m)
          .then(ajax.saveCounteragent)
          .then(r => {
            let rslt = r.data.content;
            vm.m_ = _cloneDeep(rslt);
            vm.m = _cloneDeep(rslt);
            vm.$emit('close', {
              name: rslt.client.n,
              unp: rslt.client.u,
              counteragentId: rslt.counteragentId,
            });
          })
          .catch(err => {
            vm.$showError(null, err);
          })
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
  },
  mounted() {
    let vm = this;
    vm.m_ = _cloneDeep(vm.m);
    vm.$nextTick(() => vm.$refs.client.setFocus());
    if (vm.initialId) {
      vm.blockUI = true;
      ajax
        .getCounteragent({ id: vm.initialId })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    }
  },
  components: { ClientComponent },
  mixins: [modifiable],
};
</script>
