<template>
  <div id="app">
    <BlockUI v-if="errCode == 1">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw" aria-hidden="true"/>
    </BlockUI>
    <password-exp v-if="passExpMsg" @close="passExpMsg = false" :userInfo="userInfo" :width="600" />
    <div v-if="errCode == 2">
      Ошибка при попытке загрузки данных с сервера.
      <br />Попробуйте обновить страницу. Если это не приведет к устранению проблемы, обратитесь в ИАУ.
    </div>
    <nav class="navbar navbar-fixed-left" v-if="loggedIn && errCode == 0">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div
            class="navbar-brand"
            @dblclick="toggleDebugMode"
            :title="userTitle"
            :style="debugMode && { color: 'red' }"
          >
            {{ userInfo && userInfo.login }}
          </div>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav">
            <li :class="{ active: $route.fullPath.startsWith('/perms') }">
              <router-link to="/perms" title="Разрешения" class="text-menu-item">Р</router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/mstos') }">
              <router-link to="/mstos" title="МСТО" class="text-menu-item">М</router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/ekmts') }">
              <router-link to="/ekmts" title="ЕКМТ" class="text-menu-item">Е</router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/ogs') }">
              <router-link to="/ogs" title="Опасные грузы" class="text-menu-item">ОГ</router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/passBlanks') }">
              <router-link to="/passBlanks" title="Передать бланки">
                <i class="fa fa-2x fa-arrow-right" aria-hidden="true"/>
              </router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/refuses') }">
              <router-link to="/refuses" title="Отказы">
                <i class="fa fa-2x fa-hand-paper-o" />
              </router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/acts') }">
              <router-link to="/acts" title="Акты" class="text-menu-item">А</router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/oais') }" v-if="isUserInRole('oais_operator')">
              <router-link to="/oais" title="ОАИС" class="text-menu-item"><img src="img/oais32.png"/></router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/rpt') }">
              <router-link to="/rpt" title="Отчетность">
                <i class="fa fa-2x fa-bar-chart" />
              </router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/settings') }">
              <router-link to="/settings" title="Настройки">
                <i class="fa fa-2x fa-cogs" />
              </router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/mail') }">
              <router-link to="/mail" title="Сообщения">
                <i class="fa fa-2x fa-envelope">
                  <!-- <span
                    v-show="unreadMessages"
                    class="badge blink"
                    style="position: absolute; display: inline; background-color: #fc0fc0"
                    top="1"
                    left="1"
                    >{{ unreadMessages }}</span
                  > -->
                  </i>
              </router-link>
            </li>
            <li :class="{ active: $route.fullPath.startsWith('/test') }" v-if="debugMode">
              <router-link to="/test" title="Тестовая страница">
                <i class="fa fa-2x fa-bug red-text" />
              </router-link>
            </li>
            <li>
              <a href="https://download.anydesk.com/AnyDesk.exe" title="Скачать Anydesk"><img src="img/any32.png"/></a>
            </li>
            <li>
              <a @click="logout">
                <i class="fa fa-2x fa-sign-out" title="Выйти" />
              </a>
            </li>

            <!-- <template v-for="(item, index) in $router.options.routes" v-if="isVisible(item)">
              <li
                v-if="!item.hidden && !hasVisibleChildren(item)"
                :key="index"
                :title="item.meta.title"
              >
                <router-link :to="item.path">
                  <i v-if="item.icon" :class="item.icon" class="fa fa-2x"/>
                  <div v-if="item.meta.label">{{item.meta.label}}</div>
                  <span>&nbsp;{{item.meta.title}}</span>
                </router-link>
              </li>
              <li
                v-if="!item.hidden && hasVisibleChildren(item)"
                class="dropdown"
                :key="index"
                :title="item.meta.title"
              >
                <a
                  href="#"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i :class="item.icon" class="fa fa-2x" style="display: inline;"></i>
                  <i class="fa fa-caret-right"></i>
                </a>
                <span>{{item.meta.title}}</span>
                <ul class="dropdown-menu">
                  <li
                    v-for="(subitem, subindex) in item.children"
                    v-if="isVisible(subitem)"
                    :key="subindex"
                    :title="subitem.title"
                  >
                    <router-link :to="subitem.path">
                      <i class="fa fa-2x" :class="subitem.icon"></i>
                      <span>{{subitem.title}}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </template>-->
          </ul>
        </div>
      </div>
    </nav>
    <router-view v-if="errCode == 0" />
    <!-- <div class="container">

    <div class="jumbotron">
      <h1>
        Bootstrap Navbar Sidebar
        <br>
        <small>Fixed to Left or Right</small>
      </h1>
      <p>
        <strong>
          Use classic Bootstrap navbar as sidebar, on left or right side.
        </strong>
      </p>
      <p>
        <a class="github-button" href="https://github.com/mladenplavsic/bootstrap-navbar-sidebar" data-icon="octicon-star" data-style="mega" data-count-href="/mladenplavsic/bootstrap-navbar-sidebar/stargazers" data-count-api="/repos/mladenplavsic/bootstrap-navbar-sidebar#stargazers_count" data-count-aria-label="# stargazers on GitHub" aria-label="Star mladenplavsic/bootstrap-navbar-sidebar on GitHub">Star</a>
        <a class="github-button" href="https://github.com/mladenplavsic/bootstrap-navbar-sidebar/fork" data-icon="octicon-repo-forked" data-style="mega" data-count-href="/mladenplavsic/bootstrap-navbar-sidebar/network" aria-label="Fork mladenplavsic/bootstrap-navbar-sidebar on GitHub">Fork</a>
        <a class="github-button" href="https://github.com/mladenplavsic" data-style="mega" aria-label="Follow @mladenplavsic on GitHub">Follow @mladenplavsic</a>
      </p>
      <p>Same when using <code>.navbar-fixed-top</code> - add class <code>.navbar-fixed-left</code> or <code>.navbar-fixed-right</code> where needed.</p>
      <p>Click buttons below, and appropriate class will be added to example navbar.</p>
      <p>
        <span class="btn-group">
          <a data-class="navbar-fixed-left" class="btn btn-lg btn-default">
            <i class="fa fa-arrow-left"></i>
            Fixed Left
          </a>
          <a data-class="navbar-fixed-top" class="btn btn-lg btn-default">
            <i class="fa fa-arrow-up"></i>
            Fixed Top
            <small>(original)</small>
          </a>
          <a data-class="navbar-fixed-right" class="btn btn-lg btn-default">
            <i class="fa fa-arrow-right"></i>
            Fixed Right
          </a>
        </span>
    </p>
    </div>

    <footer>
      Repository maintained by <a href="https://github.com/mladenplavsic">mladenplavsic</a>
    </footer>

    </div>-->
    <!-- <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hashCode } from '@/lib/funcs';
import { appVersion, buildDate } from '@/../generated/js/generatedAppInfo.js';
import { currentDebugPassword } from './lib/funcs';
import PasswordExp from '@/components/logon/PasswordExp.vue';
export default {
  name: 'app',
  data() {
    return {
      pass: '',
      errCode: 1, // 0 - все ОК, 1 - ждем результата, 2 - ошибка
      passExpMsg: false,
    };
  },
  created() {
    let vm = this;
    vm.$on('passExp', i => {
      vm.passExpMsg = true;
    });

    vm.$nextTick(() => {
      ajax.getUserInfo().then(
        r => {
          vm.$store.commit('login', r.data.content);
          vm.$dlg.toast('Пользователь ' + r.data.content.name, { messageType: 'info', closeTime: 1 });
          vm.errCode = 0;
          vm.$store.commit('loadStoredData', this);
        },
        e => {
          if (e.errCode == 401) vm.errCode = 0;
        }
      );
    });
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyPress);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyPress);
  },
  methods: {
    logout() {
      let vm = this;
      ajax.logout().then(
        () => {
          vm.$store.commit('logout');
          try {
            vm.$router.push({ name: 'login' });
            // eslint-disable-next-line no-empty
          } catch (ignored) {}
        },
        err => vm.$log.error(err)
      );
    },
    isUserInRole() {
      const uInfo = this.userInfo;
      if (uInfo && 'roles' in uInfo) {
        for (var i in arguments) {
          if (uInfo.roles.indexOf(arguments[i]) !== -1) {
            return true;
          }
        }
      }
      return false;
    },
    hasVisibleChildren(item) {
      if (this.isVisible(item) && item.children && item.children.length > 0) {
        for (var i in item.children) {
          if (this.isVisible(item.children[i])) return true;
        }
      }
      return false;
    },
    isVisible(item) {
      // this.$log.debug(JSON.stringify(this.userInfo))
      if (item.hidden) {
        return false;
      }

      if (item.roles) {
        for (var i = 0; i < item.roles.length; i++) {
          if (this.userInfo.roles.includes(item.meta.roles[i])) {
            return true;
          }
        }
      } else {
        return true;
      }
      return false;
    },
    toggleDebugMode() {
      let vm = this;
      if (vm.debugMode) {
        vm.$store.commit('setDebugMode', false);
      } else {
        let pass = window.prompt('Пароль отладочного режима', '');
        if (pass == currentDebugPassword()) {
          vm.$store.commit('setDebugMode', true);
        } else {
          alert('неверно...');
        }
      }
    },
    onKeyPress(e) {
      this.pass = this.pass + e.key;
      if (this.pass.length > 9) {
        this.pass = this.pass.substring(this.pass.length - 9);
      }
      if (hashCode(this.pass) == 778134101) {
        this.$store.commit('setDebugMode', true);
      }
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'debugMode', 'unreadMessages', 'loggedIn']),
    userTitle() {
      return (this.userInfo && this.userInfo.name) + `\nВерсия ${appVersion} от ${buildDate}`;
    },
  },
  components: { PasswordExp },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .container {
    width: 503px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 723px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 923px;
  }
}
@media (min-width: 1432px) {
  .container {
    width: 1170px;
  }
}
body {
  padding-top: 70px;
}
.navbar-fixed-left,
.navbar-fixed-right {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
}
@media (min-width: 768px) {
  body {
    margin-left: 48px;
  }
  .navbar-fixed-left,
  .navbar-fixed-right {
    width: 48px;
    height: 100vh;
    border-radius: 0;
  }
  .navbar-fixed-left .container,
  .navbar-fixed-left .container-fluid,
  .navbar-fixed-right .container,
  .navbar-fixed-right .container-fluid {
    padding-right: 0;
    padding-left: 0;
    width: auto;
  }
  .navbar-fixed-left .navbar-header,
  .navbar-fixed-right .navbar-header {
    float: none;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-fixed-left .navbar-collapse,
  .navbar-fixed-right .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    max-height: none;
  }
  .navbar-fixed-left .navbar-collapse .navbar-nav,
  .navbar-fixed-right .navbar-collapse .navbar-nav {
    float: none !important;
  }
  .navbar-fixed-left .navbar-collapse .navbar-nav > li,
  .navbar-fixed-right .navbar-collapse .navbar-nav > li {
    width: 100%;
  }
  .navbar-fixed-left .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu,
  .navbar-fixed-right .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
    top: 0;
  }
  .navbar-fixed-left .navbar-collapse .navbar-nav.navbar-right,
  .navbar-fixed-right .navbar-collapse .navbar-nav.navbar-right {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  body {
    padding-top: 0;
    margin-left: 48px;
  }
  .navbar-fixed-left {
    right: auto !important;
    left: 0 !important;
    border-width: 0 1px 0 0 !important;
  }
  .navbar-fixed-left .dropdown .dropdown-menu {
    left: 100%;
    right: auto;
    border-radius: 0 3px 3px 0;
  }
  .navbar-fixed-left .dropdown .dropdown-toggle .caret {
    border-top: 4px solid transparent;
    border-left: 4px solid;
    border-bottom: 4px solid transparent;
    border-right: none;
  }
  ul.nav > li > a {
    font-style: italic;
    text-align: center;
    padding: 0px;
  }
  ul.nav > li > a > span {
    display: none;
  }
  .navbar-nav > li > a > i {
    vertical-align: middle;
  }
  .navbar-nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
  }
  .navbar-brand,
  .navbar-header {
    text-overflow: clip;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
  }
  .navbar-nav > li > a {
    padding-left: 5px;
  }
}
.text-menu-item {
  width: 47px;
  font-size: 17pt;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
}
</style>
