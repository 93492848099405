<template>
  <modal-wrapper @close="$emit('close')" :width="width">
    <template slot="header">
      <h3>Срок действия пароля</h3>
    </template>
    <h4>Срок действия пароля истекает {{ expDate }}.</h4>
    <h4>
      Рекомендуется
      <a href="#" @click="changePass">изменить пароль</a>.
    </h4>
    <template slot="footer">
      <button class="btn btn-warning" type="button" @click="$emit('close')">Закрыть</button>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/controls/ModalWrapper';
import { russianDate } from '@/lib/funcs';
export default {
  computed: {
    expDate() {
      return russianDate(this.userInfo.pExpDate);
    },
  },
  props: { userInfo: { type: Object, required: true }, width: { type: Number, required: true } },
  methods: {
    changePass() {
      this.$emit('close');
      this.$router.push({
        path: `/user/changePassword?login=${this.userInfo.login}`,
      });
    },
  },
  components: { ModalWrapper },
};
</script>
