<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>

    <div class="form-group">
      <label class="control-label col-sm-3">№ акта</label>
      <div class="col-sm-9 input-group">
        <input
          placeholder="Введите не менее 3 знаков номера акта"
          class="form-control"
          type="text"
          name="actNum"
          v-model="actNum"
          @keyup.enter="findActByNum"
        />
        <span class="input-group-btn">
          <button class="btn btn-default" type="button" title="Искать" @click="findActByNum">
            <span class="fa fa-search" />
          </button>
        </span>
      </div>
    </div>
    <div style="height:350px;overflow:auto;">
      <table class="table table-hover table-mc-light-blue table-striped">
        <thead>
          <tr>
            <td>
              № акта
              <br />Дата акта
            </td>
            <td>
              № договора
              <br />Дата договора
            </td>
            <td>
              УНП
              <br />Наименование
            </td>
          </tr>
        </thead>
        <tbody v-if="acts.length!=0">
          <tr v-for="a in acts" :key="a.actId" @click="select(a)" style="cursor: pointer;">
            <td>
              {{a.actNum}}
              <br />
              {{a.actDate|date}}
            </td>
            <td>
              {{a.contractNum}}
              <br />
              {{a.contractDate|date}}
            </td>
            <td>
              {{a.unp}}
              <br />
              {{a.name}}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" style="text-align:center;">Нет данных</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn btn-warning" type="button" @click="close">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blockUI: false,
      acts: [],
      actNum: null,
    };
  },
  props: {
    serviceId: {
      type: [Number],
      required: true,
    },
  },
  methods: {
    findActByNum() {
      let vm = this;
      let actNum = vm.actNum.trim();
      if (actNum.length > 3) {
        vm.blockUI = true;
        vm.acts = [];
        ajax
          .findActsByNum({ serviceId: vm.serviceId, actNum })
          .then(
            r => {
              vm.acts = r.data.content;
            },
            err => {
              vm.$showError('error', err);
            }
          )
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
    close() {
      this.$emit('close');
    },
    select(act) {
      this.$emit('close', act);
    },
  },
};
</script>
