<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-6">Найти по № ОГ</label>
          <div class="col-sm-6 input-group">
            <input class="form-control" v-model="m.certNumber" @keyup.enter="$v.m.certNumber.$invalid || search(0)" />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="search(0)"
                :disabled="$v.m.certNumber.$invalid"
                @input="forceUpper($event, m, 'certNumber')"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-5">или госномеру</label>
          <div class="col-sm-7 input-group">
            <input class="form-control" v-model="m.rn" @keyup.enter="$v.m.rn.$invalid || search(1)" />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="search(1)"
                :disabled="$v.m.rn.$invalid"
                @input="forceUpper($event, m, 'rn')"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <og-list-common :ajax-loader="ajaxLoader" :ajax-params="ajaxParams" />
    </div>
  </div>
</template>

<script>
import OgListCommon from './OgListCommon';
import { required, numeric } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { patterns } from '@/lib/consts';
export default {
  data() {
    return {
      ajaxLoader: ajax.ogListFiltered,
      m: { certNumber: null, rn: null },
      ajaxParams: { certNumber: null, rn: null },
    };
  },
  methods: {
    search(type) {
      if (type) {
        this.ajaxParams = { ...this.m, certNumber: null };
      } else {
        this.ajaxParams = { ...this.m, rn: null };
      }
    },
  },
  components: { OgListCommon },
  validations() {
    return {
      m: {
        certNumber: { required, numeric },
        rn: { required, pattern: regex(patterns.combinedRN) },
      },
    };
  },
};
</script>
