<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <h3 class="text-center">Свои заявления на внесение изменений</h3>

    <div class="btn-group up-right-corner">
      <button
        type="button"
        class="btn btn-success"
        @click="$router.push({ path: '/editTa3/' })"
        title="Новое заявление"
      >
        <i class="fa fa-plus fa-2x" />
      </button>
    </div>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="pageChanged"
        :items-per-page="pageSize"
        :max-size="7"
      ></div>
    </div>

    <table class="table table-mc-light-blue table-striped ">
      <thead>
        <tr>
          <th></th>
          <th>Номер<br />Разрешение</th>
          <th>Создано<br />Завершено</th>
          <th>Создал<br />Завершил</th>
          <th>Имя</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, i) in tas" :key="i" @dblclick="edit(r.id)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="edit(r.id)">
                <i class="fa fa-edit fa-2x" />
              </button>
            </div>
            <!-- <div class="btn-group">
                            <button type="button" class="btn btn-in-bar" ng-click="edit(r.id)">
                                <img ng-src="unsecured/img/edit{{r.cDate?'_disabled_red':'' }}32.png">
                            </button> -->
            <!--<button type="button" class="btn btn-in-bar" ng-click="del(r)">
                        <img src="unsecured/img/delete32.png" >
                    </button>-->
            <!-- </div> -->
          </td>
          <td>{{ r.id }}<br />{{ r.pn }}</td>
          <td>{{ russianDate(r.aDate) }}<br />{{ russianDate(r.cDate) }}</td>
          <td>{{ r.oUser }}<br />{{ !!r.cUser ? r.cUser : '---' }}</td>
          <td>{{ r.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { russianDate } from '@/lib/funcs';
export default {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 0,
      blockUI_: 0,
      tas: [],
    };
  },
  computed: {
    blockUI() {
      return this.blockUI_ !== 0;
    },
  },
  methods: {
    edit(id) {
      this.$router.push({ path: '/editTa3/' + id });
    },
    russianDate,
    getTACount() {
      let vm = this;
      vm.blockUI_ |= 2;
      ajax
        .getTasCount3()
        .then(
          r => {
            vm.totalItems = Number(r.data.content);
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI_ &= 0xfffffffd;
        });
    },
    loadTAs(skip) {
      let vm = this;

      vm.blockUI_ |= 1;
      ajax
        .getTas3({ sk: skip, pageSize: vm.pageSize })
        .then(
          r => {
            vm.tas = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI_ &= 0xfffffffe;
        });
    },
    pageChanged() {
      this.loadTAs((this.pg.currentPage - 1) * this.pageSize);
    },
  },
  created() {
    this.getTACount();
    this.pageChanged();
  },
};
</script>

<style></style>
