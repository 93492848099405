<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <form class="form-inline" onsubmit="return false;">
      <div class="form-group">
        <label for="num" :class="{ 'has-error': $v.m.num.$invalid }">ЕКМТ&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <div class="input-group">
          <input type="text" class="form-control" id="num" ref="num" v-model="m.num" @input="forceUpper($event, m, 'num')" />
          <span class="input-group-btn">
            <button class="btn btn-default" :disabled="$v.m.$invalid" @click="search">
              <i class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>
    </form>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <td></td>
          <td>№</td>
          <td>Дата</td>
          <td>Выдал(а)</td>
          <td>Заказчик</td>
          <td>Госномер</td>
          <td>Срок действия</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c, i) in m.certs" :key="i" @dblclick="edit(c)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="edit(c)">
                <i class="fa fa-edit fa-2x" />
              </button>
              <button type="button" class="btn btn-in-bar" @click="del(c, i)">
                <i class="fa fa-trash fa-2x" />
              </button>
            </div>
          </td>
          <td>
            {{ c.number }}
          </td>
          <td>{{ russianDate(c.date) }}</td>
          <td :title="c.un">{{ c.ul }}</td>
          <td>{{ c.cust }}</td>
          <td>{{ c.regNumber }}</td>
          <td>{{ russianDate(c.validTo) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu, { forceUpper, russianDate, loadComponentState, storeComponentState, b64EncodeUnicode } from '@/lib/funcs';
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { required } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      blockUI: !1,
      m: { num: null, certs: [] },
      fu,
    };
  },
  validations() {
    return {
      m: {
        num: { required, regex: regex(/^\s*(BY\/D)?\s*(\d{2,7})\s*$/) },
      },
    };
  },
  methods: {
    forceUpper,
    russianDate,
    edit(c) {
      let vm = this;
      vm.$router.push({ name: 'editEkmt', params: { certId: c.id } });
    },
    del(c, index) {
      let vm = this;
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно удалить ЕКМТ № ${c.number}?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            vm.blockUI = true;
            sign(c.id)
              .then(d => ajax.deleteEkmt({ certId: c.id, s: d.s }))
              .then(r => {
                vm.$dlg.toast('Удаление успешно', { messageType: 'success', closeTime: 3 });
                vm.m.certs.splice(index, 1);
              })
              .catch(err => {
                vm.$showError('Ошибка', err);
              })
              .finally(() => {
                vm.blockUI = false;
              });
          }
        },
      });
    },
    search() {
      let vm = this;
      let num = vm.m.num.trim();
      if (num.match('^\\s*\\d')) num = 'BY/D' + num;
      vm.blockUI = true;
      vm.m.certs = [];
      ajax
        .findEkmt({ num: b64EncodeUnicode(num) })
        .then(
          r => {
            vm.m.certs = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  destroyed() {
    storeComponentState('OthersEkmts', this.m);
  },
  mounted() {
    this.m = loadComponentState('OthersEkmts', this.m);
    this.$nextTick(() => {
      this.$refs.num.focus();
    });
  },
};
</script>
