<template>
  <div>
    <div v-html="message" />
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button
          v-for="(b,i) in customButtons"
          :key="i"
          class="btn"
          :class="b.class?b.class:'btn-success'"
          style="margin-right: 5px;"
          type="button"
          @click="close(b.id)"
        >{{b.name}}</button>

        <button v-if="buttons&1" class="btn btn-default" type="button" @click="close(1)">ОК</button>
        <button v-if="buttons&2" class="btn btn-success" type="button" @click="close(2)">Да</button>
        <button v-if="buttons&4" class="btn btn-warning" type="button" @click="close(4)">Нет</button>
        <button v-if="buttons&8" class="btn btn-warning" type="button" @click="close(8)">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Message'
    },
    /**
     * Состав кнопок. Битовая маска
     * 1 - OK
     * 2 - Да
     * 4 - Нет
     * 8 - Отмена
     */
    buttons: {
      type: Number,
      default: 1
    },
    customButtons: {
      type: Array,
      required: false,
      default() { return []; }
    }
  },
  methods: {
    close(bNumber) {
      this.$emit('close', bNumber);
    }
  }
};
</script>

<style scoped>
button {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
