<template>
  <div>
    <h3 class="text-center">Передача бланков</h3>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <form name="passBlanksForm">
      <div class="form-group row">
        <label class="control-label col-sm-4" :class="{ 'red-text': $v.m.blankType.$invalid }">
          Тип бланков:
        </label>
        <div class="col-sm-2 no-left-pad">
          <select v-model="blankType" class="form-control" @change="blankTypeChanged">
            <option v-for="bt in blankTypes" :key="bt.id" :value="bt">{{ bt.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="control-label col-sm-4" :class="{ 'red-text': $v.m.userTo.$invalid }">Кому:</label>
        <div class="col-sm-7 no-pad">
          <vue-single-select name="userTo" v-model="m.userTo" :options="users" />
        </div>
      </div>

      <div class="form-group row">
        <label class="control-label col-sm-4" :class="{ 'red-text': $v.blankGroup.$invalid }">
          Диапазон бланков:
        </label>
        <div class="col-sm-2 no-left-pad">
          <input
            type="text"
            v-model="m.series"
            class="form-control"
            @input="forceUpper($event, m, 'series')"
            placeholder="Серия"
            :disabled="seriesFixed"
          />
        </div>
        <div class="col-sm-2 no-left-pad">
          <input name="begin" type="number" v-model.number="m.begin" class="form-control" placeholder="Начало" />
        </div>
        <div class="col-sm-2 no-left-pad">
          <input type="number" v-model.number="m.end" class="form-control" placeholder="Конец" />
        </div>
        <div class="col-sm-1 no-pad">
          <input type="text" :value="countBlanks" class="form-control" placeholder="количество" disabled />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-5 col-sm-push-4 no-pad" style="padding-top: 6px;">
          <button class="btn btn-success" type="button" @click="pass" :disabled="$v.$invalid">Передать</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minValue, maxValue, maxLength } from 'vuelidate/lib/validators';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { mapGetters } from 'vuex';
import { forceUpper } from '@/lib/funcs';
import { blankTypes } from '@/lib/consts';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      blankType: null,
      blockUI: false,
      seriesFixed: false,
      blankTypes,
      m: { userTo: null, series: null, begin: null, end: null, user: null, blankType: null },
    };
  },
  validations() {
    return {
      m: {
        action: 3,
        userTo: { required },
        series: { mx: maxLength(4) },
        begin: { required, mn: minValue(1), mx: maxValue(90000000) },
        end: { required, mn: minValue(Math.max(1, this.m.begin)), mx: maxValue(90000000) },
        blankType: { required },
      },
      blankGroup: ['m.series', 'm.begin', 'm.end'],
    };
  },
  computed: {
    countBlanks() {
      let m = this.m;
      if (typeof m.end == 'number' && typeof m.begin == 'number' && m.end >= m.begin) {
        return m.end - m.begin + 1;
      }
    },
    ...mapGetters(['users', 'userInfo']),
  },
  methods: {
    blankTypeChanged() {
      let vm = this;
      if (vm.blankType) {
        vm.m.blankType = vm.blankType.id;
        vm.seriesFixed = vm.blankType.seriesFixed;
        if (vm.seriesFixed) {
          vm.m.series = vm.blankType.defaultSeries;
        }
      } else {
        vm.m.blankType = null;
        vm.seriesFixed = false;
      }
    },
    forceUpper,
    pass() {
      let vm = this;
      vm.blockUI = true;
      sign(vm.m)
        .then(d => ajax.blankOps({ operation: 'pass' }, d))
        .then(r => {
          vm.$dlg.toast(r.data.content, { messageType: 'success', closeTime: 3 });
        })
        .catch(err => {
          vm.$showError('Ошибка', err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  components: { VueSingleSelect },
  mounted() {
    this.m.user = this.userInfo.id;
  },
};
</script>
