// const OaisPage = () => import(/* webpackChunkName: "/OaisPage" */ '@/components/oais/OaisPage.vue');
// const OaisApplicationList = () => import(/* webpackChunkName: "/OaisApplicationList" */ '@/components/oais/OaisApplicationList');
// const OaisApplication = () => import(/* webpackChunkName: "/OaisApplication" */ '@/components/oais/OaisApplication');

import OaisPage from '@/components/oais/OaisPage.vue';
import OaisApplicationList from '@/components/oais/OaisApplicationList';
import OaisApplication from '@/components/oais/OaisApplication';

export default [
  {
    path: '/oais',
    component: OaisPage,
    children: [
      {
        path: 'applications',
        component: OaisApplicationList,
        name: 'oaisApplications',
        meta: {
          title: 'Заявления на админ. процедуру',
        },
      },
    ],
  },
  {
    path: '/oaisApplication/:number',
    component: OaisApplication,
    name: 'openOaisApplication',
    hidden: true,
    meta: {
      title: 'Рассмотрение заявления из ОАИС',
    },
  },
];
