<template>
  <div class="container-fluid">
    <h3 class="text-center">Сообщения</h3>
    <div class="row">
      <div class="col-xs-3">
        <ul class="nav nav-pills nav-stacked">
          <li v-for="(msg, i) in messages" :key="i" :class="{ active: i == selected }">
            <a @click="select(i)"
              >{{ msg.topic }}<br />
              <span class="badge" :title="msg.crName">{{ msg.crLogin }}</span> <span class="label label-default">{{ msg.created | date }}</span></a
            >
          </li>
        </ul>
      </div>
      <div class="col-xs-9" v-show="selected != null && selected >= 0">
        <div class="form-group row">
          <label class="control-label col-sm-5 col-md-3">Тема</label>
          <div class="col-sm-7 col-md-5 no-pad">
            <input class="form-control" type="text" readonly v-model="m.topic" />
          </div>
        </div>
        <div class="form-group row">
          <label class="control-label col-sm-5 col-md-3">Создал(а)</label>
          <div class="col-sm-7 col-md-5 no-pad">
            <input class="form-control" type="text" readonly :value="m.crName + ' ' + formatDate(m.created)" />
          </div>
        </div>
        <div class="form-group row" v-show="m.modified">
          <label class="control-label col-sm-5 col-md-3">Изменил(а)</label>
          <div class="col-sm-7 col-md-5 no-pad">
            <input class="form-control" type="text" readonly :value="m.modName + ' ' + formatDate(m.modified)" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xs-12">
            <textarea readonly style="width: 100%;" rows="10" v-model="m.message"></textarea>
          </div>
        </div>
        <div class="form-group row" v-if="!m.recipients[0].dateRead">
          <div class="icheck-primary icheck-inlined">
            <input id="confirmed" v-model="confirmed" type="checkbox" />
            <label for="confirmed">Ознакомлен</label>
          </div>
          <button class="btn btn-success" :disabled="!confirmed" @click="markRead">Отметить прочтение</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { updateMessageCount } from '@/store/store';
export default {
  computed: {
    ...mapGetters(['userSettings', 'ds']),
  },
  data() {
    return {
      selected: null,
      confirmed: false,
      messages: [],
      m: {
        topic: null,
        message: null,
        created: null,
        modified: null,
        crName: null,
        modName: null,
        recipients: [{ dateRead: 1 }],
      },
    };
  },
  methods: {
    formatDate(d) {
      return moment(d).format('DD.MM.YYYY');
    },
    markRead() {
      let vm = this;
      vm.blockUI = true;
      let id = vm.m.id;
      ajax
        .markMessageRead({ id }, { id })
        .then(
          r => {
            vm.$dlg.toast('Помечено как прочитанное', { messageType: 'info', closeTime: 3 });
            vm.m.recipients[0].dateRead = 1;
            updateMessageCount();
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    markOpened(id) {
      let vm = this;
      ajax.markMessageOpened({ id }, { id }).then(
        r => {
          vm.$dlg.toast('Сообщение помечено как открытое', { messageType: 'info', closeTime: 3 });
        },
        err => {
          vm.$showError('Ошибка', err);
        }
      );
    },
    select(i) {
      let vm = this;
      if (i == vm.selected) return;
      vm.selected = null;
      vm.confirmed = false;
      vm.blockUI = true;
      let m = vm.messages[i];
      ajax
        .getUserMessage({ id: m.id })
        .then(
          r => {
            vm.m = r.data.content;
            vm.selected = i;
            vm.m.recipients[0].opened || vm.markOpened(m.id);
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    reloadMessages() {
      let vm = this;
      vm.blockUI = true;
      vm.$dlg.closeAll(null, 'toast');
      ajax
        .getUserMessages()
        .then(
          r => {
            vm.selected = null;
            vm.messages = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    this.reloadMessages();
  },
};
</script>
