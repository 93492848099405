<template>
  <div>
    <ul class="nav nav-tabs">
      <li :class="{ active: $route.path.endsWith('/blanks') }">
        <router-link to="/ogs/blanks">Бланки</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/certs') }">
        <router-link to="/ogs/certs">Свидетельства</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/searchOgs') }">
        <router-link to="/ogs/searchOgs">Поиск ОГ</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/cards') }">
        <router-link to="/ogs/cards">Карты</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/searchCards') }">
        <router-link to="/ogs/searchCards">Поиск карт</router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { tabbedSubroutes } from '@/lib/mixins';
export default {
  data() {
    return {
      componentName: 'OgPage',
      defaultSubRoute: 'blanks',
    };
  },
  mixins: [tabbedSubroutes],
};
</script>
