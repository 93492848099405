import { correctTZ, freshClient, rus2lat } from '@/lib/funcs';
import { patterns } from '@/lib/consts';
import _isArray from 'lodash/isArray';
import { getTariff } from '@/store/store';

export function freshPayment() {
  return {
    payId: null,
    docNum: null,
    s: null,
    t: 3,
    docDate: null,
  };
}

export function freshPermission(subserviceId = 101) {
  return {
    applId: null,
    mId: null,
    cId: null,
    aId: null,
    cn: '',
    bId: null,
    dcDSId: null,
    pDSId: null,
    permNumber: null,
    pPeriod: null,
    dc: null,
    dl: null,
    regNum1: null,
    regNum2: null,
    y: null,
    ow: freshClient(),
    cust: freshClient(),
    hold: freshClient(),
    p1: {
      payments: [{ ...freshPayment(), s: getTariff(subserviceId, new Date()).total }],
    },
    p2: {
      payments: [freshPayment()],
    },
    pDate: moment().format(),
    dcDate: moment().format('YYYY-MM-DD'),
    validTo: null,
    guid: null,
    mass: null,
    kdrId: null,
    vin: null,
    ton: 0,
    mark: 0,
    agr: 0,
    newAuto: 0,
    el: 0,
    spec: 0,
    mintrans: 0,
    wasTransfered: 0,
    transferType: 0,
    fromGai: 0,
    /**
     * ТС, специально оборудованное для использования инвалидами (cripple)
     */
    cr: 0,
    gaiId: null,
    tdrId: 5,
    pS: 0,
    tS: 0,
    actId: null,
  };
}

export const preparePerm = (p, forSave) => {
  // p.dl = p.dlSer + numberPad(p.dlNum, 7)
  // p.dc = p.dcSer + numberPad(p.dcNum, 7)
  p.pDate = correctTZ(moment(p.pDate).toDate(), forSave);
  p.dcDate = correctTZ(moment(p.dcDate).toDate(), forSave);
  p.validTo = correctTZ(moment(p.validTo).toDate(), forSave);
  [p.p1, p.p2].forEach(function(ps) {
    ps.payments.forEach(function(p) {
      try {
        p.docDate = correctTZ(moment(p.docDate).toDate(), forSave);
      } catch (e) {} // eslint-disable-line
    });
  });
  return p;
};

/**
 * Расчет последнего дня действия разрешения.
 * @param {Date} dt Дата выдачи разрешения
 * @param {Number} period На какой срок выдается разрешение (в месяцах)
 * @returns {String} Последний день действия разрешения в виде строки формата ISO 'YYYY-MM-DD'
 */
export const calcValidTo = (dt, period) => {
  // Дата выдачи разрешения раньше 14.01.2021, используем старый алгоритм (до последнего дня месяца)
  if (moment(dt) <= moment('2021-01-14')) {
    return moment(dt)
      .date(1)
      .add(period + 1, 'M')
      .add(-1, 'd')
      .format('YYYY-MM-DD');
  } else {
    // Новый алгоритм расчета срока действия разрешения (с 14.01.2021 года)
    return moment(dt)
      .add(period, 'month')
      .format('YYYY-MM-DD');
  }
};

export const clipboardSupported = () => {
  return 'queryCommandSupported' in window.document && window.document.queryCommandSupported('copy');
};

/**
 * подготавливает массив к копированию в буфер обмена для вставки в Microsoft Excel
 * Каждый элемент входного массива преобразуется в строку, в ней знак табуляции заменяется на 4 пробела,
 * после чего элементы массива сливаются в строку с разделителем tab (0x09)
 * @param {Array} arr массив входных данных
 * @returns {String} строка, пригодная для копирования в буфер обмена
 */
export function prepare4Clipboard(arr) {
  if (!_isArray(arr)) return 'на входе должен быть массив';
  var _arr = arr.map((item) => String(item).replace(/\t/, '    '));
  return _arr.join('\t');
}

export const findRefNameById = (ref, id) => {
  var r = ref.find(function(e) {
    return e.id == id;
  });
  return !r ? '' : r.name;
};

export const checkPermissionValid = (p, taxDiscounts, subserviceId) => {
  var errmsg = [];
  let checkTaxPayment = !(p.transfer || p.transfer2 || moment(p.pDate) >= moment('2021-01-01'));
  try {
    if (p.cId <= 0) {
      errmsg.push('Не указана категория ТС');
    }
    if (!p.newAuto) {
      if (p.dcDSId === null) {
        errmsg.push('Не заполнена станция ДК');
      }

      if (p.dc && !p.dc.match(patterns.dc)) {
        errmsg.push('Не заполнен (или неправильно заполнен) номер ДК');
      }
    }

    if (!p.mId) {
      errmsg.push('Не выбрана модель ТС');
    }

    p.regNum1 = p.regNum1 == null ? null : p.regNum1.toUpperCase();
    p.regNum2 = p.regNum2 == null ? null : p.regNum2.toUpperCase();

    if (!(p.regNum1 && p.regNum1.match(patterns.combinedRN))) {
      errmsg.push('основной госномер - ошибка');
    }
    if (p.regNum2) {
      if (!p.regNum2.match(patterns.combinedRN)) {
        errmsg.push('дополнительный госномер - ошибка');
      }
    }

    if (p.y === null || p.y < 0) {
      errmsg.push('Неверно указан год выпуска');
    }
    if (!p.transfer && !p.hold.n) {
      errmsg.push('Не внесен владелец');
    }
    if (checkTaxPayment) {
      if (p.p2.payments.find((el) => el.t === 0)) {
        errmsg.push('Платеж за пошлину не может быть наличным');
      }
      let taxDiscountAbsent = true;
      let taxDiscount = taxDiscounts.find((el) => el.id == p.tdrId);
      if (taxDiscount) {
        taxDiscountAbsent = taxDiscount.percent != 100;
      }
      if (taxDiscountAbsent && p.p2.payments.length == 0) {
        errmsg.push('Должен присутствовать платеж за госпошлину');
      }
    }
    // if ((p.transfer || p.tdrId === 1) && p.p2 && p.p2.payments && p.p2.payments.length != 0) {
    //   errmsg.push("Присутствуют платежи за госпошлину при полном освобождении")
    // }
    // Проверка платежей:
    if (!p.transfer) {
      // если освобождены от уплаты ГП или это перенос информации на новый бланк, то НЕ ПРОВЕРЯЕМ платежи ГП:
      var ps = p.tdrId == 1 || p.transfer2 || moment(p.pDate) >= moment('2021-01-01') ? [p.p1] : [p.p1, p.p2];
      var i, j;
      for (i = ps.length - 1; i >= 0; i--) {
        if (!ps[i] || !ps[i].payments) continue;
        // кое-кто освобожден от платежа за госпошлину. А если не указан платеж за разрешение, считаем, что он был совершен наличными
        // if (!ps[i] || !ps[i].payments || ps[i].payments.length == 0) {
        //     errmsg.push("не указан один из платежей")
        // }
        for (j = ps[i].payments.length - 1; j >= 0; j--) {
          var pay = ps[i].payments[j];
          if (!pay.s) {
            errmsg.push('Не указана сумма платежа');
          }
          if ((pay.t == 2 || pay.t == 6) && !pay.docNum) {
            errmsg.push('Не указан номер документа');
          }
          if ((pay.t == 2 || pay.t == 4 || pay.t == 6) && !pay.docDate) {
            errmsg.push('Не указана дата документа');
          }
          if (pay.t == 3 || pay.t == 5 || pay.t == 7) {
            if (!pay.payId) {
              errmsg.push('Не указан номер платежа');
            }
          }
        }
      }
      let ppPayments = ((p.p1 && p.p1.payments) || []).filter((p) => p.t == 2);
      if (p.cust.t == 0 && ppPayments.length > 0) {
        errmsg.push('Физлицо не может платить платежным поручением');
      }
    }
  } catch (e) {
    errmsg.push(String(e));
  }
  return errmsg;
};

/**
 * Сравнивает полные номера техпаспортов, игнорируя пробелы, регистр и считая похожие по написанию русские и английские буквы одинаковыми.
 * @param {String} fullCN Строка с полным номером первого техпаспорта (серия + номер).
 * @param {String} cnSeries Серия второго техпаспорта.
 * @param {String | Number} cnNumber номер второго техпаспорта.
 * @returns результат сравнения (true/false).
 */
export function cnEquals(fullCN, cnSeries, cnNumber) {
  if (!(fullCN && cnSeries && cnNumber)) return false;
  cnNumber = parseInt(cnNumber);
  if (isNaN(cnNumber)) return false;
  let fullCN_ = rus2lat(fullCN.toUpperCase());
  fullCN = '';
  for (let char of fullCN_) {
    if (!/\s/.test(char)) {
      fullCN += char;
    }
  }
  cnSeries = rus2lat(cnSeries.trim().toUpperCase());
  let prefix = '';
  while ((prefix + cnNumber).length <= 8) {
    if (fullCN == (cnSeries + prefix + cnNumber)) return true;
    prefix = prefix + '0';
  }
  return false;
}
