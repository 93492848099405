import PassBlanks from '@/components/perms/PassBlanks';
import Test from '@/components/Test';
import Reports from '@/components/Reports';
import Settings from '@/components/Settings';
import UserMessages from '@/components/UserMessages';
import LoginPage from '@/components/logon/LoginPage.vue';
import ChangePassword from '@/components/logon/ChangePassword.vue';

export default [
  {
    path: '/passBlanks',
    component: PassBlanks,
    icon: 'fa-arrow-right',
    meta: { title: 'Передать бланки', },
  },
  {
    path: '/rpt',
    component: Reports,
    icon: 'fa-bar-chart',
    meta: { title: 'Отчетность', },
  },
  {
    path: '/mail',
    name: 'mail',
    component: UserMessages,
    icon: 'fa-envelope',
    meta: { title: 'Сообщения', },
  },
  {
    path: '/settings',
    component: Settings,
    icon: 'fa-cogs',
    meta: { title: 'Настройки', },
  },
  {
    path: '/test',
    component: Test,
    icon: 'fa-bug red-text',
    meta: {
      title: 'Тестовая страница',
      roles: ['admin'],
    },
  },
  {
    meta: { title: 'Вход' },
    path: '/logon/login',
    name: 'login',
    hidden: true,
    component: LoginPage,
  },
  {
    meta: { title: 'Изменение пароля' },
    path: '/user/changePassword',
    hidden: true,
    component: ChangePassword,
  },
];
