<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="ctMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="print(child.data)">Печатать</a></li>
        <li><a @click.prevent="del(child.data, false)">Удалить</a></li>
        <li><a @click.prevent="del(child.data, true)">Забраковать</a></li>
      </template>
    </vue-context>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      />
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <td></td>
          <td>№</td>
          <td>Дата</td>
          <td>Срок</td>
          <td>Госномер</td>
          <td>Заказчик</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c, i) in ekmts" :key="i" @dblclick="edit(c)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click.prevent.stop="$refs.ctMenu.open($event, c)">
                <i class="fa fa-bars fa-2x" />
              </button>
            </div>
            <!-- <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="edit(c)">
                <i class="fa fa-edit"></i>
              </button>
              <button type="button" class="btn btn-in-bar" @click="print(c)">
                <i class="fa fa-print"></i>
              </button>
              <button type="button" class="btn btn-in-bar" @click="del(c)">
                <i class="fa fa-trash"></i>
              </button>
            </div>-->
          </td>
          <td>{{ c.number }}</td>
          <td>{{ c.date | date }}</td>
          <td>{{ c.validTo | date('MM/YYYY') }}</td>
          <td>{{ c.regNumber }}</td>
          <td>{{ c.cust }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 20,
      totalItems: 100,
      blockUI: false,
      ekmts: [],
    };
  },
  methods: {
    edit(c) {
      this.$router.push({ name: 'editEkmt', params: { certId: c.id } });
    },
    print(c) {
      window.open('s/certs/ekmtPdf2/' + c.id, '_blank');
    },
    del(c, trash) {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      let message = trash
        ? `Действительно забраковать бланк ЕКМТ ${c.number}? Информация о сертификате будет удалена, а бланк получит статус "Забракован"`
        : `Действительно удалить ЕКМТ ${c.number}? Информация о сертификате будет удалена, а бланк вернется в список чистых бланков`;

      vm.$dlg.modal(ConfirmDialogVue, {
        params: { message, buttons: 6 },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b !== 2) return;
          vm.blockUI = true;
          sign(c.ekmtId)
            .then(d => ajax.deleteEkmt({ certId: c.id, waste: !!trash, s: d.s }))
            .then(r => {
              vm.load();
              vm.$dlg.toast(trash ? 'Забракован' : 'Удален', '', { messageType: 'success', closeTime: 3 });
            })
            .catch(err => {
              vm.$showError('Ошибка при удалении', err);
            })
            .finally(() => {
              vm.blockUI = false;
            });
        },
      });
    },
    load() {
      let vm = this;
      vm.blockUI = true;
      ajax
        .ekmtList({ page: vm.pg.currentPage, pageSize: vm.pageSize })
        .then(
          r => {
            vm.ekmts = r.data.content[0];
            vm.totalItems = r.data.content[1];
          },
          err => {
            vm.$showError('Ошибка загрузки', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
