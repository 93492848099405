<template>
  <modal-wrapper @close="close" :width="width">
    <template slot="header">
      <h4>Поиск карты доп. проверки</h4>
    </template>

    <div class="form-group">
      <label class="control-label col-sm-6" :class="{ 'has-error': $v.m.searchTerm.$invalid }"
        >Номер карты или госномер:</label
      >
      <div class="col-sm-5 input-group">
        <input
          type="text"
          class="form-control"
          v-model="m.searchTerm"
          @keyup.enter="$v.m.searchTerm.$invalid || search()"
          @input="forceUpper($event, m, 'searchTerm')"
          ref="searchField"
        />
        <span class="input-group-btn">
          <button
            type="button"
            class="btn btn-default"
            @click.prevent.stop="search"
            :disabled="$v.m.searchTerm.$invalid"
          >
            <span class="fa fa-search" />
          </button>
        </span>
      </div>
    </div>

    <table
      class="table table-hover table-mc-light-blue table-striped"
      v-show="!blockUI && (status == 0 || status == 2)"
    >
      <thead>
        <tr>
          <th>Номер</th>
          <th>Дата</th>
          <th>Госномер</th>
          <th>VIN</th>
          <th>Г.В.</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="status == 2">
          <td style="text-align: center" colspan="6">Ничего не найдено</td>
        </tr>

        <tr
          v-show="status == 0"
          v-for="c in result"
          :key="c.id"
          :style="c.cond || { 'text-decoration': 'line-through' }"
        >
          <td>{{ c.id }}</td>
          <td>{{ c.d | date }}</td>
          <td>{{ c.rn }}</td>
          <td>{{ c.vin }}</td>
          <td>{{ c.vY }}</td>
          <td class="fa-pull-right">
            <button class="btn btn-success" type="button" @click="apply(c)" :disabled="!c.cond">
              Выбрать
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="alert alert-info" v-show="blockUI">Загружаю...</div>
    <div class="alert alert-info" v-show="!blockUI && status == 1">Ошибка поиска</div>

    <template slot="footer">
      <button class="btn btn-warning" type="button" @click="close">Закрыть</button>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/controls/ModalWrapper';
import { forceUpper } from '@/lib/funcs';
import { required } from 'vuelidate/lib/validators';
import { patterns } from '@/lib/consts';
export default {
  data() {
    return {
      blockUI: false,
      m: { searchTerm: null },
      result: [],
      // результат поиска. -1 - не осуществлялся, 0 - успех, 1 - ошибка, 2 - ничего не найдено
      status: -1,
    };
  },
  validations() {
    return {
      m: {
        searchTerm: { required },
      },
    };
  },
  props: {
    width: {
      type: String,
      required: false,
    },
  },
  components: { ModalWrapper },
  methods: {
    forceUpper,
    close() {
      this.$emit('close');
    },
    apply(card) {
      this.$emit('apply', card);
    },
    search() {
      let vm = this;
      vm.blockUI = true;
      let searchParams;
      if (/^\d+$/.test(vm.m.searchTerm)) {
        searchParams = { number: vm.m.searchTerm, rn: null };
      } else if (patterns.combinedRN.test(vm.m.searchTerm)) {
        searchParams = { rn: vm.m.searchTerm, number: null };
      } else return;

      ajax
        .ogCardSearch(searchParams)
        .then(
          r => {
            if (r.data.content.length == 0) {
              vm.status = 2;
              return;
            }
            vm.status = 0;
            vm.result = r.data.content.map(x => ({ ...x, cond: (x.p & 0b01000000000000000000000000000000) != 0 }));
          },
          err => {
            vm.status = 1;
            vm.result = [];
            this.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    this.$refs.searchField.focus();
  },
};
</script>
