import oaisRoutes from './oais';
import mstoRoutes from './msto';
import ekmtRoutes from './ekmt';
import ogRoutes from './og';
import permRoutes from './perm';
import otherRoutes from './other';
import actRoutes from './act';
import refuseRoutes from './refuse';
import taRoutes from './ta';

let routes = [
  ...oaisRoutes,
  ...mstoRoutes,
  ...ekmtRoutes,
  ...ogRoutes,
  ...permRoutes,
  ...otherRoutes,
  ...actRoutes,
  ...refuseRoutes,
  ...taRoutes,
  {
    path: '/',
    redirect: { path: '/settings' },
  },

];

export default routes;
