<template>
  <div>
    <ul class="nav nav-tabs">
      <li :class="{ active: $route.path.endsWith('/blanks') }">
        <router-link to="/mstos/blanks">Бланки</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/certs') }">
        <router-link to="/mstos/certs">Выданные</router-link>
      </li>
      <li :class="{ active: $route.path.endsWith('/prolong') }">
        <router-link to="/mstos/prolong">Продление</router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { tabbedSubroutes } from '@/lib/mixins';
export default {
  data() {
    return {
      componentName: 'MstosPage',
      defaultSubRoute: 'blanks',
    };
  },
  mixins: [tabbedSubroutes],
};
</script>
