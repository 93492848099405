export default {
  // список заявлений на этапах "Подано" и "На рассмотрении":
  getActive: { method: 'GET', url: 'oais/activeApplication?sk={sk}&l={pageSize}' },
  // Начать рассмотрение заявления:
  take: { method: 'POST', url: 'oais/activeApplication/take/{number}' },
  // отменить рассмотрение заявления (вернуть в нерассмотренные):
  untake: { method: 'POST', url: 'oais/activeApplication/untake/{number}' },
  // Отказать в принятии заявления:
  refuseToAccept: { method: 'POST', url: 'oais/activeApplication/refuseToAccept/{number}' },
  // Отказать в осуществлении АП:
  refuseProcedure: { method: 'POST', url: 'oais/activeApplication/refuseProcedure/{number}' },
};
