<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-6">Найти по № МСТО</label>
          <div class="col-sm-6 input-group">
            <input
              class="form-control"
              v-model="m.certNumber"
              @keyup.enter="$v.m.certNumber.$invalid || search(0)"
            />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="search(0)"
                :disabled="$v.m.certNumber.$invalid"
                @input="forceUpper($event, m, 'certNumber')"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-5">или VIN</label>
          <div class="col-sm-7 input-group">
            <input
              class="form-control"
              v-model="m.vin"
              @keyup.enter="$v.m.vin.$invalid || search(1)"
            />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="search(1)"
                :disabled="$v.m.vin.$invalid"
                @input="forceUpper($event, m, 'vin')"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <msto-list-common :ajax-loader="ajaxLoader" :ajax-params="ajaxParams" />
    </div>
  </div>
</template>

<script>
import MstoListCommon from './MstoListCommon';
import { required } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { patterns } from '@/lib/consts';
export default {
  data() {
    return {
      ajaxLoader: ajax.mstoListFiltered,
      m: { certNumber: null, vin: null },
      ajaxParams: { certNumber: null, vin: null },
    };
  },
  methods: {
    search(type) {
      if (type) {
        this.ajaxParams = { ...this.m, certNumber: null };
      } else {
        let certNumber = this.m.certNumber.trim().toUpperCase();
        if (!isNaN(certNumber)) {
          certNumber = 'BY' + certNumber;
        }
        this.ajaxParams = { certNumber, vin: null };
      }
    },
  },
  components: { MstoListCommon },
  validations() {
    return {
      m: {
        certNumber: { required, pattern: regex(/^(BY)?\s*(\d{2,7})$/i) },
        vin: { required, pattern: regex(patterns.vinLoose) },
      },
    };
  },
};
</script>

<style>
</style>
