<template>
  <div>
    <div class="fixed-header">
      <span class="fixed-header-title">{{ title }}</span>
      <span class="fa-pull-right">
        <button
          class="btn btn-success icon-button"
          type="button"
          title="Создать акт"
          @click.prevent.stop="$refs.creationMenu.open($event)"
        >
          <i class="fa fa-plus fa-2x"></i>
        </button>
        <button class="btn btn-success icon-button" type="button" @click="showFilter">
          <i class="fa fa-filter fa-2x"></i>
        </button>
      </span>
    </div>

    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>

    <vue-context ref="creationMenu">
      <template slot-scope="child">
        <li v-for="s in validServices" :key="s.id">
          <a @click.prevent="addOrEditAct({ serviceId: s.id })">{{ s.name }}</a>
        </li>
      </template>
    </vue-context>
    <vue-context ref="aMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="print(child.data, 2)">Печатать</a></li>
        <li><a @click.prevent="edit(child.data, true)">Создать копию</a></li>
        <li v-show="isUserInRole('act_delete', 'admin')"><a @click.prevent="del(child.data)">Удалить</a></li>
    </template>
    </vue-context>
    <div class="under-fixed-header">
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      />
      <h2 v-if="wrongFilter">Для отображения актов задайте фильтр</h2>
      <table v-else class="table table-hover table-mc-light-blue">
        <thead>
          <tr style="border-bottom-style:hidden;">
            <td rowspan="2" width="40px"></td>
            <td>Услуга</td>
            <td>№ акта</td>
            <td>№ договора</td>
            <td>УНП</td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>Дата акта</td>
            <td>Дата договора</td>
            <td colspan="2">Организация</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(a, i) in acts">
            <tr
              style="border-bottom-style:hidden; cursor: pointer;"
              @dblclick="edit(a)"
              :style="a.completeUserId && userInfo.id != a.completeUserId && { 'text-decoration': 'line-through' }"
            >
              <td rowspan="2">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-in-bar"
                    @click.prevent.stop="$refs.aMenu.open($event, { ...a, index: i })"
                  >
                    <i class="fa fa-bars fa-2x"></i>
                  </button>
                </div>
              </td>
              <td>{{ serviceId2Name[a.serviceId] }}</td>
              <td>{{ a.actNum }}</td>
              <td>{{ a.contractNum }}</td>
              <td>{{ a.unp || '' }}</td>
            </tr>
            <tr style="cursor: pointer;" @dblclick="edit(a)">
              <td>{{ a.total }}</td>
              <td :title="a.completeUserName">
                {{ a.actDate | date }}<span v-show="a.completeUserLogin"> ({{ a.completeUserLogin }})</span>
              </td>
              <td :title="a.userName">{{ a.contractDate | date }} ({{ a.userLogin }})</td>
              <td colspan="2">{{ a.name }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { countNonBlanksFields, loadComponentState, storeComponentState } from '@/lib/funcs';
import { describeFilter, filterToURI, isFilterEmpty, emptyFilter } from './docsFuncs';
import FilterDialog from './FilterDialog';
import { mapGetters } from 'vuex';
import EditAct from './EditAct';
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { isUserInRole } from '@/store/store';
import { sign } from '@/lib/sign';
const stateName = 'ActsList';
export default {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 20,
      totalItems: 100,
      blockUI: false,
      // фильтр для отбора объектов:
      filter: emptyFilter(),
      acts: [],
      serviceId2Name: {},
    };
  },
  methods: {
    isUserInRole,
    print(a, template) {
      let type = 'act';
      switch (template) {
        case 1:
          type = 'contract';
          break;
        case 2:
          type = 'actContract';
          break;
      }
      let dsNumber = this.userSettings.curDsNumber || '___';
      window.open(
        `s/docs/pdf2/${type}/${a.actId}?dsNumber=${encodeURIComponent(dsNumber)}&userName=${encodeURIComponent(
          this.userInfo.name
        )}`,
        '_blank'
      );
    },
    addOrEditAct(ai) {
      let vm = this;
      vm.$dlg.modal(EditAct, {
        params: ai,
        width: 800,
        height: 590,
        title:
          (ai.initialId && !ai.makeCopy ? 'Договор(акт)' : 'Новый договор(акт)') +
          ' на ' +
          vm.serviceId2Name[ai.serviceId],
        callback: (x) => {
          x && vm.load();
        },
      });
    },
    edit(ai, makeCopy) {
      this.addOrEditAct({
        initialContractId: ai.contractId,
        initialId: ai.actId,
        serviceId: ai.serviceId,
        makeCopy,
      });
    },
    del(ai) {
      let vm = this;
      vm.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно удалить акт ${ai.actNum} от ${ai.contractDate}?`,
          buttons: 6,
        },
        width: 600,
        height: 140,
        title: 'Подтверждение',
        callback: function(b) {
          if (b !== 2) return;
          vm.blockUI = true;
          sign(ai.actId)
            .then((d) => ajax.deleteAct({ id: ai.actId, s: d.s, si: vm.edsImpl }))
            .then(() => vm.acts.splice(ai.index, 1))
            .catch((err) => vm.$showError('Ошибка', err))
            .finally(() => {
              vm.blockUI = false;
            });
        },
      });
    },
    applyFilter(f) {
      if (f) {
        let vm = this;
        vm.filter = f;
        vm.$router.push({ path: '/acts' + filterToURI(vm.filter) });
      }
    },

    load() {
      let vm = this;
      if (vm.wrongFilter) return;
      vm.blockUI = true;
      vm.acts = [];
      ajax
        .actsFilter(Object.assign({}, vm.filter, { sk: (vm.pg.currentPage - 1) * vm.pageSize, l: vm.pageSize }))
        .then(
          (r) => {
            vm.acts = r.data.content[0];
            vm.totalItems = r.data.content[1];
          },
          (err) => vm.$showError('Ошибка загрузки', err)
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    showFilter() {
      let vm = this;
      vm.$dlg.modal(FilterDialog, {
        params: {
          filter: vm.filter,
        },
        width: 600,
        height: 390,
        title: 'Фильтр актов',
        callback: vm.applyFilter,
      });
    },
  },
  computed: {
    title() {
      return describeFilter('Акты', this.filter);
    },
    wrongFilter() {
      return isFilterEmpty(this.filter);
    },
    validServices() {
      return this.servicesArray.filter((e) => e.v);
    },
    ...mapGetters({ servicesArray: 'services', userSettings: 'userSettings', userInfo: 'userInfo', edsImpl: 'edsImpl' }),
  },
  mounted() {
    let vm = this;
    vm.servicesArray.forEach((e) => (vm.serviceId2Name[e.id] = e.name));

    if (countNonBlanksFields(this.$route.query)) {
      vm.filter = Object.assign(vm.filter, vm.$route.query);
      //   // Если фильтр пришел снаружи, то его не будем сохранять в localStore
      //   this.storeFilter = false
    } else {
      vm.filter = loadComponentState(stateName, vm.filter);
    }
    vm.load();
  },
  watch: {
    '$route.query'(curr, prev) {
      let vm = this;
      vm.filter = Object.assign(vm.filter, curr);
      vm.load();
    },
  },
  beforeRouteLeave(to, from, next) {
    // вызывается перед переходом от пути, соответствующего текущему компоненту
    // имеет доступ к контексту экземпляра компонента `this`.
    storeComponentState(stateName, this.filter);
    next();
  },
};
</script>

<style></style>
