<template>
  <div class="panel panel-default row">
    <vue-context ref="servicesMenu">
      <template slot-scope="child">
        <li v-for="s in serviceSetsFiltered" :key="s.id">
          <a @click.prevent="serviceSetChanged(s)">{{ s.name }}</a>
        </li>
      </template>
    </vue-context>
    <div class="panel-heading">
      Услуги&nbsp;&nbsp;
      <button @click.prevent.stop="$refs.servicesMenu.open">Выбрать услуги</button>
    </div>
    <div class="panel-body">
      <table class="table table-mc-light-blue">
        <thead>
          <tr>
            <td>Услуга</td>
            <td>Кол-во</td>
            <td>цена</td>
            <td>Без НДС</td>
            <td>НДС</td>
            <td>всего</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, i) in value" :key="i">
            <td>{{ s.shortName }}</td>
            <td :class="{ [errorClass]: v.$each[i].count.$invalid }">
              <input
                type="number"
                step="1"
                min="1"
                max="2"
                v-model.number="s.count"
                @change="countChanged(s)"
                :class="{ 'has-error': s.count > 2 }"
              />
            </td>
            <td :class="{ [errorClass]: v.$each[i].price.$invalid }">{{ s.price }}</td>
            <td :class="{ [errorClass]: v.$each[i].sumWithoutVAT.$invalid }">{{ s.sumWithoutVAT }}</td>
            <td :class="{ [errorClass]: v.$each[i].vat.$invalid }">{{ s.vat }}</td>
            <td :class="{ [errorClass]: v.$each[i].total.$invalid }">{{ s.total }}</td>
          </tr>
          <tr class="footer">
            <td>Итого</td>
            <td>{{ footer.count }}</td>
            <td></td>
            <td>{{ footer.sumWithoutVAT }}</td>
            <td>{{ footer.vat }}</td>
            <td>{{ footer.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _round from 'lodash/round';
import { getTariff } from '@/store/store';
function cleanFooter() {
  return {
    count: 0,
    sumWithoutVAT: 0.0,
    vat: 0.0,
    total: 0.0,
  };
}
export default {
  data() {
    return {
      footer: cleanFooter(),
    };
  },
  props: {
    value: {
      type: Array,
    },
    vatRate: {
      type: Number,
      required: true,
    },
    serviceSets: {
      type: Array,
      required: true,
    },
    docDate: {
      required: false,
    },
    /**
     * Признак того, что НЕ НУЖНО пересчитывать табличную часть.
     * Используется при загрузке документа, чтобы не перезаписать те
     * значения (возможно, неправильные), которые прилетают их бэкенда
     */
    recalcDisabled: {
      default: true,
    },
    v: {
      type: Object,
      required: true,
    },
    errorClass: {
      type: String,
      default: 'has-error',
    },
  },
  methods: {
    countChanged(l) {
      l.total = _round(l.priceWithVAT * l.count, 2);
      l.vat = _round((l.total / (100.0 + this.vatRate)) * this.vatRate, 2);
      l.sumWithoutVAT = _round(l.total - l.vat, 2);

      this.$emit('input', this.value);
    },
    calcFooter(dontSend) {
      let footer = cleanFooter();
      for (let i in this.value) {
        let line = this.value[i];
        footer.count += line.count;
        footer.sumWithoutVAT += line.sumWithoutVAT;
        footer.vat += line.vat;
        footer.total += line.total;
      }
      footer.count = _round(footer.count);
      footer.sumWithoutVAT = _round(footer.sumWithoutVAT, 2);
      footer.vat = _round(footer.vat, 2);
      footer.total = _round(footer.total, 2);
      this.footer = footer;
      dontSend || this.$emit('changed', footer);
    },
    serviceSetChanged(x) {
      let vm = this;
      let tabular = [];
      for (var i in x.tab) {
        let l = _cloneDeep(x.tab[i].subservice);
        l.count = x.tab[i].count == null ? 1 : x.tab[i].count;
        vm.calcLine(l);
        tabular.push(l);
      }
      vm.$emit('input', tabular);
    },
    calcLine(l) {
      let vm = this;
      let tariff = getTariff(l.id, vm.docDate);
      l.price = _round(tariff.total - tariff.vat, 2);
      l.priceWithVAT = _round(tariff.total, 2);
      l.total = _round(l.priceWithVAT * l.count, 2);
      l.vat = _round((l.total / (100.0 + vm.vatRate)) * vm.vatRate, 2);
      l.sumWithoutVAT = _round(l.total - l.vat, 2);
    },
  },
  computed: {
    serviceSetsFiltered() {
      // Пересчитать набор услуг (их состав может зависеть от даты)
      let docDate = new Date(this.docDate);
      let serviceSetsFiltered = (this.serviceSets || []).filter(
        el => new Date(el.from) <= docDate && docDate <= new Date(el.to)
      );
      return serviceSetsFiltered;
    },
  },
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.calcFooter();
      },
      deep: true,
    },
    docDate() {
      let vm = this;

      // Если включен флажок запрета пересчета - не пересчитывать суммы
      if (vm.recalcDisabled) return;

      // найти все id услуг из serviceSet'а:
      let allowedServiceIds = [];
      for (let serviceSet of vm.serviceSetsFiltered) {
        for (let service of serviceSet.tab) {
          allowedServiceIds.push(service.subservice.id);
        }
      }
      let tabular = [];
      for (var _l of vm.value) {
        let l = _cloneDeep(_l);
        if (!allowedServiceIds.includes(l.id)) continue;
        vm.calcLine(l);
        tabular.push(l);
      }
      vm.$emit('input', tabular);
    },
  },
};
</script>

<style scoped>
tr.footer {
  background-color: beige;
}
</style>
