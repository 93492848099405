import Refuses2 from '@/components/refuses/Refuses2';
import CommonRefusesList from '@/components/refuses/CommonRefusesList';
import EditRefuse2 from '@/components/refuses/edit/EditRefuse2';

export default [
  {
    path: '/refuses',
    icon: 'fa-hand-paper-o',
    component: Refuses2,
    meta: { title: 'Отказы', },
    children: [
      {
        path: 'perms',
        component: CommonRefusesList,
        meta: { title: 'Отказы (разрешения)', },
      },
      {
        path: 'msto',
        component: CommonRefusesList,
        meta: { title: 'Отказы (МСТО)', },
      },
      {
        path: 'ekmt',
        component: CommonRefusesList,
        meta: { title: 'Отказы (ЕКМТ)', },
      },
      {
        path: 'og',
        component: CommonRefusesList,
        meta: { title: 'Отказы (ОГ)', },
      },
    ],
  },
  {
    path: '/editRefuse2/:id?',
    component: EditRefuse2,
    name: 'editRefuse2',
    hidden: true,
    meta: { title: 'Редактирование отказа', },
  },
];
