<template>
  <div>
    <og-list-common :ajax-loader="ajaxLoader" :ajax-params="{}" />
  </div>
</template>

<script>
import OgListCommon from './OgListCommon';
export default {
  data() {
    return {
      ajaxLoader: ajax.ogList,
    };
  },
  components: { OgListCommon },
};
</script>
