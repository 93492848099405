<template>
  <div class="container">
    <div class="fixed-header">
      <span class="fixed-header-title">Карта доп. проверки {{ isNew ? '(новая)' : `№ ${m.id} (редактирование)` }}</span>
      <span class="fa-pull-right">
        <button
          class="btn btn-warning icon-button"
          type="button"
          @click="fillDummyData"
          :disabled="initialErr"
          v-if="debugMode"
        >
          <i class="fa fa-bug fa-2x"></i>
        </button>
        <button
          :disabled="initialErr || $v.m.$invalid"
          class="btn btn-success icon-button"
          @click="print"
          type="button"
          title="Печать"
        >
          <i class="fa fa-print fa-2x"></i>
        </button>
        <button
          :disabled="initialErr || $v.m.$invalid"
          class="btn btn-success icon-button"
          @click="save"
          type="button"
          title="Сохранить"
        >
          <i class="fa fa-save fa-2x"></i>
        </button>
        <button class="btn btn-danger icon-button" @click="$router.go(-1)" type="button" title="Отмена">
          <i class="fa fa-window-close fa-2x"></i>
        </button>
      </span>
    </div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw" />
    </BlockUI>
    <select-kdr
      v-if="showSelectKdr"
      @close="showSelectKdr = false"
      width="900"
      @apply="applyKdr"
      :cn="aux.cn"
      :transfer="true"
    />
    <div v-show="!initialErr" class="under-fixed-header container-fluid">
      <!-- Техпаспорт (только для поиска) -->
      <div class="form-group row">
        <label class="control-label col-sm-3">Техпаспорт (только для поиска)</label>
        <div class="col-sm-9 input-group">
          <input
            v-model="aux.cn"
            class="form-control"
            @input="forceUpper($event, aux, 'cn')"
            type="text"
            autocomplete="off"
            @keyup.enter="$v.aux.cn.$invalid || (showSelectKdr = 1)"
          />
          <span class="input-group-btn">
            <button type="button" class="btn btn-default" @click="showSelectKdr = 1" :disabled="$v.aux.cn.$invalid">
              <span class="fa fa-search" />
            </button>
          </span>
        </div>
      </div>

      <!-- 1. Регистрационный знак -->
      <div class="form-group row">
        <label :class="{ 'has-error': $v.m.rn.$invalid }" class="control-label col-sm-3">1. Госномер</label>
        <div class="col-sm-9 no-pad">
          <input
            v-model="m.rn"
            class="form-control"
            @input="forceUpper($event, m, 'rn')"
            type="text"
            autocomplete="off"
            :disabled="editingDisabled"
          />
        </div>
      </div>

      <!-- 2. Марка -->
      <div class="form-group row">
        <label :class="{ 'has-error': $v.m.m.$invalid }" class="control-label col-sm-3">2. Марка</label>
        <div class="col-sm-9 no-pad">
          <input v-model="m.m" class="form-control" :disabled="editingDisabled" />
        </div>
      </div>

      <!-- 3. VIN -->
      <div class="form-group row">
        <label :class="{ 'has-error': $v.m.vin.$invalid }" class="control-label col-sm-3">3. VIN</label>
        <div class="col-sm-9 no-pad">
          <input
            v-model="m.vin"
            class="form-control"
            @input="forceUpper($event, m, 'vin')"
            autocomplete="off"
            :disabled="editingDisabled"
          />
        </div>
      </div>

      <!-- 4. Год выпуска -->
      <div class="form-group row">
        <label :class="{ 'has-error': $v.m.vY.$invalid }" class="control-label col-sm-3">4. Год выпуска</label>
        <div class="col-sm-9 no-pad">
          <input v-model.number="m.vY" class="form-control" type="number" :disabled="editingDisabled" />
        </div>
      </div>

      <!-- 5. Тип по европейскому соглашению о международной перевозке грузов ДОПОГ -->
      <div class="form-group row">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.des.$invalid }">5. Тип по ДОПОГ</label>
          <div class="icheck-primary icheck-inlined" v-for="des in designations" :key="des.c">
            <input :id="des.n" :checked="!!(m.des & des.c)" @change="m.des = m.des ^ des.c" type="checkbox" :disabled="editingDisabled" />
            <label :for="des.n">{{ des.n }}</label>
          </div>
      </div>

      <!-- 6. Серийный номер цистерны (резервуара): -->
      <div class="form-group row">
        <label class="control-label col-sm-3">6. Серийный № цистерны</label>
        <div class="col-sm-9 no-pad">
          <input v-model="m.tSer" class="form-control" :disabled="editingDisabled" />
        </div>
      </div>

      <!-- 7. Код цистерны (резервуара): -->
      <div class="form-group row">
        <label class="control-label col-sm-3">7. Код цистерны</label>
        <div class="col-sm-9 no-pad">
          <input v-model="m.tCod" class="form-control" :disabled="editingDisabled" />
        </div>
      </div>

      <!-- 8. Год выпуска цистерны (резервуара): -->
      <div class="form-group row">
        <label class="control-label col-sm-3">8. Год цистерны</label>
        <div class="col-sm-9 no-pad">
          <input v-model.number="m.tY" class="form-control" type="number" :disabled="editingDisabled" />
        </div>
      </div>

      <h3 class="text-center" style="text-decoration: underline;">
        10. Проверяемые параметры
      </h3>
      <div class="form-group row" v-for="(paramName, paramIndex) in paramNames" :key="paramIndex">
        <label class="control-label col-sm-8">{{ paramName }}</label>
        <div class="col-sm-4 no-pad">
          <select class="form-control" v-model="m.p[paramIndex]" @change="paramChanged">
            <option v-for="(pn, i) in paramResults" :key="i" :value="i">{{ pn }}</option>
          </select>
        </div>
      </div>

      <!-- 11. Состояние ТС -->
      <div class="form-group row">
        <label class="control-label col-sm-8">11. Состояние ТС</label>
        <div class="col-sm-4 no-pad">
          <select class="form-control" v-model="m.state">
            <option :value="0">Не соответствует</option>
            <option :value="1">Соответствует</option>
          </select>
        </div>
      </div>

      <!-- 12. Дата проведения дополнительной проверки -->
      <div class="form-group row">
        <label :class="{ 'has-error': $v.m.d.$invalid }" class="control-label col-sm-3">12. Дата проверки</label>
        <div class="col-sm-9 no-pad" style="margin-bottom: 0.5em;">
          <rome-datepicker
            v-model="m.d"
            :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
            class="form-control"
            modelFormat="YYYY-MM-DD"
            :disabled="editingDisabled"
          />
        </div>
      </div>

      <!-- 13. Место проведения дополнительной проверки -->
      <div class="form-group row">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.dsId.$invalid }">Место проверки</label>
        <div class="col-sm-9 no-pad">
          <vue-single-select name="dsId" v-model="m.dsId" :options="ds" :disabled="editingDisabled" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forceUpper, randomChars, randomFromRange, editingDisabled, randomDate } from '@/lib/funcs';
import { mapGetters } from 'vuex';
import { required, numeric, between } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { designations, patterns, DocumentGroups, DocumentSubgroups } from '@/lib/consts';
import { assignId } from '@/api/api';
import { sign } from '@/lib/sign';
import { modifiable } from '@/lib/mixins';
import RomeDatepicker from '@/controls/RomeDatepicker';
import SelectKdr from '@/components/SelectKdr';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
let paramNames = [
  '10.1 Наличие и правильность оформления документов',
  '10.2 Электрическая проводка',
  '10.2 Приборы электрооборудования, расположенные позади кабины водителя',
  '10.2 аккумуляторные батареи и их главный выключатель',
  '10.3 наличие антиблокировочной тормозной системы',
  '10.4 топливные баки',
  '10.4 двигатель',
  '10.4 система выпуска отработавших газов',
  '10.5 Дополнительные требования к транспортным средствам типа MEMU и EX',
  '10.6 требования, предъявляемые к цистерне',
  '10.7 снаряжение',
  '10.7 набор ручного инструмента',
  '10.7 дополнительные приспособления',
  '10.7 средства пожаротушения',
];
export default {
  data() {
    return {
      initialErr: false,
      blockUI: false,
      isNew: true,
      paramResults: ['Не соответствует', 'Соответствует', 'Не предусмотрено'],
      paramNames,
      showSelectKdr: false,
      designations,
      aux: {
        cn: null,
      },
      m: {
        // Госномер
        rn: null,
        // Модель строкой
        m: null,
        vin: '',
        // Год выпуска ТС
        vY: null,
        // Обозначение(я) ТС согласно подразделу 9.1.1.2 ДОПОГ.
        des: null,
        // Серийный номер цистерны
        tSer: null,
        // Код цистерны
        tCod: null,
        // Год выпуска цистерны
        tY: null,
        // Результаты проверок массив целых от 0 до 2. 0 - не соответствует, 1 - соответствует, 2 - не предусмотрено конструкцией
        // 14 записей, по умолчанию - соответствует
        p: Array.apply(null, { length: paramNames.length }).map(() => 1),
        // Дата выдачи карты
        d: moment().format('YYYY-MM-DD'),
        dsId: null,
        // id акта
        aId: null,
        // состояние (0/1)
        state: null,
      },
    };
  },
  validations() {
    return {
      aux: {
        cn: { required, pattern: regex(patterns.cn) },
      },
      m: {
        // Госномер
        rn: { required, regex: regex(patterns.combinedRN) },
        // Модель строкой
        m: { required },
        vin: { regex: regex(patterns.vinLooseOrEmpty) },
        // Год выпуска ТС
        vY: { numeric, required, between: between(1940, 2090) },
        // Дата выдачи карты
        d: { required },
        dsId: { required, between: between(100000000, 999999999) },
        state: { required, numeric, between: between(0, 1) },
        des: { required, numeric, between: between(1, 127) },
      },
    };
  },
  components: { VueSingleSelect, RomeDatepicker, SelectKdr },
  computed: {
    ...mapGetters(['debugMode', 'ds', 'userSettings', 'userInfo']),
    editingDisabled() {
      return editingDisabled(this.userInfo, this.m);
    },
  },
  methods: {
    applyKdr(info) {
      let m = this.m;
      let g = info[0];
      this.showSelectKdr = false;
      if (g !== null) {
        m.rn = g.rn;
        m.m = g.model;
        m.vin = g.vin ? g.vin : '';
        m.vY = g.y;
      }
    },
    fillDummyData() {
      this.m = {
        id: this.m.id,
        rn: randomChars(2) + randomFromRange(1000, 9999) + '-' + randomFromRange(1, 7),
        m: randomChars(6),
        vin: randomChars(17),
        vY: randomFromRange(2000, 2020),
        des: 43,
        tSer: randomChars(6),
        tCod: randomChars(6),
        tY: randomFromRange(2000, 2020),
        p: [1, 1, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        d: randomDate(),
        dsId: 251845588,
        aId: null,
        state: 0,
      };
    },
    paramChanged() {
      this.m.state = typeof this.m.p.find(el => el == 0) !== 'undefined' ? 0 : 1;
    },
    // actAction({ op, index }) {
    //   let vm = this;
    //   if (index < 0 || index >= vm.m.actions.length) return;
    //   let action = vm.m.actions[index];
    //   let actionText = action.actionNumber === 0 ? 'выдаче' : 'продлению';
    //   vm.$dlg.modal(op === 0 ? SelectAct : EditAct, {
    //     params: {
    //       initialId: op === 0 ? action.actId : null,
    //       serviceId: 4,
    //     },
    //     width: 800,
    //     height: op === 0 ? 500 : 590,
    //     title: `Акт к ${actionText} сертификата ОГ ${vm.m.number}`,
    //     callback: function(rslt) {
    //       if (rslt) {
    //         action.actId = rslt.actId;
    //         action.actNum = rslt.actNum;
    //       }
    //     },
    //   });
    // },
    forceUpper,
    save() {
      let vm = this;
      vm.blockUI = true;
      assignId(vm.m, DocumentGroups.OG_CARD, DocumentSubgroups.ORIGINAL)
        .then(vm.prepareModelToSend)
        .then(sign)
        .then(ajax.saveOgCard)
        .then(r => {
          console.log(r);
          // vm.m = r.data.content;
          vm.m_ = _cloneDeep(vm.m);
          vm.isNew = false;
        })
        .catch(err => {
          this.$showError(null, err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    print() {
      let vm = this;
      if (vm.isNew || !_isEqual(vm.m, vm.m_)) {
        this.$dlg.alert('Можно печатать только сохраненные карты', {
          messageType: 'warning',
        });
        return;
      }
      window.open(
        `s/certs/ogCard/pdf/${vm.m.id}?topOffset=${vm.userSettings.topOffset}&leftOffset=${vm.userSettings.leftOffset}`,
        '_blank'
      );
    },
    async prepareModelToShow(response) {
      let input = response.data.content;
      let p = [];
      let result = _cloneDeep(input);
      result.state = (input.p >> 30) & 1;
      for (let i = 0, mask = 3; i < paramNames.length; i++, mask <<= 2) {
        p[i] = (input.p >> (i * 2)) & 3;
      }
      result.p = p;
      return result;
    },
    test() {},
    async prepareModelToSend(inputModel) {
      let m = _cloneDeep(inputModel);
      // Упаковать результаты проверки параметров из массива в битовую маску:
      let params = 0;
      for (let i = 0; i < paramNames.length; i++) {
        params |= (m.p[i] & 3) << (i * 2);
      }
      params |= (m.state ? 1 : 0) << 30;
      delete m.state;
      m.p = params;
      return m;
    },
    async send(envelope) {
      return ajax.saveOgCard(envelope);
    },
  },

  mounted() {
    let vm = this;
    let rp = vm.$route.params;
    vm.m.dsId = vm.userSettings.curDsId;
    if (rp.id) {
      // редактирование существующего
      vm.blockUI = true;
      // eslint-disable-next-line
      ajax
        .getOgCard({ id: rp.id })
        .then(vm.prepareModelToShow)
        .then(r => {
          vm.m = r;
          vm.isNew = false;
        })
        .catch(err => {
          vm.initialErr = true;
          vm.$showError(null, err);
        })
        .finally(() => {
          vm.m_ = _cloneDeep(vm.m);
          vm.blockUI = false;
        });
    } else {
      vm.m_ = _cloneDeep(vm.m);
    }
  },
  mixins: [modifiable],
};
</script>
<style scoped>
.icheck-inlined {
  display: inline-block;
  margin-right: 20px !important;
}
a {
  cursor: pointer;
}
</style>
