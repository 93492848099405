import EditOg from '@/components/og/edit/EditOg';
import EditOgCard from '@/components/og/edit/EditOgCard';
import OgList from '@/components/og/OgList';
import OgListSearch from '@/components/og/OgListSearch';
import OgCardList from '@/components/og/OgCardList';
import OgCardListSearch from '@/components/og/OgCardListSearch';
import OgPage from '@/components/og/OgPage';
import BlanksList from '@/components/certs/BlanksList';

export default [
  {
    path: '/ogs',
    component: OgPage,
    children: [
      {
        path: 'blanks',
        component: BlanksList,
        name: 'ogBlanks',
        meta: {
          title: 'Бланки ОГ',
        },
      },
      {
        path: 'certs',
        component: OgList,
        name: 'ogCerts',
        meta: {
          title: 'Свидетельства ОГ',
        },
      },
      {
        path: 'searchOgs',
        component: OgListSearch,
        meta: {
          title: 'Поиск ОГ',
        },
      },
      {
        path: 'cards',
        component: OgCardList,
        meta: {
          title: 'Карты доп. проверки ОГ',
        },
      },
      {
        path: 'searchCards',
        component: OgCardListSearch,
        meta: {
          title: 'Поиск карт доп. проверки ОГ',
        },
      },
    ],
  },
  {
    path: '/editOgCard/:id?',
    component: EditOgCard,
    name: 'editOgCard',
    hidden: true,
    meta: {
      title: 'Редактирование карты доп. проверки',
    },
  },
  {
    path: '/editOg/:certId?',
    name: 'editOg',
    component: EditOg,
    hidden: true,
    meta: {
      title: 'Редактирование ОГ',
    },
  },
];
