<template>
  <div>
    <div>
      <div class="form-signin">
        <h2 style="text-align: center">АРМ «Инженер»</h2>
        <input
          type="text"
          class="form-control"
          placeholder="Пользователь"
          ref="login"
          @keypress.enter="login"
          v-model="m.login"
          :class="{ 'has-error': $v.m.login.$invalid }"
        />
        <input
          type="password"
          class="form-control"
          placeholder="Пароль"
          @keypress.enter="login"
          v-model="m.password"
          :class="{ 'has-error': $v.m.password.$invalid }"
        />
        <button class="btn btn-lg btn-primary btn-block" @click="login" :disabled="$v.m.$invalid">Войти</button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
export default {
  data() {
    return { m: { login: '', password: '' } };
  },
  methods: {
    login() {
      let vm = this;
      if (vm.$v.m.$invalid) return;
      ajax
        .login(vm.m)
        .then(r => {
          vm.$store.commit('login', r.data.content);
          vm.$store.commit('loadStoredData');
          try {
            vm.$router.push({ path: vm.$route.query.returnPath || '/' });
            // eslint-disable-next-line no-empty
          } catch (ign) {}
          let i = r.data.content;
          if ((moment(i.pExpDate) - moment()) / 86400000 < 4) {
            vm.$parent.$emit('passExp', i);
          }
        })
        .catch(e => {
          if (e.errCode == 1002) {
            vm.$router.push({
              path: '/user/changePassword',
              query: { login: vm.m.login, message: 'Истек срок действия пароля' },
            });
          } else {
            vm.$log.error(`code: ${e.errCode}, message: ${e.message}`);
            vm.$showError('Ошибка', e);
          }
        });
    },
  },
  validations() {
    return {
      m: { login: { required, ml: minLength(3) }, password: { required } },
    };
  },
  created() {
    let vm = this;
    vm.$nextTick(() => vm.$refs.login.focus());
  },
};
</script>

<style scoped>
.form-signin {
  margin-right: auto;
  margin-left: auto;
  width: 350px;
}
</style>
