<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      ></div>
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <th />
          <th>Серия</th>
          <th>Номер</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bl, i) in blanks" :key="i" style="cursor: pointer;" @dblclick="issue(bl)">
          <td>
            <div class="btn-group">
              <div class="btn-group">
                <button type="button" class="btn btn-in-bar" @click="issue(bl)">
                  <i class="fa fa-plus-circle fa-2x"></i>
                </button>
              </div>
              <!-- <button type="button" class="btn btn-in-bar" @click="issue(bl)">
                <i class="fa fa-plus-circle fa-2x"></i>
              </button>-->
            </div>
          </td>
          <td>{{ bl.s }}</td>
          <td>{{ fu.numberPad(bl.n, 7) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu from '@/lib/funcs';
export default {
  data() {
    return {
      blockUI: false,
      fu,
      blanks: [],
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 0,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      let vm = this;
      vm.blanks = [];
      vm.blockUI = true;
      let blankType = 2;
      if (vm.$route.fullPath.endsWith('/ekmts/blanks')) blankType = 3;
      if (vm.$route.fullPath.endsWith('/ogs/blanks')) blankType = 4;
      ajax
        .getBlanks({ type: blankType, sk: vm.pageSize * (vm.pg.currentPage - 1), pageSize: vm.pageSize })
        .then(
          r => {
            vm.totalItems = Number(r.data.content[0]);
            vm.firstBlank = r.data.content[1];
            vm.blanks = r.data.content[2];
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    issue(bl) {
      let vm = this;
      if (!bl) return;
      switch (bl.t) {
        case 2:
          vm.$router.push({ name: 'editMsto', params: { bl } });
          break;
        case 3:
        case 6:
          vm.$router.push({ name: 'editEkmt', params: { bl } });
          break;
        case 4:
          vm.$router.push({ name: 'editOg', params: { bl } });
          break;
      }
    },
  },
  watch: {
    '$route.fullPath'(curr, prev) {
      let vm = this;
      vm.pg.currentPage = 1;
      vm.load();
    },
  },
};
</script>
