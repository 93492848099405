<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <!-- <paginate :pageCount="pageCount" :containerClass="'pagination'" :clickHandler="pageChanged" prev-text="<"
    next-text=">" :first-last-button="!0" first-button-text="|<" last-button-text=">|"></paginate>-->
    <!-- <uib-pagination v-model="pagination" :total-items="22"></uib-pagination> -->
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="pageChanged"
        :items-per-page="pageSize"
        :max-size="7"
      />
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <td></td>
          <td>№ заявления</td>
          <td>Дата</td>
          <td>Заявитель</td>
          <td title="Заинтересованное лицо">ЗЛ</td>
          <td>Состояние</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(a, i) in applications" :key="i" @dblclick="open(a.number)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="open(a.number)">
                <i class="fa fa-edit fa-2x" />
              </button>
            </div>
          </td>
          <td>
            {{ a.number }}
          </td>
          <td>{{ a.date | dateTime }}</td>
          <td>{{ a.doer }}</td>
          <td>{{ a.applicant }}</td>
          <td>{{ a.state }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu from '@/lib/funcs';
export default {
  data() {
    return {
      fu,
      blockUI_: 0,
      applications: [],
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 0,
    };
  },
  computed: {
    blockUI() {
      return this.blockUI_ !== 0;
    },
  },
  created() {
    this.getApplicationCount();
    this.pageChanged();
  },
  methods: {
    getApplicationCount() {
      let vm = this;
      vm.blockUI_ |= 2;
      ajaxModular.oais
        .getApplicationCount()
        .then(
          r => {
            vm.totalItems = Number(r.data.content);
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI_ &= 0xfffffffd;
        });
    },
    loadApplications(skip) {
      let vm = this;

      vm.blockUI_ |= 1;
      ajax.oais
        .getApplications({ sk: skip, pageSize: vm.pageSize })
        .then(
          r => {
            vm.applications = r.data.content;
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI_ &= 0xfffffffe;
        });
    },
    pageChanged() {
      this.loadApplications((this.pg.currentPage - 1) * this.pageSize);
    },
    open(number) {
      let vm = this;
      vm.$router.push({ name: 'openOaisApplication', params: { number } });
    },
  },
};
</script>
