<template>
  <!-- <transition name="modal"> -->
  <div class="modal-mask" @click="close($event)" v-show="show">
    <div class="modal-container" :style="{ width: w, left: left }">
      <div class="modal-header">
        <slot name="header">
          <h3>Header</h3>
        </slot>
      </div>
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer text-right">
        <slot name="footer">
          <button class="modal-default-button" @click="close">Close</button>
        </slot>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
import _toInteger from 'lodash/toInteger';
export default {
  data() {
    return {
      w: Math.max(150, _toInteger(this.width)) + 'px',
      left: '10px',
    };
  },
  props: {
    show: {
      type: [Boolean, Number],
      required: false,
      default: 1,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 300,
    },
  },
  methods: {
    close: function(e) {
      if (e === 1 || e.target.className === 'modal-mask') {
        this.$emit('close');
      }
    },
  },
  mounted: function() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code == 'Escape') {
        this.close(1);
      }
    });
    this.left = (window.innerWidth - _toInteger(this.width)) / 2 + 'px';
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  /* height: 800px; */
  margin: 40px auto 0;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  position: absolute;
  top: 10px;
}
.modal-header {
  padding: 5px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  padding: 5px 10px 0px 10px;
  overflow: hidden;
  /* border-color: rgba(0, 0, 0, 0.15);
  border-style: solid;
  border-width: 1px; */
  position: absolute;
  width: 100%;
  height: 45px;
  bottom: 0px;
  left: 0px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

/* .modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
</style>
