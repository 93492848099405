<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div style="height:290px;overflow:auto;">
      <div class="form-group">
        <div class="col-sm-12 input-group">
          <input
            class="form-control"
            type="text"
            v-model="aux.rn"
            @keyup.enter="$v.aux.rn.$invalid || search()"
            @input="forceUpper($event, aux, 'rn')"
            autocomplete="off"
            autofocus
          />
          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-default"
              @click="$v.aux.rn.$invalid || search()"
              :disabled="$v.aux.rn.$invalid"
            >
              <span class="fa fa-search"></span>
            </button>
          </span>
        </div>
      </div>
      <table class="table table-hover table-mc-light-blue table-striped">
        <tbody v-if="$v.i.regNumber.$invalid">
          <tr style="color:red; text-align: center;">
            <td colspan="2">Ничего не найдено</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>Госномер</td>
            <td>{{i.regNumber}}</td>
          </tr>
          <tr>
            <td>Серт. соотв.</td>
            <td>{{i.complianceCert}}</td>
          </tr>
          <tr>
            <td>Модель</td>
            <td>{{i.model}}</td>
          </tr>
          <tr>
            <td>VIN</td>
            <td>{{i.vin}}</td>
          </tr>
          <tr>
            <td>г.в.</td>
            <td>{{i.year}}</td>
          </tr>
          <tr>
            <td>Тип двигателя</td>
            <td>{{i.engineType}}</td>
          </tr>
          <tr>
            <td>Номер двигателя</td>
            <td>{{i.engineNumber}}</td>
          </tr>
          <tr>
            <td>Коэфф. поглощ.</td>
            <td>{{i.absorptionCoefficient}}</td>
          </tr>
          <tr>
            <td>Дата/срок</td>
            <td>{{i.date|date}} {{i.validTo|date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button
          class="btn btn-success footer-btn"
          type="button"
          @click="$emit('close', i)"
          :disabled="$v.i.regNumber.$invalid"
        >Вставить</button>
        <button class="btn footer-btn" type="button" @click="$emit('close')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import { regex } from '@/lib/validators';
import { required } from 'vuelidate/lib/validators';
import { patterns } from '@/lib/consts';
import { clearObjectFields, forceUpper } from '@/lib/funcs';
export default {
  data() {
    return {
      i: {
        regNumber: null,
        complianceCert: '',
        model: null,
        modelId: null,
        date: '',
        validTo: null,
        number: null,
        vin: null,
        year: null,
        engineType: '',
        engineNumber: '',
        absorptionCoefficient: null,
        trailer: false,
        ow: { t: 1, n: '', u: null },
      },
      aux: {
        rn: '',
      },
      blockUI: false
    };
  },
  props: {
    rn: { type: String, required: true },
    ekmtId: { type: Number },
  },
  validations() {
    return {
      aux: { rn: { required, regex: regex(patterns.combinedRN) } },
      i: { regNumber: { required, regex: regex(patterns.combinedRN) } },
    };
  },
  methods: {
    forceUpper,
    search() {
      let vm = this;
      vm.blockUI = true;
      clearObjectFields(vm.i);
      vm.$dlg.closeAll(null, 'toast');
      ajax
        .getExistingEkmt({ rn: vm.aux.rn, ekmtId: vm.ekmtId })
        .then(
          r => {
            Object.assign(vm.i, r.data.content);
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    let vm = this;
    vm.aux.rn = vm.rn;
    vm.search();
  },
};
</script>
