import EkmtPage from '@/components/ekmt/EkmtPage';
import OthersEkmts from '@/components/ekmt/OthersEkmts';
import EditEkmt from '@/components/ekmt/edit/EditEkmt';
import EkmtList from '@/components/ekmt/EkmtList';
import CommonBlanksList from '@/components/CommonBlanksList';

export default [
  {
    path: '/ekmts',
    component: EkmtPage,
    children: [
      {
        path: 'blanks',
        component: CommonBlanksList,
        name: 'ekmtBlanks',
        meta: {
          title: 'Бланки ЕКМТ',
        },
      },
      {
        path: 'certs',
        component: EkmtList,
        name: 'ekmtCerts',
        meta: {
          title: 'ЕКМТ',
        },
      },
      {
        path: 'others',
        component: OthersEkmts,
        meta: {
          title: 'Чужие ЕКМТ',
        },
      },
    ],
  },
  {
    path: '/editEkmt/:certId?',
    name: 'editEkmt',
    component: EditEkmt,
    hidden: true,
    meta: {
      title: 'Редактирование ЕКМТ',
    },
  },
];
