<template>
  <div>
    <button
      class="btn btn-success icon-button up-right-corner"
      type="button"
      title="Создать карту доп. проверки"
      @click="$router.push({ path: '/editOgCard/' })"
    >
      <i class="fa fa-plus fa-2x" />
    </button>
    <og-card-list-common :ajax-loader="ajaxLoader" :ajax-params="{}" />
  </div>
</template>

<script>
import OgCardListCommon from './OgCardListCommon';
export default {
  data() {
    return {
      ajaxLoader: ajax.ogCardList,
    };
  },
  components: { OgCardListCommon },
};
</script>
