<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-6">Найти по №</label>
          <div class="col-sm-6 input-group">
            <input
              class="form-control"
              v-model.number="m.number"
              type="number"
              @keyup.enter="$v.m.number.$invalid || search(0)"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="search(0)" :disabled="$v.m.number.$invalid">
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 no-pad">
        <div class="form-group">
          <label class="control-label col-sm-5">или госномеру</label>
          <div class="col-sm-7 input-group">
            <input
              class="form-control"
              v-model.trim="m.rn"
              @keyup.enter="$v.m.rn.$invalid || search(1)"
              @input="forceUpper($event, m, 'rn')"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="search(1)" :disabled="$v.m.rn.$invalid">
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <og-card-list-common :ajax-loader="ajaxLoader" :ajax-params="ajaxParams" />
    </div>
  </div>
</template>

<script>
import OgCardListCommon from './OgCardListCommon';
import { required, numeric } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
import { patterns } from '@/lib/consts';
export default {
  data() {
    return {
      ajaxLoader: ajax.ogCardListFiltered,
      m: { number: null, rn: null },
      ajaxParams: { number: null, rn: null },
    };
  },
  methods: {
    search(type) {
      if (type) {
        this.ajaxParams = { ...this.m, number: null };
      } else {
        this.ajaxParams = { ...this.m, rn: null };
      }
    },
  },
  components: { OgCardListCommon },
  validations() {
    return {
      m: {
        number: { required, numeric },
        rn: { required, pattern: regex(patterns.combinedRN) },
      },
    };
  },
};
</script>
