<template>
  <div class="container">
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div class="panel panel-danger" v-if="unreadMessages">
      <div class="panel-heading">Почта!</div>
      <div class="panel-body">
        <p>Имеется {{ unreadMessages }} непрочитанных писем</p>
        <button class="btn btn-danger" @click="$router.push({ name: 'mail' })">Перейти к сообщениям</button>
      </div>
    </div>
    <div class="panel panel-primary">
      <div class="panel-heading">Настройки</div>
      <div class="panel-body">
        <form onsubmit="return false;" class="form-horizontal">
          <div class="container-fluid">
            <div class="form-group row">
              <label class="control-label col-sm-4 col-md-3" :class="{ 'has-error': $v.us.curDsId.$invalid }">
                Текущая ДС
              </label>
              <div class="col-sm-8 col-md-9 no-pad">
                <vue-single-select v-model="us.curDsId" :options="activeDs" />
              </div>
            </div>

            <div class="form-group row">
              <label class="control-label col-sm-4 col-md-3" :class="{ 'has-error': $v.us.dcSer.$invalid }">
                Серия бланка ДК по умолчанию
              </label>
              <div class="col-sm-8 col-md-9 no-pad">
                <input class="form-control" type="text" v-model="us.dcSer" @input="forceUpper($event, us, 'dcSer')" />
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-4 col-md-3" :class="{ 'has-error': $v.us.leftOffset.$invalid }">
                Смещение печати слева
              </label>
              <div class="col-sm-8 col-md-9 no-pad">
                <input class="form-control" type="number" v-model="us.leftOffset" min="-4.0" max="4.0" step="0.01" />
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-4 col-md-3" :class="{ 'has-error': $v.us.topOffset.$invalid }">
                Смещение печати сверху
              </label>
              <div class="col-sm-8 col-md-9 no-pad">
                <input class="form-control" type="number" v-model="us.topOffset" min="-4.0" max="4.0" step="0.01" />
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-4 col-md-3" :class="{ 'has-error': $v.us.ogPlace.$invalid }">
                Место выдачи (для ОГ)
              </label>
              <div class="col-sm-8 col-md-9 no-pad">
                <input class="form-control" type="text" v-model="us.ogPlace" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9 no-pad">
                <button class="btn btn-success" type="button" @click="close(1)" :disabled="$v.us.$invalid">
                  <i class="fa fa-save" /> Записать
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="panel panel-primary">
      <div class="panel-heading">Настройки договоров и актов</div>
      <div class="panel-body">
        <div class="container-fluid">
          <!-- № штампа (для разрешений): -->
          <div class="form-group row">
            <label class="control-label col-sm-4 col-md-3">№ штампа</label>
            <div class="col-sm-8 col-md-9 no-pad">
              <input
                title="№ штампа (для разрешений)"
                class="form-control"
                type="number"
                v-model="si.stamp"
                @keyup.enter="putUserSettings"
              />
            </div>
          </div>

          <!-- № личной печати (для ЕКМТ, МСТО, договоров) -->
          <div class="form-group row">
            <label class="control-label col-sm-4 col-md-3">№ личной печати</label>
            <div class="col-sm-8 col-md-9 no-pad">
              <input
                title="№ личной печати (для договоров)"
                class="form-control"
                type="text"
                v-model="si.personalStamp"
                @keyup.enter="putUserSettings"
              />
            </div>
          </div>

          <!-- Основание подписания договоров -->
          <div class="form-group row">
            <label class="control-label col-sm-4 col-md-3">Основание подписания договоров</label>
            <div class="col-sm-8 col-md-9 no-pad">
              <input class="form-control" type="text" v-model="si.ground" @keyup.enter="putUserSettings" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-offset-5 col-md-offset-3 col-sm-7 col-md-9 no-pad">
              <button class="btn btn-success" type="button" @click="putUserSettings">
                <i class="fa fa-save" /> Записать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-warning" @click="loadRefs">Загрузить справочники</button>&nbsp;&nbsp;
    <button type="button" class="btn btn-danger" @click="changePassword">Изменить пароль</button>
  </div>
</template>

<script>
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { mapGetters } from 'vuex';
import { forceUpper } from '@/lib/funcs';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
export default {
  computed: {
    ...mapGetters(['userSettings', 'ds', 'unreadMessages', 'userInfo']),
    activeDs() {
      return this.ds.filter(e => e.v);
    },
  },
  components: { VueSingleSelect },
  data() {
    return {
      blockUI: false,
      us: {
        curDsId: null,
        dcSer: null,
        leftOffset: 0,
        topOffset: 0,
        ogPlace: null,
      },
      si: {
        personalStamp: '',
        stamp: 0,
        ground: '',
      },
    };
  },
  validations() {
    return {
      us: {
        curDsId: { required },
        dcSer: { minl: minLength(2), maxl: maxLength(2) },
        leftOffset: { required, between: between(-4, 4) },
        topOffset: { required, between: between(-4, 4) },
        ogPlace: { required },
      },
    };
  },
  methods: {
    changePassword() {
      this.$router.push({
        path: '/user/changePassword',
        query: { login: this.userInfo.login },
      });
    },
    putUserSettings() {
      let vm = this;
      vm.blockUI = true;
      ajax
        .putUserSettings(vm.si)
        .then(
          r => vm.$dlg.toast('OK', '', { messageType: 'success', closeTime: 3 }),
          err => vm.$showError('error', err)
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    close(r) {
      if (r) {
        let selectedDS = this.ds.find(el => el.id === this.us.curDsId);
        if (selectedDS) this.us.curDsNumber = selectedDS.num;
        this.$store.commit('userSettings', this.us);
      }
      this.$router.push({ path: '/perms/blanks' });
    },
    loadRefs() {
      this.$store.commit('reloadReferences', this);
    },
    forceUpper,
  },
  mounted() {
    let vm = this;
    Object.assign(vm.us, vm.userSettings);
    vm.blockUI = true;
    ajax
      .getUserSettings()
      .then(
        r => {
          vm.si = r.data.content;
        },
        err => vm.$showError('error', err)
      )
      .finally(() => {
        vm.blockUI = false;
      });
  },
};
</script>
