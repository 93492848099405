<template>
  <div>
    <ul class="nav nav-tabs">
      <li :class="{ active: $route.path.endsWith('/applications') }">
        <router-link to="/oais/applications">Заявления</router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { tabbedSubroutes } from '@/lib/mixins';
export default {
  data() {
    return {
      componentName: 'OaisPage',
      defaultSubRoute: 'applications',
    };
  },
  mixins: [tabbedSubroutes],
};
</script>
