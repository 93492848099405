<template>
  <div>
    <!-- Флажок "только созданные мной" -->
    <div class="form-group">
      <label class="control-label col-sm-3" />
      <div class="icheck-primary">
        <input type="checkbox" id="ownCreated" v-model="m.ownCreated" />
        <label for="ownCreated">Только созданные мной</label>
      </div>
    </div>
    <!-- Флажок "только закрытые мной" -->
    <div class="form-group">
      <label class="control-label col-sm-3" />
      <div class="icheck-primary">
        <input type="checkbox" id="ownCompleted" v-model="m.ownCompleted" />
        <label for="ownCompleted">Только закрытые мной</label>
      </div>
    </div>
    <!-- УНП -->
    <div class="form-group">
      <label class="control-label col-sm-3">УНП</label>
      <div class="col-sm-9 input-group">
        <input class="form-control" type="number" v-model.number="m.unp" @keyup.enter="apply" />
      </div>
    </div>

    <!-- Выбор услуги -->
    <div class="form-group">
      <label class="control-label col-sm-3">Услуга</label>
      <div class="col-sm-9 input-group">
        <select class="form-control" v-model="m.serviceId">
          <option :value="null"></option>
          <option v-for="s in services" :key="s.id" :value="s.id">{{ s.name }}</option>
        </select>
      </div>
    </div>

    <!-- Номер -->
    <div class="form-group">
      <label class="control-label col-sm-3">Номер</label>
      <div class="col-sm-9 input-group">
        <input class="form-control" type="text" v-model="m.number" @keyup.enter="apply" />
      </div>
    </div>

    <!-- Период -->
    <div class="form-group">
      <label class="control-label col-sm-3">Период акта</label>
      <!-- <div class="col-sm-9 input-group"> -->
      <div class="col-sm-4 no-left-pad" style="margin-bottom: 0.5em;">
        <rome-datepicker
          v-model="m.from"
          class="form-control"
          :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
          modelFormat="YYYY-MM-DD"
          @keyup.enter="apply"
          placeholder="Начало"
        />
      </div>
      <div class="col-sm-5 no-pad" style="margin-bottom: 0.5em;">
        <rome-datepicker
          v-model="m.to"
          class="form-control"
          :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
          modelFormat="YYYY-MM-DD"
          @keyup.enter="apply"
          placeholder="Конец"
        />
      </div>
      <!-- </div> -->
    </div>

    <!-- Наименование клиента -->
    <div class="form-group">
      <label class="control-label col-sm-3">ФИО/Наименование</label>
      <div class="col-sm-9 input-group">
        <input class="form-control" type="text" v-model="m.name" @keyup.enter="apply" />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn btn-warning footer-btn" type="button" @click="clear">Очистить</button>
        <button class="btn btn-warning footer-btn" type="button" @click="$emit('close')">Отмена</button>
        <button class="btn btn-success footer-btn" type="button" @click="apply">Применить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store/store';
import RomeDatepicker from '@/controls/RomeDatepicker';
import { emptyFilter } from './docsFuncs';
export default {
  data() {
    return {
      m: emptyFilter(),
      services: [],
    };
  },

  props: {
    filter: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    let vm = this;
    vm.services = store.getters.services.filter(e => {
      return e.v;
    });
    vm.m = _cloneDeep(vm.filter);
  },
  methods: {
    apply() {
      this.$emit('close', this.m);
    },
    clear() {
      this.m = emptyFilter();
      this.m.own = true;
    },
  },
  components: { RomeDatepicker },
};
</script>
