<template>
  <div>
    <div style="height:140px;overflow:auto;">
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.unp.$invalid }">УНП</label>
        <div class="col-sm-9 input-group">
          <input class="form-control" type="number" name="unp" v-model.number="m.unp" disabled />
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.name.$invalid }">Наименование</label>
        <div class="col-sm-9 input-group">
          <input class="form-control" type="text" name="name" v-model="m.name" />
        </div>
      </div>
      <!-- Юр. адрес: -->
      <div class="form-group">
        <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.address.$invalid }">Юр. адрес</label>
        <div class="col-sm-9 input-group">
          <input class="form-control" type="text" name="address" v-model="m.address" />
        </div>
      </div>
    </div>
    <div class="row">
      <!-- @click.prevent.stop="$refs.printMenu.open($event)" -->
      <div class="col-xs-12" style="text-align:right">
        <button class="btn btn-success footer-btn" type="button" @click="print(3)" :disabled="$v.m.$invalid">
          <i class="fa fa-print" />Печатать
        </button>
        <button class="btn btn-warning footer-btn" type="button" @click="close">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from 'vuelidate/lib/validators';
import { includes } from '@/lib/validators';
import { resolveUnpInfo } from '@/api/api';
export default {
  data() {
    return {
      m: {
        unp: null,
        name: null,
        address: null,
      },
    };
  },
  validations() {
    // let vm = this
    return {
      m: {
        unp: { required, between: between(100000000, 999999999) },
        name: { required, ml: minLength(6), sw: includes('⚠', true) },
        address: { required, ml: minLength(6) },
      },
    };
  },
  props: {
    unp: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
  },
  methods: {
    print(template) {
      let vm = this;
      vm.$emit('close', vm.m);
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    let vm = this;
    vm.m.unp = vm.unp;
    vm.m.name = vm.name;
    resolveUnpInfo(vm.m.unp).then(({ address }) => {
      vm.m.address = address;
    });
  },
};
</script>
