<template>
  <div>
    <vue-context ref="aMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="del(child.data)">Удалить</a></li>
      </template>
    </vue-context>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      ></div>
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <th />
          <th>№</th>
          <th>Наименование/ФИО</th>
          <th>Дата</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, i) in refuses" :key="r.id" style="cursor: pointer;" @dblclick="edit(r)">
          <td>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-in-bar"
                @click.prevent.stop="$refs.aMenu.open($event, { ...r, index: i })"
              >
                <i class="fa fa-bars fa-2x"></i>
              </button>
            </div>
          </td>
          <td>O-{{ r.id }}</td>
          <td>{{ r.name }}</td>
          <td>{{ r.date | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu from '@/lib/funcs';
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      blockUI: false,
      fu,
      refuses: [],
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 10000,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      let vm = this;
      vm.refuses = [];
      vm.blockUI = true;
      let x = vm.$route.fullPath.split('/').pop();
      let admType = 1;
      switch (x) {
        case 'msto':
          admType = 2;
          break;
        case 'ekmt':
          admType = 3;
          break;
        case 'og':
          admType = 4;
          break;
      }
      ajax
        .getRefuses2({ type: admType, sk: vm.pageSize * (vm.pg.currentPage - 1), pageSize: vm.pageSize })
        .then(
          r => {
            vm.totalItems = vm.calculateTotalItems(r.data.content[0]);
            vm.refuses = r.data.content[1];
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    calculateTotalItems(itemsCountFromServer) {
      let vm = this;
      if (itemsCountFromServer != -1) return itemsCountFromServer;
      return vm.totalItems;
    },
    edit(r) {
      this.$router.push({ name: 'editRefuse2', params: { id: r.id } });
    },
    del(refuse) {
      let vm = this;
      vm.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно хотите удалить отказ O-${refuse.id} от ${Vue.filter('date')(refuse.date)}?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b !== 2) return;
          vm.blockUI = true;
          sign(refuse.id)
            .then(d => ajax.delRefuse2({ id: refuse.id, s: d.s }))
            .then(r => {
              vm.$dlg.toast('Удалено', { messageType: 'info', closeTime: 3 });
              vm.refuses.splice(refuse.index, 1);
            })
            .catch(err => {
              vm.$showError(null, err);
            })
            .finally(() => {
              vm.blockUI = false;
            });
        },
      });
    },
  },
  watch: {
    '$route.fullPath'(curr, prev) {
      let vm = this;
      vm.pg.currentPage = 1;
      vm.totalItems = 10000;
      vm.load();
    },
  },
};
</script>
