import ActsList from '@/components/docs/ActsList';
import ContractsList from '@/components/docs/ContractsList';
import CAsList from '@/components/docs/CAsList';

export default [
  {
    path: '/acts',
    component: ActsList,
    hidden: false,
    meta: {
      label: 'А',
      title: 'Акты',
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: ContractsList,
    hidden: true,
    meta: {
      label: 'Д',
      title: 'Договоры',
    },
  },
  {
    path: '/cas',
    name: 'cas',
    component: CAsList,
    hidden: true,
    meta: {
      label: 'ДА',
      title: 'Договоро-акты',
    },
  },
];
