<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-4" />
      <div class="btn-group col-sm-8 no-pad">
        <button
          v-for="c in formsOfOwnership"
          :key="c.id"
          type="button"
          class="btn btn-secondary"
          :class="{ 'btn-success': c.id == local.t }"
          @click="updateNumeric('t', c.id)"
          :disabled="disabled"
        >
          {{ c.name }}
        </button>
      </div>
    </div>
    <div class="form-group row" v-show="local.t != 0">
      <label class="control-label col-sm-4" :class="{ [errorClass]: v.u.$invalid }"
        >УНП<span v-show="showBranches" :class="{ [errorClass]: v.b.$invalid }">/КОП</span></label
      >
      <div class="no-pad" :class="{ 'col-sm-2': showBranches, 'col-sm-8': !showBranches }">
        <input @input="updateUnp($event.target.value)" type="text" :value="local.u" class="form-control" :disabled="disabled" ref="unp" />
      </div>
      <div class="col-sm-6 no-pad" v-show="showBranches">
        <select
          class="form-control"
          :value="local.b"
          @change="updateBranch($event.target.value)"
          :key="showBranchesKey"
          :class="{ [errorClass]: v.b.$invalid }"
        >
          <option v-for="b in branches" :key="b.b" :value="b.b" :title="b.b">{{
            b.b == -1 ? b.n : numberPad(b.b, 4) + ' ' + b.n
          }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-sm-4" :class="{ [errorClass]: v.n.$invalid }">{{ local.t == 0 ? 'ФИО' : 'Название' }}</label>
      <div class="col-sm-8 no-pad">
        <input @input="update('n', $event.target.value)" type="text" :value="local.n" class="form-control" :disabled="disabled || nameDisabled" ref="name" />
      </div>
    </div>
  </div>
</template>

<script>
import { resolveUnpInfo } from '@/api/api';
import { freshClient } from '@/lib/funcs';
import _isEmpty from 'lodash/isEmpty';
export default {
  data() {
    return {
      showBranchesKey: 0,
      nameDisabled: false,
      branches: {},
    };
  },
  props: {
    value: {
      type: Object,
    },
    v: {
      type: Object,
      required: true,
    },
    formsOfOwnership: {
      type: Array,
      default() {
        return [{ id: 0, name: 'ФЛ' }, { id: 1, name: 'ЮЛ' }, { id: 2, name: 'ИП' }];
      },
    },
    errorClass: {
      type: String,
      default: 'has-error',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useBranches: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    local() {
      return this.value ? this.value : freshClient();
    },
    showBranches() {
      return this.useBranches && this.branches && !_isEmpty(this.branches);
    },
  },

  methods: {
    setFocus() {
      if (this.value.t == 0) {
        this.$refs.name.focus();
      } else {
        this.$refs.unp.focus();
      }
    },
    updateBranches() {
      let vm = this;
      if (!vm.useBranches) return;
      if (vm.value.t != 0 && !vm.v.u.$invalid) {
        resolveUnpInfo(vm.value.u).then(info => {
          vm.branches = info.branches;
          vm.showBranchesKey++;
        });
      }
    },
    getUnpInfo() {
      let vm = this;
      let unp = vm.local.u;
      vm.value.b = null;
      vm.branches = null;
      if (vm.value.t == 0 || vm.v.u.$invalid) {
        return;
      }
      vm.nameDisabled = true;
      resolveUnpInfo(unp).then(info => {
        let newData = { ...vm.local };
        newData.b = null;
        vm.branches = info.branches;
        newData.n = info.name;
        vm.$emit('input', newData);
        vm.$emit('address', info.address);
        vm.showBranchesKey++;
      }).finally(() => { vm.nameDisabled = false; });
    },
    numberPad(num, padSize) {
      return (1e9 + num + '').slice(-padSize);
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    },
    updateNumeric(key, value) {
      if (isNaN(value)) return;
      this.$emit('input', { ...this.local, [key]: value == null ? null : Number(value) });
    },
    updateBranch(value) {
      if (isNaN(value)) return;
      let b = value == null ? null : Number(value);
      let client = b == null ? null : this.branches.find(x => x.b == b);
      let n = (client) ? client.n : '';
      this.$emit('input', { ...this.local, b, n });
      this.$emit('branchSelected', { ...this.local, b, n, a: client.a });
    },
    updateUnp(newValue) {
      let vm = this;
      vm.updateNumeric('u', newValue);
      vm.$nextTick(() => {
        vm.getUnpInfo();
      });
    },
  },
  watch: {
    'value.u'(val, old) {
      this.updateBranches();
    },
  },
};
</script>
