<template>
  <div>
    <!-- <div class="row">
      <div class="col-sm-2">
        <label class="control-label">Тип</label>
      </div>
      <div class="col-sm-2">
        <label class="control-label">Дата</label>
      </div>
      <div class="col-sm-2">
        <label class="control-label">Срок</label>
      </div>
      <div class="col-sm-3">
        <label class="control-label">Инженер</label>
      </div>
      <div class="col-sm-3">
        <label class="control-label">Акт</label>
      </div>
    </div>-->
    <certificate-action
      @actAction="$emit('actAction', { op: $event, index: i })"
      v-model="local[i]"
      v-for="(a, i) in local"
      :key="i"
      :v="v.$each[i]"
      :validToInputFormat="validToInputFormat"
      :dsList="ds"
      :disabled="actionDisabled(a)"
      :showPlace="certType == 4"
      :printCallback="printCallback"
      @del="delAction(i)"
    />
    <!-- @actAction="$emit('actAction', {op: $event, index:i})"
      @del="delAction(i)"
      @save="save(i)"
      :disabled="false"
    :v="local.$each[i]"-->
    <button @click="prolong" class="btn btn-default">Продлить</button>
  </div>
</template>

<script>
import CertificateAction from './CertificateAction';
import ConfirmDialogVue from '@/controls/ConfirmDialog.vue';
import { freshCertificateAction, editingDisabled, russianDate } from '@/lib/funcs';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    local() {
      let a = this.value || [];
      return a;
    },
    ...mapGetters(['ds', 'userSettings', 'userInfo']),
  },
  components: { CertificateAction },
  props: {
    value: {
      type: Array,
      required: true,
    },
    prolongPeriod: {
      type: Number,
      default: 12,
    },
    v: {
      type: Object,
      required: true,
    },
    validToInputFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    certType: {
      type: Number,
      default: -1,
    },
    /**
     * Callback для печати продления. Если null или undefined, кнопка печати будет скрыта.
     * получает на входе объект CertificateAction (см. CertificateAction.vue)
     */
    printCallback: {
      type: Function,
      default: undefined,
    },
  },
  methods: {
    actionDisabled(a) {
      return editingDisabled(this.userInfo, a);
    },
    prolong() {
      let m = this.local;
      let maxValidTo = m.map(o => o.validTo).reduce((prev, current) => (prev > current ? prev : current));
      let maxActionNumber = m.reduce((max, next) => (next.actionNumber <= max ? max : next.actionNumber), 0);
      let d = moment(maxValidTo).add(1, 'd');
      let date = d.format('YYYY-MM-DD');
      let validTo = d
        .add(this.prolongPeriod, 'M')
        .add(-1, 'd')
        .format('YYYY-MM-DD');
      let result = {
        ...freshCertificateAction(this.userInfo, this.userSettings),
        date,
        validTo,
        actionNumber: maxActionNumber + 1,
      };
      switch (this.certType) {
        case 4:
          result.place = this.userSettings.ogPlace;
          break;
        default:
          break;
      }
      m.push(result);
    },
    delAction(index) {
      let vm = this;
      let len = vm.local.length;
      if (index >= len) return;
      let msg = '';
      if (index < len - 1) {
        msg = 'Можно удалять только последнее действие';
      }
      let action = vm.local[index];
      if (action.actionNumber == 0) {
        msg = 'Нельзя удалять действие "Выдача"';
      }
      if (msg) {
        vm.$dlg.alert(msg, { messageType: 'warning' });
        return;
      }
      vm.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно хотите удалить продление от ${russianDate(action.date)} до ${russianDate(
            action.validTo
          )} (${action.userName})?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            vm.$emit('input', vm.value.slice(0, index));
          }
        },
      });
    },
  },
};
</script>
