<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="ctMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="print(child.data)">Печатать</a></li>
        <li><a @click.prevent="del(child.data, false)">Удалить</a></li>
        <li><a @click.prevent="del(child.data, true)">Забраковать</a></li>
      </template>
    </vue-context>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      />
    </div>
    <table class="table table-hover table-mc-light-blue table-striped" v-show="!blockUI">
      <thead>
        <tr>
          <td></td>
          <td>№</td>
          <td>Дата</td>
          <td>Срок</td>
          <td>№ ДК</td>
          <td>Госномер</td>
          <td>Собственник</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(c, i) in mstos" :key="i" @dblclick="edit(c)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click.prevent.stop="$refs.ctMenu.open($event, c)">
                <i class="fa fa-bars fa-2x" />
              </button>
            </div>
          </td>
          <td>{{ c.number }}</td>
          <td>{{ c.date | date }}</td>
          <td>{{ c.validTo | date('MM/YYYY') }}</td>
          <td>{{ c.card }}</td>
          <td>{{ c.regNumber }}</td>
          <td>{{ c.owner }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { mapGetters } from 'vuex';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 20,
      totalItems: 100,
      blockUI: false,
      mstos: [],
    };
  },
  props: {
    /**
     * Сюда передается объект-загрузчик vue-resource
     */
    ajaxLoader: {
      type: Function,
      required: true,
    },
    /**
     * условия поиска для объекта-загрузчика vue-resource(если есть).
     * В этом компоненте к условиям поиска добавится информация о пагинации
     */
    ajaxParams: {
      type: Object,
      required: true,
    },
  },
  computed: mapGetters(['userSettings']),
  methods: {
    edit(c) {
      this.$router.push({ name: 'editMsto', params: { mstoId: c.id } });
    },
    print(c) {
      window.open(`s/certs/mstoPdf2/${c.id}?topOffset=${this.userSettings.topOffset}&leftOffset=${this.userSettings.leftOffset}`, '_blank');
    },
    del(c, trash) {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      let message = trash
        ? `Действительно забраковать бланк МСТО ${c.number}? Информация о сертификате будет удалена, а бланк получит статус "Забракован"`
        : `Действительно удалить МСТО ${c.number}? Информация о сертификате будет удалена, а бланк вернется в список чистых`;
      vm.$dlg.modal(ConfirmDialogVue, {
        params: { message, buttons: 6 },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b !== 2) return;
          vm.blockUI = true;
          sign(c.id)
            .then(d => ajax.deleteMsto({ certId: c.id, waste: !!trash, s: d.s }))
            .then(r => {
              vm.load();
              vm.$dlg.toast(trash ? 'Забракован' : 'Удален', '', { messageType: 'success', closeTime: 3 });
            })
            .catch(err => {
              vm.$showError('Ошибка при удалении', err);
            })
            .finally(() => {
              vm.blockUI = false;
            });
        },
      });
    },
    load() {
      let vm = this;
      vm.blockUI = true;
      vm.mstos = [];
      vm.ajaxLoader({ ...vm.ajaxParams, page: vm.pg.currentPage, pageSize: vm.pageSize })
        .then(
          r => {
            vm.mstos = r.data.content[0];
            vm.totalItems = r.data.content[1];
          },
          err => {
            vm.$showError('Ошибка загрузки', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    if (Object.keys(this.ajaxParams).length == 0) {
      this.load();
    }
  },
  watch: {
    ajaxLoader: function(val, oldVal) {
      this.load();
    },
    ajaxParams: {
      handler: function(val, oldVal) {
        this.$log.debug('ajaxParams changed: ', oldVal, val);
        this.load();
      },
      deep: true,
    },
  },
};
</script>
