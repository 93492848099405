<template>
  <div>
    <div class="fixed-header">
      <span class="fixed-header-title">{{ title }}</span>
      <span class="fa-pull-right">
        <button
          class="btn btn-success icon-button"
          type="button"
          title="Создать договор"
          @click.prevent.stop="$refs.creationMenu.open($event)"
        >
          <i class="fa fa-plus fa-2x"></i>
        </button>
        <button class="btn btn-success icon-button" type="button" @click="showFilter">
          <i class="fa fa-filter fa-2x"></i>
        </button>
      </span>
    </div>

    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="creationMenu">
      <template slot-scope="child">
        <li v-for="s in validServices" :key="s.id">
          <a @click.prevent="addContract(s.id)">{{ s.name }}</a>
        </li>
      </template>
    </vue-context>
    <vue-context ref="ctMenu">
      <template slot-scope="child" v-if="child.data">
        <li><a @click.prevent="edit(child.data)">Редактировать</a></li>
        <li><a @click.prevent="print(child.data)">Печатать</a></li>
        <li><a @click.prevent="addAct(child.data)">Выдать акт</a></li>
      </template>
    </vue-context>
    <div class="under-fixed-header">
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      />
      <h2 v-if="wrongFilter">Для отображения договоров задайте фильтр</h2>
      <table v-else class="table table-hover table-mc-light-blue">
        <thead>
          <tr style="border-bottom-style:hidden;">
            <td rowspan="2" width="40px"></td>
            <td>№</td>
            <td>УНП</td>
            <td>Услуга</td>
          </tr>
          <tr>
            <td>Дата</td>
            <td colspan="2">Организация</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="c in contracts">
            <tr style="border-bottom-style:hidden;">
              <td rowspan="2">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-in-bar btn-success"
                    @click.prevent.stop="$refs.ctMenu.open($event, c)"
                  >
                    <i class="fa fa-bars"></i>
                  </button>
                </div>
              </td>
              <td>{{ c.number }}</td>
              <td>{{ c.unp }}</td>
              <td>{{ allServices[c.serviceId] }}</td>
            </tr>
            <tr>
              <td>{{ c.date | date }}</td>
              <td colspan="2">{{ c.name }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { countNonBlanksFields, loadComponentState, storeComponentState } from '@/lib/funcs';
import { describeFilter, filterToURI, isFilterEmpty, emptyFilter } from './docsFuncs';
import FilterDialog from './FilterDialog';
import { mapGetters } from 'vuex';
import EditContract from './EditContract';
import EditAct from './EditAct';
const stateName = 'ContractsList';
export default {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 20,
      totalItems: 100,
      blockUI: false,
      // фильтр для отбора объектов:
      filter: emptyFilter(),
      contracts: [],
      allServices: {},
      validServices: [],
    };
  },
  methods: {
    print(c) {
      window.open('s/docs/contractAsPDF/' + c.contractId, '_blank');
    },
    addAct(c) {
      let vm = this;
      vm.$dlg.modal(EditAct, {
        params: {
          initialId: null,
          serviceId: c.serviceId,
          initialContractId: c.contractId,
        },
        width: 800,
        height: 500,
        title: 'Акт к договору ' + c.number,
        callback: function(rslt) {},
      });
    },
    edit(c) {
      this.addOrEditContract(c.serviceId, c.contractId);
    },
    addContract(serviceId) {
      this.addOrEditContract(serviceId);
    },
    addOrEditContract(serviceId, initialId) {
      let vm = this;
      vm.$dlg.modal(EditContract, {
        params: { serviceId, initialId },
        width: 800,
        height: 500,
        title: (initialId ? 'Договор ' : 'Новый договор ') + vm.allServices[serviceId],
        callback: x => {
          console.log('addOrEditContract complete ', x);
          x && vm.load();
        },
      });
    },
    applyFilter(f) {
      if (f) {
        let vm = this;
        vm.filter = f;
        vm.$router.push({ path: '/contracts' + filterToURI(vm.filter) });
      }
    },

    load() {
      let vm = this;
      if (vm.wrongFilter) return;
      vm.blockUI = true;
      vm.contracts = [];
      ajax
        .contractsFilter(
          Object.assign({}, vm.filter, {
            sk: (vm.pg.currentPage - 1) * vm.pageSize,
            l: vm.pageSize,
          })
        )
        .then(
          r => {
            vm.contracts = r.data.content[0];
            vm.totalItems = r.data.content[1];
          },
          err => {
            vm.$showError('Ошибка загрузки', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    showFilter() {
      let vm = this;
      vm.$log.debug(vm.filter);

      vm.$dlg.modal(FilterDialog, {
        params: {
          filter: vm.filter,
        },
        width: 600,
        height: 320,
        title: 'Фильтр договоров',
        callback: vm.applyFilter,
      });
    },
  },
  computed: {
    ...mapGetters(['debugMode']),
    title() {
      return describeFilter('Договоры', this.filter);
    },
    wrongFilter() {
      return isFilterEmpty(this.filter);
    },
    ...mapGetters({ servicesArray: 'services' }),
  },
  mounted() {
    let vm = this;
    vm.servicesArray.forEach(e => {
      vm.allServices[e.id] = e.name;
      e.v && vm.validServices.push(e);
    });

    if (countNonBlanksFields(this.$route.query)) {
      vm.filter = Object.assign(vm.filter, vm.$route.query);
      //   // Если фильтр пришел снаружи, то его не будем сохранять в localStore
      //   this.storeFilter = false
    } else {
      vm.filter = loadComponentState(stateName, vm.filter);
    }
    vm.load();
  },
  watch: {
    '$route.query'(curr, prev) {
      let vm = this;
      vm.filter = Object.assign(vm.filter, curr);
      vm.load();
    },
  },
  beforeRouteLeave(to, from, next) {
    // вызывается перед переходом от пути, соответствующего текущему компоненту
    // имеет доступ к контексту экземпляра компонента `this`.
    storeComponentState(stateName, this.filter);
    next();
  },
};
</script>

<style></style>
