<template>
  <div>
    <div class="fixed-header">
      <span class="fixed-header-title" v-if="m.id">
        Заявление на внесение изменений информации № {{ m.id }} от {{ russianDate(m.regDate) }}</span
      >
      <span class="fixed-header-title" v-else> Новое заявление на внесение изменений информации</span>
      <span class="fa-pull-right">
        <button class="btn btn-success icon-button" type="button" @click="help">
          <i class="fa fa-question-circle fa-2x"></i>
        </button>
        <button
          class="btn btn-success icon-button"
          type="button"
          title="Сохранить"
          @click="save()"
          :disabled="initialFetchFailure || $v.m.$invalid"
        >
          <i class="fa fa-save fa-2x"></i>
        </button>
        <button class="btn btn-danger icon-button" type="button" title="Отмена" @click="$router.go(-1)">
          <i class="fa fa-window-close fa-2x"></i>
        </button>
      </span>
    </div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw" />
    </BlockUI>
    <!-- 2615673582 ETA000271293 -->
    <select-erip-payment
      v-if="showSelectEripPayment"
      @close="showSelectEripPayment = false"
      width="800"
      :allowed-services="[16]"
      :showInsertButton="false"
      :initialSearchTerm="m.cn"
    />
    <select-webpay-payment
      v-if="showSelectWebpayPayment"
      @close="showSelectWebpayPayment = false"
      width="800"
      :allowed-services="[13]"
      :showInsertButton="false"
      :initialSearchTerm="m.cn"
    />
    <div class="under-fixed-header container" v-show="!initialFetchFailure">
      <div class="alert alert-info row" v-if="m.cDate">Работы по этому заявлению завершены. Изменять его нельзя</div>
      <div class="panel panel-default row" v-else>
        <div class="panel-heading text-center">Шаг 1. Проверка платежа ЕРИП или Webpay</div>
        <div class="panel-body">
          <div class="form-group">
            <div class="col-sm-8">
              <button type="button" class="btn btn-default" @click="showSelectEripPayment = true">
                ЕРИП
              </button>
              &nbsp;&nbsp;&nbsp;
              <button type="button" class="btn btn-default" @click="showSelectWebpayPayment = true">
                Webpay
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default row">
        <div class="panel-heading text-center">Шаг 2. Регистрация заявления</div>
        <div class="panel-body">
          <div class="container-fluid">
            <form novalidate onsubmit="return false;">
              <fieldset :disabled="m.cDate">
                <client-component v-model="m.client" :v="$v.m.client" />
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.regDate.$invalid }">Дата</label>
                  <div class="col-sm-8 no-pad" style="margin-bottom: 0.5em;">
                    <rome-datepicker
                      v-model="m.regDate"
                      class="form-control"
                      :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
                      modelFormat="YYYY-MM-DD"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.dsId.$invalid }"
                    >Место регистрации</label
                  >
                  <div class="col-sm-8 no-pad">
                    <vue-single-select name="dsId" v-model="m.dsId" :options="ds" :disabled="!!m.cDate" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.cn.$invalid }"
                    >Номер техпаспорта</label
                  >
                  <div class="col-sm-8 no-pad">
                    <input type="text" v-model="m.cn" class="form-control" @input="forceUpper($event, m, 'cn')" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.pn.$invalid }"
                    >Номер разрешения из заявления</label
                  >
                  <div class="col-sm-8 no-pad">
                    <input type="text" v-model="m.pn" class="form-control" />
                  </div>
                </div>
                <div class="form-group row" v-show="m.cDate && !m.rrId">
                  <label class="control-label col-sm-4">Номер оригинального бланка</label>
                  <div class="col-sm-8 no-pad">
                    <input type="text" v-model="m.opn" class="form-control" />
                  </div>
                </div>
                <div class="form-group row" v-show="m.cDate && !m.rrId">
                  <label class="control-label col-sm-4">Номер нового бланка</label>
                  <div class="col-sm-8 no-pad">
                    <input type="text" v-model="m.tpn" class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.tr.$invalid }"
                    >Причина переноса</label
                  >
                  <div class="icheck-primary">
                    <input type="checkbox" id="lostOrDamaged" v-model="m.tr.lostOrDamaged" />
                    <label for="lostOrDamaged">Повреждение или утеря</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="icheck-primary col-sm-offset-4">
                    <input type="checkbox" id="ownerChanged" v-model="m.tr.ownerChanged" />
                    <label for="ownerChanged">Смена собственника</label>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="icheck-primary col-sm-offset-4">
                    <input type="checkbox" id="rnChanged" v-model="m.tr.rnChanged" />
                    <label for="rnChanged">Смена госномера</label>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.rrId.$invalid }"
                    >Причина отказа</label
                  >
                  <div class="col-sm-8 no-pad">
                    <select class="form-control" v-model="m.rrId">
                      <option v-for="r in refuseReasons" :key="r.id" :value="r.id">{{ r.name }}</option>
                    </select>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div class="panel panel-default row" v-show="m && !m.cDate && m.id && !m.rrId">
        <div class="panel-heading text-center">Шаг 3. Поиск разрешения для переноса</div>
        <div class="panel-body">
          <form class="form-inline" onsubmit="return false;">
            <div class="form-group">
              <div
                class="input-group col-xs-6"
                v-for="(o, i) in [
                  { n: 'pn', t: 'Разрешение' },
                  { n: 'vin', t: 'VIN' },
                  { n: 'cn', t: 'Техпаспорт' },
                  { n: 'rn', t: 'Госномер' },
                ]"
                :key="i"
              >
                <input
                  :placeholder="o.t"
                  type="text"
                  v-model="s[o.n]"
                  :name="o.n"
                  class="form-control"
                  :id="o.n"
                  @keyup.enter="$v.s[o.n].$invalid || search(o.n)"
                  @input="forceUpper($event, s, o.n)"
                />
                <span class="input-group-btn">
                  <button type="button" class="btn btn-default" @click="search(o.n)" :disabled="$v.s[o.n].$invalid">
                    <span class="fa fa-search"></span>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <table class="table table-hover table-mc-light-blue table-striped">
            <thead>
              <tr>
                <th>
                  № разрешения
                  <br />Госномер
                </th>
                <th>
                  Дата выдачи
                  <br />Срок действия
                </th>
                <th>
                  vin (разрешение)
                  <br />vin (КДР)
                </th>
                <th>
                  Модель
                  <br />год
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="permResults && permResults.length === 0">
              <tr>
                <td class="text-center red-text" colspan="4" style="padding-top:20px;">{{ searchDescription }}</td>
              </tr>
            </tbody>
            <tbody v-if="permResults && permResults.length > 0">
              <tr v-for="(p, i) in permResults" :key="i">
                <td>
                  {{ p.num }}
                  <span v-if="isTransfer(p.fl)" class="fa-stack fa-lg">
                    <i class="fa fa-arrows-h fa-stack-1x"></i>
                  </span>
                  <br />
                  {{ p.rn }}
                </td>
                <td>
                  {{ russianDate(p.pDate) }}
                  <br />
                  {{ russianDate(p.validTo) }} ({{ isTransfer(p.fl) ? '-' : p.p }})
                </td>
                <td>
                  {{ p.vin ? p.vin : 'не указан' }}
                  <br />
                  {{ p.vinKdr ? p.vinKdr : 'не указан' }}
                </td>
                <td>
                  {{ p.model }}
                  <br />
                  {{ p.y }}
                </td>
                <td>
                  <button
                    class="btn btn-default"
                    type="button"
                    :disabled="cannotTransfer(p)"
                    @click="
                      transfer(p);
                      $event.stopPropagation();
                    "
                  >
                    {{ describeStatus(p) }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forceUpper, russianDate, isTransfer, freshClient } from '@/lib/funcs';
import { patterns, ta2Reasons } from '@/lib/consts';
import ClientComponent from '@/controls/ClientComponent';
import SelectEripPayment from '@/components/payments/SelectEripPayment';
import SelectWebpayPayment from '@/components/payments/SelectWebpayPayment';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { mapGetters } from 'vuex';
import ConfirmDialogVue from '@/controls/ConfirmDialog.vue';
import { required, minValue, minLength, maxLength, integer } from 'vuelidate/lib/validators';
import { regex, clientValidation, atLeastOneTrueValidator } from '@/lib/validators';
import RomeDatepicker from '@/controls/RomeDatepicker';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      showSelectEripPayment: false,
      showSelectWebpayPayment: false,
      permResults: null,
      patterns,
      ta2Reasons,
      blockUI: false,
      initialFetchFailure: false,
      searchDescription: null,
      refuseReasons: [
        { id: null, name: '--- нет ---' },
        { id: 1, name: 'Разрешение не найдено' },
        { id: 2, name: 'Истёк срок действия разрешения' },
        { id: 3, name: 'Отсутствие оплаты' },
        { id: 4, name: 'Не представлены необходимые документы' },
      ],
      s: {
        pn: null,
        vin: null,
        cn: null,
        rn: null,
      },
      m: {
        id: null,
        client: freshClient(),
        pn: '',
        cn: '',
        tr: {
          lostOrDamaged: false,
          ownerChanged: false,
          rnChanged: false,
        },
        rrId: null,
        regDate: moment().format('YYYY-MM-DD'),
        dsId: null,
        cDate: null,
        oPermId: null,
        tPermId: null,
        opn: null,
        tpn: null,
      },
    };
  },
  validations() {
    return {
      m: {
        cn: { required, regex: regex(patterns.cn) },
        pn: { regex: regex(patterns.pn) },
        tr: { required, atLeastOneTrueValidator },
        rrId: { minValue: minValue(1), integer },
        regDate: { required },
        client: clientValidation(),
        dsId: { required },
      },
      s: {
        pn: { required, regex: regex(patterns.pn) },
        vin: { required, maxLength: maxLength(22), minLength: minLength(1) },
        cn: { required, regex: regex(patterns.cn) },
        rn: { required, regex: regex(patterns.combinedRN) },
      },
    };
  },
  methods: {
    help() {
      window.open('help/ta3.html', '_blank');
    },
    forceUpper,
    russianDate,
    isTransfer,
    search(type) {
      let vm = this;
      vm.blockUI = true;
      vm.searchDescription = '';
      vm.permResults = [];
      ajax
        .searchPerms4Ta({ type, term: vm.s[type] })
        .then(
          r => {
            vm.permResults = r.data.content;
            if (vm.permResults.length == 0) vm.searchDescription = r.data.message;
          },
          err => {
            vm.searchDescription = 'Ошибка поиска';
            vm.$showError('Ошибка поиска', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    cannotTransfer(p) {
      return (
        (p.fl & (128 | 512 | 1024)) != 0 ||
        this.$v.m.$invalid ||
        this.m.rrId ||
        moment(p.validTo) < moment()
      );
    },
    describeStatus(p) {
      let fl = p.fl;
      if ((fl & 128) !== 0) {
        return 'Уже перенесено';
      }
      if ((fl & 512) !== 0) {
        return 'Аннулировано';
      }
      if ((fl & 1024) !== 0 || moment(p.validTo) < moment()) {
        return 'Недействительно';
      }
      return 'Перенести';
    },
    transfer(p) {
      let vm = this;
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: `Действительно внести изменения информации в разрешение № ${p.num}?`,
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            vm.save(function() {
              vm.$router.push({
                name: 'editPerm',
                params: { guid: p.guid, transferType: 3, cn: vm.m.cn, applId: vm.m.id, blankNum: p.num },
              });
            });
          }
        },
      });
    },
    register() {
      let vm = this;
      if (!_isEqual(vm.m, vm.m_)) {
        vm.save();
      }
    },
    save(cba) {
      let vm = this;
      vm.blockUI = true;
      vm.$dlg.closeAll(null, 'toast');
      sign(vm.m)
        .then(ajax.saveTa3)
        .then(r => {
          vm.$dlg.toast('Сохранено', { messageType: 'success', closeTime: 3 });
          vm.m.id = r.data.content;
          vm.m_ = _cloneDeep(vm.m);
          if (vm.m.rrId) {
            vm.m.cDate = new Date();
          }
          if (cba) {
            cba();
          }
        })
        .catch(err => {
          vm.$showError(null, err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  mounted() {
    let vm = this;
    vm.m.dsId = vm.userSettings.curDsId;
    vm.m_ = _cloneDeep(vm.m);
    let id = vm.$route.params.id;
    if (id) {
      vm.blockUI = true;
      ajax
        .getTa3({ id })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.initialFetchFailure = 1;
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (_isEqual(this.m, this.m_)) {
      next();
    } else {
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: 'У вас есть несохраненные изменения.\nДействительно хотите выйти?',
          buttons: 6,
        },
        width: 400,
        height: 180,
        title: 'Внимание',
        callback: b => {
          if (b === 2) next();
          else next(false);
        },
      });
    }
  },
  components: {
    ClientComponent,
    VueSingleSelect,
    SelectEripPayment,
    SelectWebpayPayment,
    RomeDatepicker,
  },
  computed: {
    ...mapGetters(['debugMode', 'userSettings', 'ds']),
  },
};
</script>
