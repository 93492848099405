import { ajax } from '@/api/api';
import { store } from '@/store/store';
import { AjaxError } from './consts';
import { b64EncodeUnicode } from './funcs';

/**
 * Returns SHA-256 hash from supplied message.
 * https://gist.github.com/chrisveness/e5a07769d06ed02a2587df16742d3fdd
 * @param   {String} message.
 * @returns {String} hash as hex string.
 *
 * @example
 *   sha256('abc').then(hash => console.log(hash));
 *   const hash = await sha256('abc');
 */
async function sha256(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  var bytes = new Uint8Array(hashBuffer);
  var len = bytes.byteLength;
  var binary = '';
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * Подписывает строку простой подписью (обычный хэш SHA-256).
 * Документ и ЭЦП кодируются в Base64.
 * @param {String} dataString Объект для подписания, уже преобразованный в строку (JSON.stringify)
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
async function signSha256(dataString, encodedData) {
  let data2sign = store.getters.edsError ? dataString + 'a' : dataString;
  let encodedSign = await sha256(data2sign);
  Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${data2sign}`);
  return { s: encodedSign, d: encodedData, si: 0, t: 0 };
}

/**
 * Подписывает объект ЭЦП (реализация от НТЦ Контакт + proxy от Александра Коско).
 * Документ и ЭЦП кодируются в Base64.
 * @param {String} dataString Объект для подписания, уже преобразованный в строку (JSON.stringify)
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
async function signContact(dataString, encodedData) {
  let data2sign;
  if (store.getters.edsError) {
    // Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${dataString + 'a'}`);
    data2sign = b64EncodeUnicode(dataString + 'a');
  } else {
    // Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${dataString}`);
    data2sign = encodedData;
  }
  let post = JSON.stringify({ base64: data2sign });
  return ajax.sign(/* { isSign: 1 } */ null, post).then(
    r => {
      if (r.data.errCode == 0) {
        return Promise.resolve({ s: r.data.content, d: encodedData, si: 1, t: 0 });
      } else {
        return Promise.reject(new AjaxError(1001, r.data.message));
      }
    },
    e => {
      let message = (e.data && e.data.message) ? e.data.message : 'Не удалось поставить ЭЦП. Возможно, не запущен криптосервис';
      return Promise.reject(new AjaxError(1001, 'Ошибка ЭЦП: ' + message));
    }
  );
}

/**
 * Подписывает объект ЭЦП. Реализация выбирается в зависимости от настроек.
 * Объект сначала выгоняется в строку, высчитывается ЭЦП для этой строки.
 * Документ и ЭЦП кодируются в Base64.
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
export async function sign(obj) {
  let dataAsString = typeof obj === 'string' ? obj : JSON.stringify(obj);
  let encodedData = b64EncodeUnicode(dataAsString);
  switch (store.getters.edsImpl) {
    case 0:
      return signSha256(dataAsString, encodedData);
    case 1:
      return signContact(dataAsString, encodedData);
    default:
      return { s: '', d: b64EncodeUnicode(dataAsString), si: -1, t: 0 };
  }
}
