<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div>
      <div
        is="uib-pagination"
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="load"
        :items-per-page="pageSize"
        :max-size="7"
      />
    </div>

    <table class="table table-mc-light-blue table-striped ">
      <thead>
        <tr>
          <th></th>
          <th>Номер</th>
          <th>Дата выдачи</th>
          <th>Госномер</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, i) in list" :key="i" @dblclick="edit(r.id)" style="cursor: pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="edit(r.id)">
                <i class="fa fa-edit fa-2x" />
              </button>
            </div>
          </td>
          <td>{{ r.id }}</td>
          <td>{{ russianDate(r.d) }}</td>
          <td>{{ r.rn }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { russianDate } from '@/lib/funcs';
import { pagedCommonList } from '@/lib/mixins';
export default {
  methods: {
    edit(id) {
      this.$router.push({ path: '/editOgCard/' + id });
    },
    russianDate,
  },
  mixins: [pagedCommonList],
};
</script>
