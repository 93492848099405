<template>
  <input type="text" ref="picker" @blur="blur" />
</template>

<script>
import rome from 'rome';
import 'rome/dist/rome.min.css';
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      required: false,
    },
    modelFormat: {
      type: String,
      default: 'YYYY-MM-DD[T]HH:mm:ssZ',
    },
  },
  methods: {
    blur() {
      let d = this.rome.getDateString();
      if (!d) this.$emit('input', null);
    },
  },
  mounted() {
    this._options = { inputFormat: 'DD.MM.YYYY HH:mm', ...this.options };
    this._options.initialValue = this.value
      ? moment(this.value, this.modelFormat)
      : null;
    this.rome = rome(this.$refs.picker, this._options);

    this.rome.on('data', value => {
      let v = this.rome.getDateString(this.modelFormat);
      this.$emit('input', v);
    });
  },
  watch: {
    value(n, old) {
      if (n) {
        let v = moment(n, this.modelFormat);
        this.rome.setValue(v);
        this.$refs.picker.value = v.format(this._options.inputFormat);
      } else {
        this.rome.setValue(null);
        this.$refs.picker.value = null;
      }
      // let v = n ? moment(n, this.modelFormat) : null
      // this.rome.setValue(v)
      // this.$refs.picker.value = v.format(this._options.inputFormat)
    },
  },
};
</script>
<style>
div.rd-container.rd-container-attachment {
  z-index: 2000;
}
</style>
