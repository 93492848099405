<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div style="height:290px;">
      <form class="form-inline" onsubmit="return false;">
        <div class="form-group">
          <div
            class="input-group col-xs-6"
            v-for="(o, i) in [
              { n: 'pn', t: 'Разрешение' },
              { n: 'vin', t: 'VIN' },
              { n: 'cn', t: 'Техпаспорт' },
              { n: 'rn', t: 'Госномер' },
            ]"
            :key="i"
          >
            <input
              :placeholder="o.t"
              type="text"
              v-model="s[o.n]"
              :name="o.n"
              class="form-control"
              :id="o.n"
              @keyup.enter="$v.s[o.n].$invalid || search(o.n)"
              @input="forceUpper($event, s, o.n)"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="search(o.n)" :disabled="$v.s[o.n].$invalid">
                <span class="fa fa-search"></span>
              </button>
            </span>
          </div>
        </div>
      </form>
      <table class="table table-hover table-mc-light-blue table-striped">
        <thead v-if="showResult">
          <tr>
            <td>Разрешение</td>
            <td>Госномер</td>
            <td>Срок</td>
            <td>Собственник</td>
            <td></td>
          </tr>
        </thead>
        <tbody v-if="showResult">
          <tr v-for="i in perms" :key="i.guid">
            <td>{{ i.num }}</td>
            <td>{{ i.rn }}</td>
            <td>{{ i.pDate | date }} - {{ i.validTo | date }}</td>
            <td>{{ i.ow.n }}</td>
            <td style="text-align:right">
              <div>
              <!-- <div class="col-xs-12" style="text-align:right"> -->
              <button v-show="!i.disabled" @click="$emit('close', i)">Вставить</button>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td>Разрешение</td>
            <td>{{ i.num }} от {{ i.pDate | date }}</td>
          </tr>
          <tr>
            <td>ДК</td>
            <td>{{ i.card }} от {{ i.cardDate | date }}</td>
          </tr>
          <tr>
            <td>VIN</td>
            <td>{{ i.vin }}</td>
          </tr>
          <tr>
            <td>Госномер</td>
            <td>{{ i.rn }}</td>
          </tr>
          <tr>
            <td>Год выпуска</td>
            <td>{{ i.y }}</td>
          </tr>
          <tr>
            <td>Форма собственности</td>
            <td>{{ legalType }}</td>
          </tr>
          <tr>
            <td>Название/ФИО</td>
            <td>{{ i.ow.n }}</td>
          </tr>
          <tr v-if="i.ow.t != 0">
            <td>УНП</td>
            <td>{{ i.ow.u }}</td>
          </tr> -->
        </tbody>
        <tbody style="color:red; text-align: center;" v-if="message">
          <tr>
            <td colspan="2">{{ message }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <!-- <button class="btn btn-success footer-btn" type="button" @click="$emit('close', i)" :disabled="!i.regNumber">
          Вставить
        </button> -->
        <button class="btn footer-btn" type="button" @click="$emit('close')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import { patterns } from '@/lib/consts';
import { forceUpper } from '@/lib/funcs';
import { regex } from '@/lib/validators';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      blockUI: false,
      showResult: false,
      message: null,
      s: {
        pn: null,
        vin: null,
        cn: null,
        rn: null,
      },
      perms: [],
      // i: {
      //   card: '',
      //   cardDate: null,
      //   vin: '',
      //   regNumber: '',
      //   year: null,
      //   ow: {
      //     n: null,
      //     t: null,
      //     u: null,
      //   },
      // },
    };
  },
  validations() {
    return {
      s: {
        pn: { required, regex: regex(patterns.pn) },
        vin: { required, maxLength: maxLength(22), minLength: minLength(1) },
        cn: { required, regex: regex(patterns.cn) },
        rn: { required, regex: regex(patterns.combinedRN) },
      },
    };
  },
  // computed: {
  //   legalType(client) {
  //     return client && client.t && formsOfOwnership[client.t] ? formsOfOwnership[client.t].name : '';
  //   },
  // },
  methods: {
    forceUpper,
    search(type) {
      let vm = this;
      vm.blockUI = true;
      vm.showResult = false;
      vm.message = false;
      ajax
        .searchPerms4OtherCert({ type, term: vm.s[type] })
        .then(
          r => {
            let deadline = moment()
              .hour(0)
              .minute(0)
              .second(0);
            let perms = r.data.content;
            for (let p of perms) {
              p.disabled = moment(p.validTo) < deadline;
            }
            vm.perms = perms;
            if (perms.length > 0) {
              vm.showResult = true;
            } else {
              vm.message = 'Разрешений не найдено';
            }
          },
          err => {
            console.log(err);
            vm.message = 'Ошибка поиска';
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    // search() {
    //   let vm = this;
    //   vm.blockUI = true;
    //   clearObjectFields(vm.i);
    //   ajax
    //     .permInfo4Cert({ pn: vm.aux.pn })
    //     .then(
    //       r => {
    //         console.log(r.data.content);
    //         Object.assign(vm.i, r.data.content);
    //         vm.showResult = true;
    //       },
    //       err => {
    //         vm.message = 'Ошибка поиска';
    //         vm.showResult = false;
    //         vm.$showError('Ошибка', err);
    //       }
    //     )
    //     .finally(() => {
    //       vm.blockUI = false;
    //     });
    // },
  },
};
</script>
