<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <h3 class="text-center">Поиск заявлений на внесение изменений</h3>
    <form name="fSearchTa3" class="form-horizontal" @submit.prevent>
      <div class="row">
        <div class="col-sm-5">
          <div class="form-group">
            <label class="control-label col-sm-5 col-md-3" for="ta_id">№ заявления</label>
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                id="ta_id"
                name="ta_id"
                v-model="m.id"
                @keypress.enter="!$v.m.id.$invalid && search(1)"
                onwheel.passive="return !1;"
                ref="ta_id"
              />
              <span class="input-group-btn">
                <input type="button" class="btn btn-default" :disabled="$v.m.id.$invalid" @click="search(1)" value="Искать" />
              </span>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="form-group">
            <label class="control-label col-sm-5 col-md-3" for="cn">Техпаспорт</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="cn"
                name="cn"
                v-model="m.cn"
                @keypress.enter="!$v.m.cn.$invalid && search(2)"
                @input="forceUpper($event, m, 'cn')"
              />
              <span class="input-group-btn">
                <input type="button" class="btn btn-default" :disabled="$v.m.cn.$invalid" @click="search(2)" value="Искать" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </form>
    <table class="table table-mc-light-blue table-striped">
      <thead>
        <tr>
          <th></th>
          <th>
            Номер
            <br />Разрешение
          </th>
          <th>
            Создано
            <br />Завершено
          </th>
          <th>
            Создал
            <br />Завершил
          </th>
          <th>Имя</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(r, i) in tas" :key="i" @dblclick="edit(r.id)" style="cursor:pointer;">
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="edit(r.id)">
                <i class="fa fa-edit fa-2x"></i>
              </button>
            </div>
          </td>
          <td>
            {{ r.id }}
            <br />
            {{ r.pn }}
          </td>
          <td>
            {{ russianDate(r.aDate) }}
            <br />
            {{ russianDate(r.cDate) }}
          </td>
          <td>
            {{ r.oUser }}
            <br />
            {{ !!r.cUser ? r.cUser : '---' }}
          </td>
          <td>{{ r.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { patterns } from '@/lib/consts';
import { russianDate, forceUpper, loadComponentState, storeComponentState } from '@/lib/funcs';
import { required, minValue } from 'vuelidate/lib/validators';
import { regex } from '@/lib/validators';
export default {
  data() {
    return {
      m: { id: null, cn: null },
      patterns,
      blockUI: false,
      tas: [],
    };
  },
  validations() {
    return {
      m: {
        id: { required, minValue: minValue(1) },
        cn: { required, regex: regex(patterns.cn) },
      },
    };
  },
  methods: {
    edit(id) {
      this.$router.push({ path: '/editTa3/' + id });
    },
    russianDate,
    forceUpper,
    search(type) {
      var vm = this;
      vm.blockUI = true;
      var promise;
      switch (type) {
        case 2:
          promise = ajax.searchTabyCn3({ cn: vm.m.cn });
          break;
        default:
          promise = ajax.searchTabyId3({ id: vm.m.id });
          break;
      }
      promise
        .then(
          r => {
            vm.tas = r.data.content;
            if (vm.tas.length === 0) {
              vm.$dlg.toast('Ничего не найдено', { messageType: 'warning', closeTime: 3 });
            }
          },
          err => {
            vm.$showError('Ошибка', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
  destroyed() {
    storeComponentState('SearchTAs', this.m);
  },
  mounted() {
    try {
      let m = loadComponentState('SearchTAs');
      this.m.id = m.id;
      this.m.cn = m.cn;
    } catch (e) {} // eslint-disable-line
    this.$nextTick(() => {
      this.$refs.ta_id.focus();
    });
  },
};
</script>
