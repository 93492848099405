<template>
  <div>
    <div class="container">
      <div class="fixed-header">
        <span class="fixed-header-title">МСТО {{ m.number }} ({{ editMode }})</span>
        <span class="fa-pull-right">
          <vue-context ref="actMenu">
            <template slot-scope="child">
              <li v-for="mi in actContextMenu" :key="mi.tag">
                <a @click.prevent="menuSelected(mi)">{{ mi.name }}</a>
              </li>
            </template>
          </vue-context>
          <button
            class="btn btn-success icon-button"
            type="button"
            title="Печать"
            @click="print"
            :disabled="initialErr || $v.m.$invalid"
          >
            <i class="fa fa-print fa-2x" />
          </button>
          <button
            class="btn btn-success icon-button"
            type="button"
            title="Сохранить"
            @click="save"
            :disabled="initialErr || $v.m.$invalid"
          >
            <i class="fa fa-save fa-2x" />
          </button>
          <button class="btn btn-danger icon-button" type="button" title="Отмена" @click="$router.go(-1)">
            <i class="fa fa-window-close fa-2x" />
          </button>
        </span>
      </div>
      <BlockUI v-if="blockUI">
        <i class="fa fa-pulse fa-spinner fa-3x fa-fw" />
      </BlockUI>
      <select-kdr
        v-if="showSelectKdr"
        @close="showSelectKdr = false"
        width="900"
        @apply="applyKdr"
        :cn="aux.cn"
        :transfer="0"
      />
      <div class="under-fixed-header container" v-if="!initialErr">
        <!-- Номер разрешения -->
        <div class="form-group row">
          <!-- <label class="control-label col-sm-3">Номер разрешения</label> -->
          <div class="col-sm-9 input-group col-sm-push-3">
            <button type="button" class="btn btn-default" @click="searchPerm">
                Поиск разрешения
              </button>
            <!-- <input
              class="form-control"
              type="text"
              v-model="aux.pn"
              @input="forceUpper($event, aux, 'pn')"
              @keyup.enter="$v.aux.pn.$invalid || permInfo4Cert()"
              autocomplete="off"
              :disabled="editingDisabled"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="permInfo4Cert" :disabled="$v.aux.pn.$invalid">
                <span class="fa fa-search" />
              </button>
            </span> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="control-label col-sm-3">Техпаспорт</label>
          <div class="col-sm-9 input-group">
            <input
              type="text"
              class="form-control"
              v-model="aux.cn"
              placeholder="Номер техпаспорта ТОЧНО ТАК, КАК ОН НАПИСАН В СВИДЕТЕЛЬСТВЕ"
              @keyup.enter="$v.aux.cn.$invalid || (showSelectKdr = 1)"
              @input="forceUpper($event, aux, 'cn')"
              :disabled="editingDisabled"
            />
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="showSelectKdr = 1" :disabled="$v.aux.cn.$invalid">
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>

        <h3 class="text-center">Сертификат</h3>
        <!-- выбор бланка (для нового МСТО) -->
        <!-- <div class="form-group" v-if="!m.mstoId">
          <label
            class="control-label col-sm-3"
            :class="{'has-error':$v.m.blankId.$invalid}"
          >Бланк МСТО</label>
          <div class="col-sm-7 input-group">
            <vue-single-select name="blankId" v-model.number="m.blankId" :options="blanks"/>
          </div>
        </div>-->

        <!-- Номер ДК -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.card.$invalid }">Серия/номер ДК</label>
          <div class="col-sm-9 input-group">
            <input
              class="form-control"
              type="text"
              name="card"
              v-model="m.card"
              @input="forceUpper($event, m, 'card')"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- Дата ДК -->
        <div class="form-group row" v-show="m.card">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.dcDate.$invalid }">Дата ДК</label>
          <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.dcDate"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              @input="recalcValidTo"
              :disabled="editingDisabled"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.categoryId.$invalid }">Категория ТС</label>
          <div class="col-sm-9 input-group">
            <vue-single-select v-model="m.categoryId" :options="cats" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- госномер -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.regNumber.$invalid }">Госномер</label>
          <div class="col-sm-9 input-group">
            <input
              autocomplete="off"
              class="form-control"
              type="text"
              name="regNumber"
              v-model="m.regNumber"
              @input="forceUpper($event, m, 'regNumber')"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- Модель -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.modelId.$invalid }">Модель ТС</label>
          <div class="col-sm-9 input-group">
            <vue-single-select v-model="m.modelId" :options="gmodels" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- VIN -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.vin.$invalid }">VIN</label>
          <div class="col-sm-9 input-group">
            <input
              class="form-control"
              type="text"
              name="vin"
              v-model="m.vin"
              autocomplete="off"
              @input="forceUpper($event, m, 'vin')"
              :disabled="editingDisabled"
              placeholder="оставьте пустым, если не определен"
            />
          </div>
        </div>

        <!-- Год выпуска -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.year.$invalid }">Год выпуска</label>
          <div class="col-sm-9 input-group">
            <input
              class="form-control"
              type="number"
              name="year"
              v-model.number="m.year"
              :disabled="editingDisabled"
              placeholder="оставьте пустым, если не определен"
            />
          </div>
        </div>

        <!-- Место выдачи МСТО -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.actions.$each[0].dsId.$invalid }"
            >Место выдачи МСТО</label
          >
          <div class="col-sm-9 input-group">
            <vue-single-select name="dsId" v-model="m.actions[0].dsId" :options="ds" :disabled="editingDisabled" />
          </div>
        </div>

        <!-- дата оформления МСТО-->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.actions.$each[0].date.$invalid }"
            >Дата МСТО</label
          >
          <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.actions[0].date"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              @input="recalcValidTo"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- срок действия -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.actions.$each[0].validTo.$invalid }"
            >Срок действия</label
          >
          <div class="col-sm-9 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.actions[0].validTo"
              class="form-control"
              :options="{ time: false, inputFormat: 'MM/YYYY' }"
              modelFormat="YYYY-MM-DD"
              autocomplete="off"
              :disabled="editingDisabled"
            />
          </div>
        </div>

        <!-- Компонент выбора собственника/заказчика: -->
        <div class="panel panel-default row">
          <div class="panel-heading">Собственник/заказчик</div>
          <div class="panel-body">
            <div class="container-fluid">
              <client-component v-model="m.ow" :v="$v.m.ow" :disabled="editingDisabled" :useBranches="false" />
            </div>
          </div>
        </div>

        <h3 class="text-center">Регистрационные действия</h3>
        <!-- Регистрационные действия -->
        <div class="row">
          <certificate-actions
            :userInfo="userInfo"
            v-model="m.actions"
            :v="$v.m.actions"
            :validToInputFormat="'MM/YYYY'"
            @actAction="actAction"
          />
        </div>
      </div>
    </div>
    <div v-if="debugMode">{{ m }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, between, numeric, requiredIf } from 'vuelidate/lib/validators';
import { regex, clientValidation, actionsValidation } from '@/lib/validators';
import { patterns } from '@/lib/consts';
import RomeDatepicker from '@/controls/RomeDatepicker';
import ClientComponent from '@/controls/ClientComponent';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { freshClient, numberPad, forceUpper, freshCertificateAction, editingDisabled } from '@/lib/funcs';
import SelectAct from '@/components/docs/SelectAct';
import PermInfo4Cert from '@/components/PermInfo4Cert';
import EditAct from '@/components/docs/EditAct';
import { modifiable } from '@/lib/mixins';
import _isObject from 'lodash/isObject';
import _isNumber from 'lodash/isNumber';
import CertificateActions from '@/controls/CertificateActions';
import SelectKdr from '@/components/SelectKdr';
import { sign } from '@/lib/sign';

export default {
  data() {
    return {
      editMode: 'новый',
      initialErr: false,
      blockUI: false,
      showSelectKdr: false,
      aux: { pn: null, cn: null },
      m: {
        mstoId: null,
        blankId: null,
        categoryId: null,
        modelId: null,
        dsId: null,
        dcDate: moment().format('YYYY-MM-DD'),
        card: null,
        number: null,
        regNumber: null,
        vin: '',
        year: null,
        ow: freshClient(1),
        actions: [
          {
            ...freshCertificateAction(),
            date: moment().format('YYYY-MM-DD'),
            validTo: moment()
              .add(1, 'y')
              .add(-1, 'd')
              .format('YYYY-MM-DD'),
          },
        ],
      },
    };
  },
  validations() {
    return {
      aux: {
        cn: { required, pattern: regex(patterns.cn) },
      },
      m: {
        blankId: { required, numeric },
        categoryId: { required },
        modelId: { required },
        dcDate: { required: requiredIf(nm => !!nm.card) },
        card: { regex: regex(patterns.dc) },
        regNumber: { required, regex: regex(patterns.combinedRN) },
        vin: { regex: regex(patterns.vinLoose) },
        year: { between: between(1940, 2090) },
        ow: clientValidation(true),
        actions: actionsValidation(),
      },
    };
  },
  components: { ClientComponent, VueSingleSelect, RomeDatepicker, CertificateActions, SelectKdr },
  computed: {
    ...mapGetters(['debugMode', 'ds', 'userSettings', 'userInfo', 'cats', 'gmodels']),
    actContextMenu() {
      let vm = this;
      let menu = [{ name: 'Создать новый акт', tag: 'add' }, { name: 'Подобрать акт', tag: 'select' }];
      if (vm.m.actId) {
        menu.push({ name: 'Редактировать акт № ' + vm.m.actNum, tag: 'edit' });
      }

      return menu;
    },
    editingDisabled() {
      return editingDisabled(this.userInfo, this.m.actions[0]);
    },
  },
  methods: {
    actAction({ op, index }) {
      let vm = this;
      if (index < 0 || index >= vm.m.actions.length) return;
      let action = vm.m.actions[index];
      let actionText = action.actionNumber === 0 ? 'выдаче' : 'продлению';
      vm.$dlg.modal(op === 0 ? SelectAct : EditAct, {
        params: {
          initialId: op === 0 ? action.actId : null,
          serviceId: 2,
        },
        width: 800,
        height: op === 0 ? 500 : 590,
        title: `Акт к ${actionText} сертификата МСТО ${vm.m.number}`,
        callback: function(rslt) {
          if (rslt) {
            action.actId = rslt.actId;
            action.actNum = rslt.actNum;
          }
        },
      });
    },
    forceUpper,
    searchPerm() {
      let vm = this;
      vm.$dlg.modal(PermInfo4Cert, {
        width: 1000,
        height: 400,
        title: 'Поиск разрешений',
        callback: function(rslt) {
          if (_isObject(rslt)) {
            vm.m.card = rslt.dc;
            vm.m.regNumber = rslt.rn;
            vm.m.vin = rslt.vin;
            vm.m.year = rslt.y;
            vm.m.ow = rslt.ow;
            vm.m.categoryId = rslt.cId;
            vm.m.modelId = rslt.mId;
            vm.m.dcDate = moment(rslt.dcDate || rslt.pDate).format('YYYY-MM-DD');
            vm.recalcValidTo();
          }
        },
      });
    },
    applyKdr(gk) {
      // MAA1943576
      let vm = this;
      vm.showSelectKdr = false;
      let k = gk[1];
      let g = gk[0];
      let m = vm.m;
      if (k !== null) {
        m.dcDate = moment(k.tiDate).format();
        if (k.dcNumber) {
          m.card = (k.dcSeries || '') + numberPad(k.dcNumber, 7);
        } else {
          m.card = null;
        }
        m.regNumber = k.regNumber;
        m.categoryId = k.catId;
        m.modelId = k.modelId;
        m.vin = k.vin;
        m.year = k.year;
        m.ow.n = k.owner;
        m.ow.t = k.ownerType || 2;
        m.ow.u = k.ownerUnp === 0 ? '' : k.ownerUnp;
        vm.recalcValidTo();
      }
      if (g !== null) {
        m.year = g.y;
        m.regNumber = g.rn;
        m.vin = g.vin ? g.vin : !m.vin ? g.chassis : m.vin;
      }
    },
    recalcValidTo() {
      let m = this.m;
      let startDate;
      if (m.actions[0].date >= '2022-04-09') {
        // с 2022-04-09 расчёт срока действия МСТО ведётся от даты его выдачи
        startDate = m.actions[0].date;
      } else {
        if (m.dcDate) {
          startDate = m.dcDate;
        } else if (m.actions[0].date) {
          startDate = m.actions[0].date;
        }
      }
      m.actions[0].validTo = moment(startDate)
        .add(1, 'y')
        .format('YYYY-MM-DD');
    },
    menuSelected(mi) {
      let vm = this;
      vm.$dlg.modal(mi.tag === 'select' ? SelectAct : EditAct, {
        params: {
          initialId: mi.tag === 'edit' ? vm.m.actId : null,
          serviceId: 2,
        },
        width: 800,
        height: mi.tag === 'select' ? 500 : 500,
        title: 'Акт к МСТО ' + vm.m.number,
        callback: function(rslt) {
          if (rslt) {
            vm.m.actId = rslt.actId;
            vm.m.actNum = rslt.actNum;
          }
        },
      });
    },
    save() {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      vm.blockUI = true;
      sign(vm.m)
        .then(ajax.saveMsto)
        .then(r => {
          vm.m = r.data.content;
          vm.m_ = _cloneDeep(vm.m);
          // vm.$router.go(-1)
          vm.editMode = 'редактирование';
        })
        .catch(err => {
          vm.$showError(null, err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    print() {
      if (!(this.m.mstoId && _isEqual(this.m, this.m_))) {
        this.$dlg.alert('Можно печатать только сохраненные сертификаты', {
          messageType: 'warning',
        });
        return;
      }
      window.open(
        `s/certs/mstoPdf2/${this.m.mstoId}?topOffset=${this.userSettings.topOffset}&leftOffset=${
          this.userSettings.leftOffset
        }`,
        '_blank'
      );
    },
  },
  mounted() {
    let vm = this;
    let rp = vm.$route.params;
    if (rp.mstoId) {
      // редактирование существующего
      vm.blockUI = true;
      // eslint-disable-next-line
      ajax
        .getMsto({ mstoId: rp.mstoId })
        .then(
          r => {
            vm.initialErr = true;
            let m = r.data.content;
            if (m.actions.length > 0 && m.actions[0].actionNumber === 0) {
              vm.initialErr = false;
              vm.m = m;
              if (m.year == 0) m.year = null;
              vm.m_ = _cloneDeep(m);
            } else {
              vm.$showError('error', {
                errCode: 1,
                message: 'Из БД получен сертификат с неправильными регистрационными данными',
              });
            }
          },
          err => {
            vm.initialErr = true;
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
      vm.editMode = 'редактирование';
    } else if (_isObject(rp.bl) && _isNumber(rp.bl.id)) {
      vm.m.blankId = rp.bl.id;
      vm.m.number = rp.bl.s + ' ' + numberPad(rp.bl.n, 7);
      vm.m.actions = [{ ...freshCertificateAction(vm.userInfo, vm.userSettings) }];
      vm.m_ = _cloneDeep(vm.m);
      vm.editMode = 'новый';
    } else {
      vm.m_ = _cloneDeep(vm.m);
      vm.$router.go(-1);
    }
  },
  mixins: [modifiable],
};
</script>
