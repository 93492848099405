<template>
  <div>
    <div class="container">
      <div class="fixed-header">
        <span class="fixed-header-title">ЕКМТ {{ m.number }} ({{ editMode }})</span>
        <span class="fa-pull-right">
          <button
            class="btn btn-warning icon-button"
            type="button"
            @click="fillDummyData"
            :disabled="initialErr"
            v-if="debugMode"
          >
            <i class="fa fa-bug fa-2x"></i>
          </button>
          <button
            class="btn btn-success icon-button"
            type="button"
            title="Печать"
            @click="print"
            :disabled="initialErr || $v.m.$invalid"
          >
            <i class="fa fa-print fa-2x"></i>
          </button>
          <button
            class="btn btn-success icon-button"
            type="button"
            title="Сохранить"
            @click="save"
            :disabled="initialErr || $v.m.$invalid"
          >
            <i class="fa fa-save fa-2x"></i>
          </button>
          <button class="btn btn-danger icon-button" type="button" title="Отмена" @click="goBack">
            <i class="fa fa-window-close fa-2x"></i>
          </button>
        </span>
      </div>
      <BlockUI v-if="blockUI">
        <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
      </BlockUI>
      <div class="under-fixed-header" v-show="!initialErr">
        <!-- Флажок нового авто -->
        <div class="form-group row">
          <div class="icheck-primary col-sm-offset-4">
            <input type="checkbox" id="newVehicle" v-model="newVehicle" @change="recalcValidTo" />
            <label for="newVehicle">Новое ТС</label>
          </div>
        </div>

        <h3 class="text-center">Протокол испытаний</h3>
        <!-- № Протокола испытаний -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.protocolNumber.$invalid }"
            >№ протокола</label
          >
          <div class="col-sm-8 input-group">
            <input class="form-control" type="text" :disabled="newVehicle" v-model="m.protocolNumber" />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="selectFromProtocol"
                :disabled="$v.m.protocolNumber.$invalid"
                title="Поискать протокол по номеру"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>

        <!-- Место выдачи протокола испытаний -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.protocolDsId.$invalid }"
            >Место выдачи</label
          >
          <div class="col-sm-8 input-group">
            <vue-single-select v-model="m.protocolDsId" :options="ds" :disabled="newVehicle" />
          </div>
        </div>

        <!-- дата протокола испытаний -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.protocolDate.$invalid }"
            >Дата протокола</label
          >
          <div class="col-sm-8 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.protocolDate"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              :disabled="newVehicle"
              @input="recalcValidTo"
            />
          </div>
        </div>

        <h3 class="text-center">Данные о ТС</h3>
        <!-- госномер -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.regNumber.$invalid }">Госномер</label>
          <div class="col-sm-8 input-group">
            <input
              class="form-control"
              type="text"
              v-model="m.regNumber"
              @input="forceUpper($event, m, 'regNumber')"
              autocomplete="off"
            />
            <span class="input-group-btn">
              <button
                type="button"
                class="btn btn-default"
                @click="selectEkmtFromOld"
                :disabled="$v.m.regNumber.$invalid"
                title="Поискать среди выданных ранее сертификатов"
              >
                <span class="fa fa-search" />
              </button>
            </span>
          </div>
        </div>

        <!-- Флажок прицепа -->
        <div class="form-group row">
          <div class="icheck-primary col-sm-offset-4">
            <input type="checkbox" id="trailer" v-model="m.trailer" @change="trailerChanged" />
            <label for="trailer">Прицеп</label>
          </div>
        </div>

        <!-- Экологический класс -->
        <div class="form-group row">
          <label class="control-label col-sm-4">Экологический класс</label>
          <div class="col-sm-8 input-group">
            <select class="form-control" v-model="m.ecoClass" @change="ecoClassChanged" :disabled="m.trailer">
              <option :value="o.id" v-for="(o, i) in ecoClasses" :key="i">{{ o.name }}</option>
            </select>
          </div>
        </div>

        <!-- Сертификат соответствия -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.complianceCert.$invalid }"
            >Сертификат соответствия</label
          >
          <div class="col-sm-8 input-group">
            <input
              class="form-control"
              type="text"
              v-model="m.complianceCert"
              autocomplete="off"
              @input="forceUpper($event, m, 'complianceCert')"
            />
          </div>
        </div>

        <!-- VIN -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.vin.$invalid }">VIN</label>
          <div class="col-sm-8 input-group">
            <input
              class="form-control"
              type="text"
              v-model="m.vin"
              autocomplete="off"
              @input="forceUpper($event, m, 'vin')"
            />
          </div>
        </div>

        <!-- Модель (не для прицепа) или тип (для прицепа) -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.model.$invalid }">Тип ТС, модель</label>
          <div class="col-sm-8 input-group">
            <input type="text" class="form-control" v-model="m.model" />
          </div>
        </div>

        <!-- Тип и номер двигателя (не для прицепов): -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.engineTypeNumber.$invalid }"
            >Тип и номер двигателя</label
          >
          <div class="col-sm-8 input-group">
            <input class="form-control" v-model="m.engineTypeNumber" :disabled="m.trailer" />
          </div>
        </div>

        <!-- коэффициент поглощения -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.absorptionCoefficient.$invalid }"
            >Коэффициент поглощения</label
          >
          <div class="col-sm-8 input-group">
            <input
              class="form-control"
              type="number"
              step="0.01"
              :disabled="m.trailer || m.ecoClass == 6"
              v-model.number="m.absorptionCoefficient"
            />
          </div>
        </div>

        <!-- выбор ДС -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.dsId.$invalid }">Место выдачи</label>
          <div class="col-sm-8 input-group">
            <vue-single-select name="dsId" v-model="m.dsId" :options="ds" />
          </div>
        </div>

        <!-- Год выпуска -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.year.$invalid }">Год выпуска</label>
          <div class="col-sm-8 input-group">
            <input class="form-control" type="number" v-model.number="m.year" />
          </div>
        </div>

        <!-- дата выдачи -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.date.$invalid }">Дата выдачи</label>
          <div class="col-sm-8 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.date"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              @input="recalcValidTo"
            />
          </div>
        </div>

        <!-- срок действия -->
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'has-error': $v.m.validTo.$invalid }">Срок действия</label>
          <div class="col-sm-8 input-group" style="margin-bottom: 0.5em;">
            <rome-datepicker
              v-model="m.validTo"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              autocomplete="off"
            />
          </div>
        </div>

        <!-- Номер акта: -->
        <div class="form-group row">
          <label class="control-label col-sm-4">№ акта</label>
          <div class="col-sm-8 input-group">
            <input class="form-control" type="text" v-model="m.actNum" readonly />
            <!-- <span class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                title="Меню акта"
                :disabled="initialErr || $v.m.$invalid"
                @click.prevent.stop="$refs.actMenu.open"
              >...</button>
            </span>-->
          </div>
        </div>

        <h3 class="text-center">Заказчик</h3>
        <!-- Компонент выбора собственника: -->
        <client-component
          v-model="m.cust"
          :v="$v.m.cust"
          :formsOfOwnership="[{ id: 1, name: 'ЮЛ' }, { id: 2, name: 'ИП' }]"
          :useBranches="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectExistingEkmt from '@/components/ekmt/SelectExistingEkmt';
import { required, between, numeric, requiredIf } from 'vuelidate/lib/validators';
import { regex, clientValidation } from '@/lib/validators';
import { patterns } from '@/lib/consts';
import RomeDatepicker from '@/controls/RomeDatepicker';
import ClientComponent from '@/controls/ClientComponent';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import {
  freshClient,
  numberPad,
  forceUpper,
  randomChars,
  randomArrayItem,
  randomFromRange,
  randomDate,
  isBlankOrEmpty,
} from '@/lib/funcs';
import SelectAct from '@/components/docs/SelectAct';
import EditAct from '@/components/docs/EditAct';
import { modifiable } from '@/lib/mixins';
import _isObject from 'lodash/isObject';
import _isNumber from 'lodash/isNumber';
import _isInteger from 'lodash/isInteger';
import ConfirmDialogVue from '@/controls/ConfirmDialog';
import { sign } from '@/lib/sign';

export default {
  data() {
    return {
      editMode: 'новый',
      initialErr: false,
      ecoClasses: [
        { id: 0, name: 'Не указан' },
        { id: 4, name: 'Евро 4' },
        { id: 5, name: 'Евро 5' },
        { id: 6, name: 'Евро 6' },
        { id: 100, name: 'Евро EEV' },
      ],
      newVehicle: false,
      blockUI: false,
      m: {
        complianceCert: '',
        model: null,
        ekmtId: null,
        blankId: null,
        dsId: null,
        date: moment().format('YYYY-MM-DD'),
        validTo: moment()
          .add(1, 'y')
          .format('YYYY-MM-DD'),
        number: null,
        regNumber: null,
        vin: null,
        year: null,
        actId: null,
        engineTypeNumber: '',
        absorptionCoefficient: null,
        trailer: false,
        ecoClass: null,
        protocolNumber: null,
        protocolDate: moment().format('YYYY-MM-DD'),
        protocolDsId: null,
        cust: freshClient(1),
      },
    };
  },
  validations() {
    let vm = this;
    return {
      m: {
        regNumber: { required, regex: regex(patterns.combinedRN) },
        blankId: { required, numeric },
        dsId: { required, between: between(100000000, 999999999) },
        date: { required },
        validTo: { required },
        vin: { required, regex: regex(patterns.vinLoose) },
        year: { required, between: between(1940, 2090) },
        cust: clientValidation(true),
        engineTypeNumber: { required },
        absorptionCoefficient: {
          req: requiredIf(function(nm) {
            return !(nm.trailer || nm.ecoClass == 6);
          }),
          range: between(0.0, 99.99),
        },
        complianceCert: { required },
        protocolDate: {
          req: requiredIf(function(nm) {
            return !vm.newVehicle;
          }),
        },
        protocolNumber: {
          req: requiredIf(function(nm) {
            return !vm.newVehicle;
          }),
        },
        protocolDsId: {
          req: requiredIf(function(nm) {
            return !vm.newVehicle;
          }),
          between: between(100000000, 999999999),
        },
        model: { required },
      },
    };
  },
  components: { ClientComponent, VueSingleSelect, RomeDatepicker },
  computed: {
    ...mapGetters(['debugMode', 'ds', 'userSettings', 'gmodels']),
    actContextMenu() {
      let vm = this;
      let menu = [{ name: 'Создать новый акт', tag: 'add' }, { name: 'Подобрать акт', tag: 'select' }];
      if (vm.m.actId) {
        menu.push({ name: 'Редактировать акт № ' + vm.m.actNum, tag: 'edit' });
      }

      return menu;
    },
  },
  methods: {
    selectFromProtocol() {
      let vm = this;
      let m = this.m;
      let number = Number(m.protocolNumber);
      if (!_isInteger(number)) {
        return;
      }
      ajax
        .fetchEkmtProto({ id: number })
        .then(r => {
          let data = r.data.content;
          if (!data) {
            vm.$dlg.modal(ConfirmDialogVue, {
              params: { message: 'Не найдено', buttons: 1 },
              width: 400,
              height: 180,
              title: 'Не найдено',
            });
            return;
          }
          data.date = moment(data.date).format('YYYY-MM-DD');
          let message = `Найден протокол № ${data.number} от ${data.date}, выданный на ${
            data.dsName
          }. Хотите заполнить данные из него?`;
          vm.$dlg.modal(ConfirmDialogVue, {
            params: { message, buttons: 6 },
            width: 400,
            height: 180,
            title: 'Подтверждение',
            callback: function(b) {
              if (b !== 2) {
                return;
              }
              let ri = data.regInfo;
              m.protocolDsId = data.dsId;
              m.protocolDate = data.date;
              m.model = data.model;

              m.regNumber = ri.regNumber;
              m.trailer = ri.categoryId >= 7 && ri.categoryId <= 10;
              m.ecoClass = ri.euroClass;
              m.complianceCert = ri.complianceCert;
              m.vin = ri.vin;
              m.model = ri.modelName;
              m.absorptionCoefficient = data.info.smoke;
              m.year = ri.year;
              m.cust = ri.client;
              vm.trailerChanged(0);
              m.engineTypeNumber = `${ri.engineType} ${ri.engineNumber}`;
              vm.recalcValidTo();
            },
          });
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    recalcValidTo() {
      let m = this.m;
      let startDate = this.newVehicle ? m.date : m.protocolDate;
      m.validTo = moment(startDate)
        .add(1, 'y')
        .format('YYYY-MM-DD');
    },
    ecoClassChanged() {
      let m = this.m;
      if (m.ecoClass == 6) {
        m.absorptionCoefficient = null;
      }
    },
    fillDummyData() {
      let m = this.m;
      m.regNumber = randomChars(2) + randomFromRange(1000, 9999) + '-' + randomFromRange(1, 7);
      m.complianceCert = randomChars(5);
      m.model = randomArrayItem(this.gmodels).name;
      m.vin = randomChars(17);
      m.engineTypeNumber = randomChars(4) + '/' + randomChars(5);
      m.absorptionCoefficient = Math.floor(randomFromRange(0, 10000) / 77) / 100;
      m.dsId = this.userSettings.curDsId;
      m.protocolDsId = this.userSettings.curDsId;
      m.protocolNumber = randomChars(4);
      m.year = (1990, 2018);
      let d = moment(randomDate());
      m.date = d.format('YYYY-MM-DD');
      m.validTo = d.add(1, 'y').format('YYYY-MM-DD');
      m.cust.t = randomFromRange(2, 3);
      m.cust.n = randomChars(10);
      m.cust.u = randomFromRange(100000000, 999999999);
    },
    /**
     * @param {Boolean} clean очищать ли engineTypeNumber
     */
    trailerChanged(clean = 1) {
      let m = this.m;
      if (m.trailer) {
        m.engineTypeNumber = '--- / ---';
        m.absorptionCoefficient = null;
        m.ecoClass = null;
      } else {
        if (clean) m.engineTypeNumber = null;
      }
    },
    forceUpper,
    selectEkmtFromOld() {
      let vm = this;
      vm.$dlg.modal(SelectExistingEkmt, {
        params: { rn: vm.m.regNumber, ekmtId: vm.m.ekmtId },
        width: 800,
        height: 400,
        title: 'Информация о ЕКМТ',
        callback: function(rslt) {
          if (_isObject(rslt)) {
            vm.m.model = rslt.model;
            vm.m.complianceCert = rslt.complianceCert;
            vm.m.regNumber = rslt.regNumber;
            vm.m.vin = rslt.vin;
            vm.m.engineTypeNumber = rslt.engineTypeNumber;
            vm.m.absorptionCoefficient = rslt.absorptionCoefficient;
            vm.m.year = rslt.year;
            vm.m.trailer = rslt.trailer;
            vm.m.cust = rslt.cust;
          }
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    menuSelected(mi) {
      let vm = this;
      vm.$dlg.modal(mi.tag === 'select' ? SelectAct : EditAct, {
        params: {
          initialId: mi.tag === 'edit' ? vm.m.actId : null,
          serviceId: 3,
        },
        width: 800,
        height: 500,
        title: 'Акт к ЕКМТ ' + vm.m.number,
        callback: function(rslt) {
          if (rslt) {
            vm.m.actId = rslt.actId;
            vm.m.actNum = rslt.actNum;
          }
        },
      });
    },
    save() {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');
      vm.blockUI = true;
      sign(vm.m)
        .then(ajax.saveEkmt)
        .then(r => {
          vm.m = r.data.content;
          vm.newVehicle = isBlankOrEmpty(vm.m.protocolNumber);
          vm.m_ = _cloneDeep(vm.m);
          // vm.$router.go(-1)
          vm.editMode = 'редактирование';
        })
        .catch(err => {
          vm.$showError(null, err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    print() {
      if (!(this.m.ekmtId && _isEqual(this.m, this.m_))) {
        this.$dlg.alert('Можно печатать только сохраненные сертификаты', { messageType: 'warning' });
      } else {
        window.open('s/certs/ekmtPdf2/' + this.m.ekmtId, '_blank');
      }
    },
  },

  mounted() {
    let vm = this;
    let rp = vm.$route.params;

    if (rp.certId) {
      // редактирование существующего
      vm.blockUI = true;
      // eslint-disable-next-line
      ajax
        .getEkmt({ certId: rp.certId })
        .then(
          r => {
            vm.m = r.data.content;
            vm.newVehicle = isBlankOrEmpty(vm.m.protocolNumber);
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.initialErr = true;
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
      vm.editMode = 'редактирование';
    } else if (_isObject(rp.bl) && _isNumber(rp.bl.id)) {
      vm.m.blankId = rp.bl.id;
      vm.m.number = rp.bl.s + ' ' + numberPad(rp.bl.n, 6);
      vm.m.dsId = vm.userSettings.curDsId;
      vm.m_ = _cloneDeep(vm.m);
      vm.editMode = 'новый';
    } else {
      vm.m_ = _cloneDeep(vm.m);
      vm.$router.go(-1);
    }
  },
  mixins: [modifiable],
};
</script>
