<template>
  <div class="container">
    <h3 class="text-center">"Передача" разрешений</h3>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <form name="passPermsForm" onsubmit="return false;">
      <div class="form-group row">
        <label class="control-label col-sm-3">
          Диапазон:
        </label>
        <div class="col-sm-3 no-left-pad">
          <input
            type="text"
            v-model="m.series"
            class="form-control"
            :class="{ 'has-error': $v.m.series.$invalid }"
            @input="forceUpper($event, m, 'series')"
            placeholder="Серия"
          />
        </div>
        <div class="col-sm-3 no-left-pad">
          <input
            name="begin"
            type="number"
            v-model.number="m.begin"
            class="form-control"
            placeholder="Начало"
            :class="{ 'has-error': $v.m.begin.$invalid }"
          />
        </div>
        <div class="col-sm-3 no-left-pad">
          <input
            name="begin"
            type="number"
            v-model.number="m.end"
            class="form-control"
            placeholder="Конец"
            :class="{ 'has-error': $v.m.end.$invalid }"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-5 col-sm-push-3 no-pad" style="padding-top: 6px;">
          <button class="btn btn-success" type="button" @click="pass" :disabled="$v.$invalid">Передать {{ countBlanks }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minValue, maxValue, maxLength } from 'vuelidate/lib/validators';
import { forceUpper } from '@/lib/funcs';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      blockUI: false,
      m: { series: null, begin: null, end: null },
    };
  },
  validations() {
    return {
      m: {
        series: { required, mx: maxLength(2) },
        begin: { required, mn: minValue(1), mx: maxValue(90000000) },
        end: { required, mn: minValue(Math.max(1, this.m.begin)), mx: maxValue(90000000) },
      },
      // blankGroup: ['m.series', 'm.begin', 'm.end'],
    };
  },
  computed: {
    countBlanks() {
      let m = this.m;
      if (typeof m.end == 'number' && typeof m.begin == 'number' && m.end >= m.begin) {
        return m.end - m.begin + 1 + ' разрешений';
      }
    },
  },
  methods: {
    forceUpper,
    pass() {
      let vm = this;
      vm.blockUI = true;
      sign(vm.m)
        .then(ajax.passPerms)
        .then(r => {
          vm.$dlg.toast(r.data.content, { messageType: 'success', closeTime: 3 });
        })
        .catch(err => {
          vm.$showError('Ошибка', err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
  },
};
</script>
