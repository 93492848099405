<template>
  <div>
    <div class="container">
      <h3 class="text-center">Изменение пароля пользователя</h3>
      <h4 v-if="message" style="color:red;" class="text-center">{{ message }}</h4>
      <div class="row form-group">
        <label class="control-label col-xs-3">Пользователь</label>
        <div class="col-xs-8 no-pad">
          <input
            class="form-control"
            type="text"
            v-model="m.login"
            ref="login"
            :class="{ 'has-error': $v.m.login.$invalid }"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="control-label col-xs-3">Текущий пароль</label>
        <div class="col-xs-8 no-pad">
          <input
            class="form-control"
            type="password"
            v-model="m.oldPassword"
            ref="oldPassword"
            :class="{ 'has-error': $v.m.oldPassword.$invalid }"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="control-label col-xs-3">Новый пароль</label>
        <div class="col-xs-8 input-group">
          <input
            class="form-control"
            :type="passwordOpened ? 'text' : 'password'"
            v-model="m.newPassword"
            :class="{ 'has-error': $v.m.newPassword.$invalid }"
          />
          <span class="input-group-btn">
            <button type="button" class="btn" @click="passwordOpened = !passwordOpened" title="Показать/скрыть пароль">
              <i
                class="fa"
                :class="{
                  'fa-eye': passwordOpened,
                  'fa-eye-slash': !passwordOpened,
                }"
              ></i>
            </button>
            <button type="button" class="btn btn-default" @click="pwdGen()" title="Сгенерировать безопасный пароль">
              <i class="fa fa-asterisk" />
            </button>
          </span>
        </div>
      </div>
      <div class="row form-group" v-show="!passwordOpened">
        <label class="control-label col-xs-3">Новый пароль (повтор)</label>
        <div class="col-xs-8 no-pad">
          <input
            class="form-control"
            type="password"
            v-model="m.newPasswordRepeat"
            :class="{ 'has-error': $v.m.newPasswordRepeat.$invalid }"
          />
        </div>
      </div>

      <div class="row" style="text-align: right;">
        <div class="col-xs-11 no-pad">
          <button class="btn btn-success" type="button" @click="save()">
            Изменить
          </button>&nbsp;&nbsp;
          <button class="btn btn-success" type="button" @click="$router.push({ path: '/' })">
            На главную
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _pwdGen from 'generate-password-browser';
import { passwordComplexity } from '@/lib/funcs';
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      passwordOpened: false,
      message: null,
      m: {
        login: '',
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
      },
    };
  },
  methods: {
    pwdGen() {
      var newPwd = '';
      var tries = 100;
      while (!passwordComplexity(newPwd) && tries > 0) {
        newPwd = _pwdGen.generate({
          length: 10,
          numbers: true,
          symbols: true,
          uppercase: true,
          lowercase: true,
        });
        tries--;
      }
      this.m.newPassword = newPwd;
      this.newPasswordRepeat = newPwd;
      this.passwordOpened = true;
    },
    save() {
      let vm = this;
      vm.$dlg.closeAll();
      ajax
        .changePassword(vm.m)
        .then(r => {
          vm.$dlg.toast('Пароль обновлен', { messageType: 'success' });
        })
        .catch(e => {
          vm.$showError('Ошибка изменения пароля', e);
        });
    },
  },
  mounted() {
    let vm = this;
    let q = vm.$route.params;
    if (q.login) {
      vm.m.login = q.login;
      vm.message = q.message;
    }
    if (!vm.m.login) {
      q = vm.$route.query;
      if (q) {
        vm.m.login = q.login;
        vm.message = q.message;
      }
    }
    if (!vm.m.login) {
      if (vm.$store.getters.loggedIn && vm.$store.getters.userInfo.login) {
        vm.m.login = vm.$store.getters.userInfo.login;
      }
    }
    vm.$nextTick(() => {
      if (vm.m.login) {
        vm.$refs.oldPassword.focus();
      } else {
        vm.$refs.login.focus();
      }
    });
  },
  validations() {
    let vm = this;
    return {
      m: {
        login: { required, ml: minLength(3) },
        oldPassword: { required, ml: minLength(3) },
        newPassword: { required, ml: minLength(8) },
        newPasswordRepeat: {
          req: requiredIf(() => !vm.passwordOpened),
          same: sameAs('newPassword'),
        },
      },
    };
  },
};
</script>
