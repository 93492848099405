import Vuex from 'vuex';
import { mergeRefs, byNameComparator, bySortOrderAndNameComparator } from '@/lib/funcs';
import _isEmpty from 'lodash/isEmpty';
import { allReferences } from '@/lib/consts';

const defaultUserInfo = { roles: [] };
Vue.use(Vuex);
var _storeObj = {
  state: {
    debugMode: process.env.NODE_ENV === 'development',
    userInfo: defaultUserInfo,
    loggedIn: false,
    edsImpl: -1,
    edsError: false,
    userSettings: {},
    users: [],
    gmodels: [],
    cats: [],
    areas: [],
    regions: [],
    colors: [],
    ds: [],
    applyings: [],
    taxdiscounts: [],
    tariffs: {},
    unreadMessages: 0,
    services: [],
    servicesMap: {},
    subservicesMap: {},
    subserviceSets: {},
  },
  getters: {
    userSettings: (state) => {
      var us = state.userSettings;
      if (_isEmpty(us)) {
        try {
          us = JSON.parse(localStorage.getItem('userSettings')) || {};
        } catch (e) {
          us = {};
        }
        state.userSettings = us;
      }
      return state.userSettings;
    },
    debugMode: (state) => state.debugMode,
    edsImpl: (state) => state.edsImpl,
    edsError: (state) => state.edsError,
    userInfo: (state) => state.userInfo,
    loggedIn: (state) => state.loggedIn,
    users: (state) => state.users,
    gmodels: (state) => state.gmodels,
    cats: (state) => state.cats,
    areas: (state) => state.areas,
    regions: (state) => state.regions,
    colors: (state) => state.colors,
    ds: (state) => state.ds,
    applyings: (state) => state.applyings,
    taxdiscounts: (state) => state.taxdiscounts,
    tariffs: (state) => state.tariffs,
    services: (state) => state.services,
    servicesMap: (state) => state.servicesMap,
    subservicesMap: (state) => state.subservicesMap,
    subserviceSets: (state) => state.subserviceSets,
    unreadMessages: (state) => state.unreadMessages,
  },
  mutations: {
    userSettings: (state, value) => {
      state.userSettings = value;
      localStorage.setItem('userSettings', JSON.stringify(value));
    },
    edsImpl: (state, v) => (state.edsImpl = v),
    edsError: (state, v) => (state.edsError = v),
    setDebugMode: (state, d) => {
      if (d) {
        state.debugMode = true;
        Vue.$log.changeOptions({ logLevel: 'debug' });
      } else {
        state.debugMode = false;
        Vue.$log.changeOptions({ logLevel: 'warn' });
      }
    },
    login: (state, userInfo) => {
      state.userInfo = userInfo;
      state.edsImpl = userInfo.edsImpl;
      state.loggedIn = true;
    },
    logout: (state) => {
      state.userInfo = defaultUserInfo;
      state.loggedIn = false;
    },
    userInfo: (state, info) => (state.userInfo = info),
    reloadReferences: (state, vm) => {
      loadReferences(vm, true);
    },
    loadStoredData: (state, vm) => {
      //   try {
      //     var _userSettings = JSON.parse(localStorage.getItem("userSettings"))
      //     store.commit("userSettings", _userSettings)
      //   } catch (err) {
      //     log.error("loadUserSettings:", err)
      //   }
      setTimeout(updateMessageCount, 2000);
      // Запуск опроса новых сообщений каждые 10 минут:
      setInterval(updateMessageCount, 10 * 60 * 1000);
      loadReferences(vm, false);
    },
    users: (state, v) => (state.users = v),
    gmodels: (state, v) => (state.gmodels = v),
    cats: (state, v) => (state.cats = v),
    areas: (state, v) => (state.areas = v),
    regions: (state, v) => (state.regions = v),
    colors: (state, v) => (state.colors = v),
    ds: (state, v) => (state.ds = v),
    applyings: (state, v) => (state.applyings = v),
    taxdiscounts: (state, v) => (state.taxdiscounts = v),
    tariffs: (state, v) => (state.tariffs = v),
    services: (state, v) => (state.services = v),
    subservicesMap: (state, v) => (state.subservicesMap = v),
    subserviceSets: (state, v) => (state.subserviceSets = v),
    servicesMap: (state, v) => (state.servicesMap = v),
    unreadMessages: (state, v) => (state.unreadMessages = v),
  },
};

export const store = new Vuex.Store(_storeObj);

export const isUserInRole = function() {
  const uInfo = store.getters.userInfo;
  if (uInfo && 'roles' in uInfo) {
    for (var roleIndex in arguments) {
      if (uInfo.roles.indexOf(arguments[roleIndex]) !== -1) {
        return true;
      }
    }
  }
  return false;
};

export const isUserInAllRoles = function() {
  const uInfo = store.getters.userInfo;
  let counter = 0;
  if (uInfo && 'roles' in uInfo) {
    for (var roleIndex in arguments) {
      if (uInfo.roles.indexOf(arguments[roleIndex]) !== -1) {
        counter++;
      }
    }
  }
  return counter == arguments.length;
};

function loadReferences(vm, fully) {
  allReferences.forEach((refName) => loadReference(vm, refName, fully));

  ajax.getTariffs({ services: '1,2,3,4,11,13,16' }).then(
    (r) => {
      let tariffs = r.data.content;
      store.commit('tariffs', tariffs);
    },
    (err) => {
      console.log(err);
    }
  );
  ajax.getServices().then(
    (r) => {
      let services = r.data.content;
      let servicesMap = {};
      // Колхозно захардкодить некоторые данные, преобразовать массив услуг в map:
      services.forEach((e) => {
        e.v = [2, 3, 4].includes(e.id);
        if (e.id == 1) e.actWithoutPayment = true;
        servicesMap[e.id] = _cloneDeep(e);
      });
      store.commit('services', services);
      store.commit('servicesMap', servicesMap);
    },
    (err) => console.log(err)
  );

  ajax.getSubserviceSets().then(
    (r) => {
      let subserviceSets = r.data.content;
      let subservicesMap = {};
      for (let key in subserviceSets) {
        let el = subserviceSets[key];
        for (let sset of el) {
          for (let subservice of sset.tab) {
            subservicesMap[subservice.subservice.id] = subservice.subservice;
          }
        }
      }
      store.commit('subserviceSets', subserviceSets);
      store.commit('subservicesMap', subservicesMap);
    },
    (err) => console.log(err)
  );
}

function loadReference(vm, refName, fully) {
  var refData = localStorage.getItem(refName); // Получить справочник как json-строку
  try {
    if (fully || refData === null) {
      throw new Error();
    }
    refData = JSON.parse(refData);
  } catch (err) {
    refData = { date: 0, data: [], ver: -1 };
    localStorage.setItem(refName, JSON.stringify(refData));
  }
  if (moment(refData.date) < moment().add(-8, 'hour')) {
    // справочник устарел, попробуем обновиться
    // Vue.$log.debug(new Date() + ": loading reference: " + refName)
    ajax
      .getRef({
        refName,
        v: refData.ver,
      })
      .then(
        (r) => {
          // log.debug(refName, r)
          var data = r.data.content;

          refData.date = data.date;
          refData.ver = data.ver;
          if (data.data.length > 0) {
            // $log.info(new Date() + ": merging: " + refName + " (" + data.data.length + " new items)")
            refData.data = mergeRefs([refData.data, data.data]);

            var arr = refData.data;
            arr.sort(typeof arr[0].so === 'undefined' ? byNameComparator : bySortOrderAndNameComparator);
            // $log.info(new Date() + ": saving: " + refName)
            localStorage.setItem(refName, JSON.stringify(refData));
            store.commit(refName, refData.data);
          }
        },
        (err) => Vue.$log.error(refName + ' get error ', err)
      );
  } else {
    // log.debug(refName + " is up to date")
  }
  store.commit(refName, refData.data);
}

export const updateMessageCount = () => {
  ajax.getUnreadCount().then(
    (r) => store.commit('unreadMessages', r.data.content.count_unread),
    (err) => Vue.$log.error('getUnreadCount error ', err)
  );
};

/**
 * Возвращает тариф на услугу на указанную дату. Если тариф не найден - возвращается объект с нулевыми ценами.
 * @param {Number} subserviceId номер подуслуги (не тот, который в ЕРИП)
 * @param {Date} date дата актуальности тарифа
 */
export const getTariff = (subserviceId, date) => {
  let tariffs4Subservice = store.getters.tariffs[subserviceId];

  if (!tariffs4Subservice) return { total: 0, vat: 0 };
  let sDate = new Date(date).toISOString();
  let result = tariffs4Subservice.find((x) => sDate >= x.startDate && sDate <= x.endDate) || { total: 0, vat: 0 };
  return result;
};
