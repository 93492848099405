<template>
  <!--
  ACA056257 (AM 99466)
  AEA110067 (AM 99467)
  MAA1970454 (AM 270856)
  MAA980492 (AM 214391)
 -->
  <modal-wrapper @close="close" :width="width">
    <template slot="header">
      <h3>Техпаспорт: {{ cn }}</h3>
    </template>

    <p>Из ГАИ:</p>
    <table class="table table-hover table-mc-light-blue table-striped" v-show="!blockUI">
      <thead>
        <tr>
          <th></th>
          <th>Госномер</th>
          <th>Год</th>
          <th>Модель</th>
          <th>VIN</th>
          <th>шасси</th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="gai.length == 0 && resultType == 0">
          <td style="text-align: center" colspan="8">Ничего не найдено</td>
        </tr>
        <tr v-show="gai.length == 0 && resultType != 0">
          <td style="text-align: center" colspan="8">Ошибка при поиске</td>
        </tr>
        <tr v-for="(g, i) in gai" style="cursor: pointer;" @click="toggleGAI(i)" :key="i">
          <td>
            <input
              type="checkbox"
              @click="
                toggleGAI(i)
                $event.stopPropagation()
              "
              :checked="i === gaiSelected"
            />
          </td>
          <td>{{ g.rn }}</td>
          <td>{{ g.y }}</td>
          <td>{{ g.model }}</td>
          <td>{{ g.vin }}</td>
          <td>{{ g.chassis }}</td>
        </tr>
      </tbody>
    </table>

    <p v-if="!transfer">Последний исправный КДР:</p>
    <table v-if="!transfer" class="table table-hover table-mc-light-blue table-striped" v-show="!blockUI">
      <thead>
        <tr>
          <th></th>
          <th>Госномер</th>
          <th>Год</th>
          <th>ДК</th>
          <th>VIN</th>
          <th>Дата</th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="kdrs.length == 0 && resultType == 0">
          <td style="text-align: center" colspan="8">Ничего не найдено</td>
        </tr>
        <tr v-show="kdrs.length == 0 && resultType != 0">
          <td style="text-align: center" colspan="8">Ошибка при поиске</td>
        </tr>
        <tr v-for="(k, i) in kdrs" style="cursor: pointer;" @click="toggleKDR(i)" :key="i">
          <td>
            <input
              type="checkbox"
              @click="
                toggleKDR(i)
                $event.stopPropagation()
              "
              :checked="i === kdrSelected"
            />
          </td>
          <td>{{ k.regNumber }}</td>
          <td>{{ k.year }}</td>
          <td>{{ k.dcSeries }}{{ k.dcNumber }}</td>
          <td>{{ k.vin }}</td>
          <td>{{ k.tiDate | msToDate }}</td>
        </tr>
      </tbody>
    </table>
    <div class="alert alert-danger" v-if="refuses.length > 0 && !blockUI">
      <p>Внимание! Для техпаспорта {{ cn }} за прошедший календарный год были оформлены отказы!</p>
      <p>Информация о причинах отказа появится при наведении мыши на строку таблицы</p>
      <table class="table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>ДС</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, i) in refuses" :key="i" :title="r.descr">
            <td>{{ r.date | msToDate }}</td>
            <td>{{ r.dsName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info" v-show="blockUI">Загружаю...</div>

    <template slot="footer">
      <button class="btn btn-warning" type="button" @click="close">Отмена</button>
      <button class="btn btn-success" type="button" @click="apply" :disabled="kdrSelected === -1 && gaiSelected === -1">Ok</button>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/controls/ModalWrapper';
import axios from 'axios';
import { parseNumberWithDefault } from '@/lib/funcs';

export default {
  data() {
    return {
      kdrSelected: -1,
      gaiSelected: -1,
      kdrs: [],
      gai: [],
      refuses: [],
      blockUI: false,
      resultType: 0,
    };
  },
  props: {
    width: {
      type: String,
      required: false,
    },
    cn: {
      type: String,
      required: false,
    },
    transfer: {
      type: [Number, Boolean],
      required: false,
      default: true,
    },
  },
  components: { ModalWrapper },
  methods: {
    close() {
      this.$emit('close');
    },
    apply() {
      let vm = this;
      this.$emit('apply', [vm.gaiSelected === -1 ? null : vm.gai[vm.gaiSelected], vm.kdrSelected === -1 ? null : vm.kdrs[vm.kdrSelected]]);
    },
    toggleKDR(i) {
      let vm = this;
      if (vm.kdrSelected === i) {
        vm.kdrSelected = -1;
      } else {
        vm.kdrSelected = i;
      }
    },
    toggleGAI(i) {
      let vm = this;
      if (vm.gaiSelected === i) {
        vm.gaiSelected = -1;
      } else {
        vm.gaiSelected = i;
      }
    },
    convertGai(data) {
      let result = [];
      for (let entry of data) {
        if (entry.LICENCE_ID == null) {
          continue;
        }
        let e = {
          massMax: entry.TRANSP_MAX_WEIGHT,
          regId: entry.LICENCE_ID,
          modelId: entry.btoModelId,
          y: parseNumberWithDefault(entry.TRANSP_MADE_DATE, null),
          vin: entry.TRANSP_BODY,
          chassis: entry.TRANSP_CHASSIS,
          rn: '',
          rnExtra: '',
          model: '',
          cId: entry.btoCatId,
          originalData: entry,
        };
        for (let gaiRn of entry.NUMS) {
          if (gaiRn.TRANSP_NUM_MAIN) {
            e.rn = gaiRn.TRANSP_NUM;
          } else {
            e.rnExtra = gaiRn.TRANSP_NUM;
          }
        }
        let mark = (entry.TRANSP_MARK_RUS || '').trim();
        let model = (entry.TRANSP_MODEL_RUS || '').trim();
        e.model = (mark ? (mark + ' ' + model) : model).trim();
        result.push(e);
      }
      return result;
    },
  },
  mounted() {
    let vm = this;
    vm.blockUI = true;
    axios.get(`i/gaikdrrefuses/${this.cn}`, { params: { kdr: vm.transfer ? 0 : 1 } })
      .then(
        (r) => {
          vm.resultType = 0;
          let c = r.data.content;
          vm.kdrs = c.kdrs;
          vm.gai = vm.convertGai(c.gai);
          vm.refuses = c.refuses;
          vm.gaiSelected = vm.gai.length !== 0 ? 0 : -1;
          vm.kdrSelected = vm.kdrs.length !== 0 ? 0 : -1;
        },
        (err) => {
          vm.resultType = 1;
          vm.kdrs = [];
          vm.gai = [];
          vm.refuses = [];
          this.$showError('Ошибка', err);
        }
      )
      .finally(() => {
        vm.blockUI = false;
      });
  },
};
</script>
