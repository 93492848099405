<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <vue-context ref="clientMenu">
      <template slot-scope="child">
        <li><a @click.prevent="addCounteragent">Добавить</a></li>
        <li><a @click.prevent="selectCounteragent">Выбрать</a></li>
        <li v-if="m.counteragentId">
          <a @click.prevent="editCounteragent">Редактировать {{ shrinkString(info.name, 15) }}</a>
        </li>
      </template>
    </vue-context>

    <div style="height:480px;overflow:auto;" class="container-fluid">
      <ul class="nav nav-tabs">
        <li :class="{ active: tabIndex == 0 }" @click="tabIndex = 0">
          <a>Основное</a>
        </li>
        <li :class="{ active: tabIndex == 1 }" @click="tabIndex = 1" v-show="showPaymentTab">
          <a :class="{ 'has-error': paymentError }">Оплата</a>
        </li>
        <li :class="{ active: tabIndex == 2 }" @click="tabIndex = 2">
          <a>Сертификаты</a>
        </li>
      </ul>
      <div v-show="tabIndex == 0">
        <!-- выбор ДС -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.dsId.$invalid }">Место выдачи</label>
          <div class="col-sm-9 input-group">
            <vue-single-select v-model="m.dsId" :options="ds" />
          </div>
        </div>

        <!-- Номер договора (акта) -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.number.$invalid }">№ договора (акта)</label>
          <div class="col-sm-9 no-pad">
            <input class="form-control" type="text" v-model="m.number" />
          </div>
        </div>

        <!-- дата договора и акта: -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.contractDate.$invalid || $v.m.date.$invalid }"
            >Даты договора и акта</label
          >
          <div class="col-sm-4 no-pad">
            <rome-datepicker
              v-model="m.contractDate"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              placeholder="Дата договора"
            />
          </div>
          <div class="col-sm-5 no-right-pad">
            <rome-datepicker
              v-model="m.date"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              placeholder="Дата акта"
            />
          </div>
        </div>

        <!-- Клиент: -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.counteragentId.$invalid }">Клиент</label>
          <div class="col-sm-9 input-group">
            <input :title="info.name" class="form-control" type="text" disabled :value="clientDescription" />
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" title="Выбрать клиента" @click.prevent.stop="$refs.clientMenu.open">...</button>
            </span>
          </div>
        </div>

        <!-- сумма акта: -->
        <div class="form-group row">
          <label class="control-label col-sm-3" :class="{ 'has-error': $v.m.vat.$invalid || $v.m.total.$invalid }">НДС и сумма</label>
          <div class="col-sm-4 no-pad">
            <input class="form-control" type="number" v-model="m.vat" readonly tabindex="-1" />
          </div>
          <div class="col-sm-5 no-right-pad">
            <input class="form-control" type="number" v-model="m.total" readonly tabindex="-1" />
          </div>
        </div>

        <tabular-part
          v-model="m.tabular"
          :serviceSets="serviceSets"
          @changed="tabularChanged"
          :vatRate="consts.vatRate"
          :docDate="m.date || currentDate"
          :recalcDisabled="loading"
          :v="$v.m.tabular"
        />
      </div>
      <div v-show="tabIndex == 1">
        <div class="panel panel-warning">
          <div class="panel-heading">
            Оплата
            <div class="btn-group">
              <button type="button" class="btn btn-in-bar" @click="addPayment">
                <i class="fa fa-plus-square" />
              </button>
            </div>
          </div>
          <div class="panel-body" style="padding-left:15px;padding-right:15px;">
            <payment-set v-model="m.p" :v="$v.m.p" />
          </div>
        </div>
      </div>
      <div v-show="tabIndex == 2 && showCertificatesTab">
        <div class="panel panel-warning col-xs-6" style="border: 1px;">
          <div class="panel-heading text-center">Доступные</div>
          <div class="panel-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(cert, i) in spareCerts" :key="i" @click="moveCertRight(i)">
                  <td style="cursor: pointer;">
                    {{ cert.number }} ({{ cert.actionNumber ? 'продление' : 'выдача' }})
                    <i class="fa fa-chevron-circle-right" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="panel panel-success col-xs-6" style="border: 1px;">
          <div class="panel-heading text-center">В акте</div>
          <div class="panel-body">
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(cert, i) in m.certificates" :key="i" @click="moveCertLeft(i)">
                  <td style="cursor: pointer;">
                    <i class="fa fa-chevron-circle-left" />
                    {{ cert.number }} ({{ cert.actionNumber ? 'продление' : 'выдача' }})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button class="btn footer-btn" type="button" @click="print(3)"><i class="fa fa-print" />Печатать</button>
        <button class="btn btn-warning footer-btn" type="button" @click="close">Отмена</button>

        <button class="btn btn-success footer-btn" type="button" @click="checkPaymentsAndSave" :disabled="actInvalid">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import { store, isUserInRole } from '@/store/store';
import { freshPayment, shrinkString, russianDate } from '@/lib/funcs';
import { vatRate, patterns } from '@/lib/consts';
import SelectCounteragent from './SelectCounteragent';
import EditCounteragent from './EditCounteragent';
import { required, minValue, between } from 'vuelidate/lib/validators';
import { regex, paymentsValidation, tabPartValidation } from '@/lib/validators';
import { modifiable, askThenDo } from '@/lib/mixins';
import TabularPart from './TabularPart';
import RomeDatepicker from '@/controls/RomeDatepicker';
import { sign } from '@/lib/sign';
import PaymentSet from '@/components/payments/PaymentSet';
export default {
  data() {
    return {
      currentDate: moment().format('YYYY-MM-DD'),
      loading: false,
      ds: [],
      servicesMap: {},
      subservicesMap: {},
      subserviceSets: {},
      spareCerts: [],
      blockUI: false,
      tabIndex: 0,
      // объект для хранения доп. данных о клиенте (имя и УНП), которые не содержатся в договоре непосредственно
      info: { name: null, unp: null },
      m: {
        date: null,
        contractDate: moment().format('YYYY-MM-DD'),
        serviceId: null,
        number: '',
        contractId: null,
        total: 0.0,
        vat: 0.0,
        vatRate,
        dsId: null,
        tabular: [],
        p: {
          payments: [],
        },
        certificates: [],
        counteragentId: null,
        regNumber: null,
      },
      consts: {
        vatRate,
      },
      m_: {},
      contractTimeout: null,
      subServiceGroup: null,
    };
  },
  validations() {
    return {
      m: {
        date: { regex: regex(patterns.date) },
        contractDate: { required, regex: regex(patterns.date) },
        total: { required, min: minValue(0.01) },
        vat: { required, min: minValue(0.01) },
        number: { required },
        counteragentId: { required, min: minValue(1) },
        serviceId: { required, min: minValue(1) },
        p: paymentsValidation(),
        dsId: { required, between: between(100000000, 999999999) },
        tabular: tabPartValidation(),
      },
    };
  },
  props: {
    initialId: {
      type: Number,
      default: null,
    },
    serviceId: {
      type: [Number],
      required: false,
    },
    initialContractId: {
      type: Number,
      default: null,
    },
    makeCopy: {
      type: Boolean,
      default: false,
    },
    showCertificatesTab: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    EditCounteragent,
    TabularPart,
    SelectCounteragent,
    RomeDatepicker,
    PaymentSet,
    VueSingleSelect,
  },
  computed: {
    actInvalid() {
      return this.$v.m.$invalid || this.paymentError;
    },
    paymentError() {
      return this.m.date && this.m.p.payments.length == 0 && this.showPaymentTab;
    },
    showPaymentTab() {
      return !(this.servicesMap[this.serviceId] && this.servicesMap[this.serviceId].actWithoutPayment);
    },
    serviceSets() {
      return this.m.serviceId ? this.subserviceSets[this.m.serviceId] : [];
    },
    clientDescription() {
      let info = this.info;
      let d = shrinkString(info.name, 40);
      return (info.unp ? info.unp + ' ' : '') + d;
    },
  },
  methods: {
    shrinkString,
    selectCounteragent() {
      let vm = this;
      vm.$dlg.modal(SelectCounteragent, {
        width: 800,
        height: 500,
        title: 'Подбор клиента',
        callback: function(rslt) {
          if (rslt) {
            vm.info = rslt;
            vm.m.counteragentId = rslt.counteragentId;
          }
        },
      });
    },
    editCounteragent() {
      let id = this.m.counteragentId;
      if (id) this.addOrEditCounteragent(id);
    },
    addCounteragent() {
      this.addOrEditCounteragent();
    },
    addOrEditCounteragent(initialId) {
      let vm = this;
      vm.$dlg.modal(EditCounteragent, {
        params: { initialId },
        width: 800,
        height: 440,
        title: (initialId ? 'Редактирование ' : 'Создание') + ' клиента для договора ' + vm.m.number,
        callback: function(rslt) {
          if (rslt) {
            vm.m.counteragentId = rslt.counteragentId;
            vm.info = rslt;
          }
        },
      });
    },
    recalcRegNumber() {
      let m = this.m;
      if (m.certificates.length == 0) {
        m.regNumber = null;
      } else {
        m.regNumber = m.certificates[0].regNumber;
      }
    },
    moveCertRight(i) {
      this.m.certificates.push(this.spareCerts.splice(i, 1)[0]);
      this.recalcRegNumber();
    },
    moveCertLeft(i) {
      this.spareCerts.push(this.m.certificates.splice(i, 1)[0]);
      this.recalcRegNumber();
    },
    addPayment() {
      this.m.p.payments.push(freshPayment(0));
    },
    print(template) {
      let vm = this;
      if (vm.m.actId && _isEqual(vm.m, vm.m_)) {
        let type = 'actContract';
        switch (template) {
          case 1:
            type = 'contract';
            break;
          case 2:
            type = 'act';
            break;
        }
        let dsNumber = this.userSettings.curDsNumber || '___';
        window.open(
          `s/docs/pdf2/${type}/${vm.m.actId}?dsNumber=${encodeURIComponent(dsNumber)}&userName=${encodeURIComponent(this.userInfo.name)}`,
          '_blank'
        );
      } else {
        this.$dlg.alert('Можно печатать только сохраненные акты', { messageType: 'warning' });
      }
    },
    close() {
      this.$emit('close');
    },
    tabularChanged(footer) {
      let m = this.m;
      m.total = footer.total;
      m.vat = footer.vat;
    },
    checkPaymentsAndSave() {
      let vm = this;
      let payments = (vm.m.p.payments || []).filter(p => p.t == 2);
      if (payments.length == 0) {
        vm.save();
        return;
      }
      ajax
        .findUsedPaymentOrders4Act({ cId: vm.m.counteragentId, ...(vm.m.actId ? { actId: vm.m.actId } : null) }, payments)
        .then(r => {
          let result = r.data.content;
          if (result.length == 0) {
            vm.save();
            return;
          }
          let message = 'Дублирующие номера платежных поручений:\n';
          for (let it of result) {
            message = `${message}\n№ <b>${it.doc_num}</b> от <b>${russianDate(it.doc_date)}</b> (Акт ${
              it.num
            }, ${it.name})`;
          }
          message =
            message +
            '\n\nДля сохранения (если уверены в отсутствии мошенничества), нажмите "ДА". Либо нажмите "НЕТ" и перепроверьте введенные данные';
          vm.askThenDo(message.replace(/\n/g, '<br>'), vm.save, null, 600, 300);
        })
        .catch(err => {
          vm.$log.error('Ошибка проверки дублирования платежей', err);
          // Даже если при проверке платежек возникла проблема (типа слишком частых запросов), позволим сохранить разрешение
          vm.save();
        });
    },
    save() {
      let vm = this;
      vm.$dlg.closeAll(null, 'toast');

      // проверить, что число количество привязанных к акту сертификатов не превышает количество услуг в ТЧ, предполагающих выдачу или продление сертификата
      let cCount = 0;
      for (let tabLine of vm.m.tabular) {
        let serviceDescr = vm.subservicesMap[tabLine.id];
        if (!serviceDescr) continue;
        if (serviceDescr.cert) {
          cCount += tabLine.count;
        }
      }
      if (vm.m.certificates.length > cCount) {
        vm.$showError(null, {
          errCode: 1,
          message: `Количество прикрепленных сертификатов(свидетельств) равно ${
            vm.m.certificates.length
          }, что превышает суммарное количество услуг в табличной части акта (${cCount})`,
        });
        return;
      }
      function makeReturn(m) {
        return { actId: m.actId, actNum: m.number, actDate: m.actDate };
      }
      vm.blockUI = true;
      if (vm.m.actId && _isEqual(vm.m, vm.m_)) {
        vm.$log.debug('no changes');
        vm.$emit('close', makeReturn(vm.m));
      } else {
        sign(vm.m)
          .then(ajax.saveAct)
          .then(r => {
            vm.m_ = _cloneDeep(vm.m);
            vm.$emit('close', makeReturn(vm.m));
          })
          .catch(err => {
            vm.$showError(null, err);
          })
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
    menuSelected(mi) {
      let vm = this;
      switch (mi.tag) {
        case 'add':
          vm.addOrEditContract();
          break;
        case 'edit':
          vm.addOrEditContract(vm.m.contractId);
          break;
        case 'select':
          vm.selectContract();
          break;
      }
    },
  },
  mounted() {
    let vm = this;
    vm.blockUI = true;
    vm.ds = store.getters.ds;
    vm.servicesMap = store.getters.servicesMap;
    vm.subservicesMap = store.getters.subservicesMap;
    vm.userSettings = store.getters.userSettings;
    vm.subserviceSets = store.getters.subserviceSets;
    vm.userInfo = store.getters.userInfo;
    vm.m.dsId = store.getters.userSettings.curDsId;
    vm.m_ = _cloneDeep(vm.m);
    if (vm.initialId) {
      vm.loading = true;
      ajax
        .getActAndSpareCerts({ id: vm.initialId })
        .then(
          r => {
            let act = r.data.content[0];
            // если акт закрыт другим пользователем и мы не являемся модераторами, то выходим
            if (act.completeUserId && vm.userInfo.id != act.completeUserId && !isUserInRole('perm_moderator')) {
              vm.$dlg.alert(`Акт ${act.number} закрыт другим пользователем`, {
                messageType: 'warning',
                callback: x => {
                },
              });
              // vm.$emit('close')
            }
            vm.m = act;
            let c = vm.m.actInfo;
            if (c) {
              vm.info = Object.assign(vm.info, c);
              vm.info.contractId = vm.m.contractId;
            }
            vm.spareCerts = r.data.content[1];
            vm.m_ = _cloneDeep(vm.m);
            if (vm.makeCopy) {
              vm.m.certificates = [];
              vm.m.date = null;
              vm.m.contractDate = null;
              vm.m.p.payments = [];
              delete vm.m.actId;
              vm.m.number = '';
              vm.m_ = _cloneDeep(vm.m);
              ajax.nextActNum({ serviceId: vm.m.serviceId }).then(
                r => {
                  vm.m.number = r.data.content;
                  vm.m_ = _cloneDeep(vm.m);
                },
                err => vm.$showError('Ошибка', err)
              );
            }
          },
          err => vm.$showError('Ошибка', err)
        )
        .finally(() => {
          vm.blockUI = false;
          vm.loading = false;
        });
    } else {
      vm.m.serviceId = vm.serviceId;
      ajax
        .nextActNumAndSpareCerts({ serviceId: vm.serviceId })
        .then(
          r => {
            vm.m.number = r.data.content[0];
            vm.spareCerts = r.data.content[1];
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.$showError('Не смог получить номер акта', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
      if (vm.initialContractId) {
        ajax
          .getContract({ id: vm.initialContractId })
          .then(
            r => {
              let d = r.data.content;
              vm.m.contractId = vm.initialContractId;
              vm.info.contractNum = d.number;
            },
            err => {
              vm.$showError('Ошибка', err);
            }
          )
          .finally(() => {
            vm.blockUI = false;
          });
      }
    }
  },
  mixins: [modifiable, askThenDo],
};
</script>
